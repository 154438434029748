import React, { useState, useEffect, useRef, FC } from 'react';
import classNames from 'classnames/bind';

import styles from './notes.module.scss';
import Button from '../Button/Button';
import { saveLeadNote, saveNote } from 'api/notes/index';
import { notify } from 'utils/notify';
import { useOnClickOutside } from 'utils/useClickOutside';
import { INotes, INotesData } from '../../../models/components/Notes/Notes.ts';
import { Switch } from '../Switch/Switch.tsx';
import { SimpleInput } from '../SimpleInput/SimpleInput.tsx';

const cx = classNames.bind(styles);

const Notes: FC<INotes> = (props) => {
  const user_id: string | number = props.userId;
  const [notesData, setNotesData] = useState<INotesData>({
    subject: '',
    body: '',
    is_pinned: false,
  });
  const [validate, setValidate] = useState(false);
  const contentRef = useRef(null);

  const handleChangeValue = (fieldName, value) => {
    setNotesData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleSubmit = () => {
    let sendData;
    switch (props.type) {
      case 'client':
        sendData = {
          ...notesData,
          user_id: user_id,
          is_pinned: notesData && notesData.is_pinned === true ? 1 : 0,
        };
        saveNote(sendData).then((res: any) => {
          if (res) {
            notify({ type: 'success', message: res.data.message });
            props.updateComponent(props.userId);
            props.handleClose(false);
          } else {
            notify({ type: 'success', message: res.responce });
            props.handleClose(false);
          }
        });
        break;
      case 'lead':
        sendData = {
          ...notesData,
          lead_id: user_id,
          is_pinned: notesData && notesData.is_pinned === true ? 1 : 0,
        };
        saveLeadNote(sendData).then((res: any) => {
          if (res) {
            notify({ type: 'success', message: 'Note created' });
            props.updateComponent(props.userId);
            props.handleClose(false);
          } else {
            notify({ type: 'success', message: res.responce });
            props.handleClose(false);
          }
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (notesData.body === undefined || notesData.body === '') {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [notesData]);

  useOnClickOutside(contentRef, () => {
    props.handleClose(false);
  });

  return (
    <div className={cx('notes')} ref={contentRef}>
      <div className={cx('notes__container')}>
        <div className={cx('notes__title')}>Note</div>
        <div className={cx('notes__subject')}>
          <div className={cx('input-wrap')}>
            <SimpleInput
              inputClassName="d-flex flex-column align-items-start"
              label="Subject"
              type="text"
              name="subject"
              onChange={(e) => handleChangeValue('subject', e.target.value)}
            />
          </div>
        </div>
        <div className={cx('notes__body')}>
          <div className={cx('label')}>
            Body<span className={cx('required')}>*</span>
          </div>
          <div className={cx('input-wrap')}>
            <textarea name="body" onChange={(e) => handleChangeValue('body', e.target.value)} />
          </div>
        </div>
        <div className={cx('notes__footer')}>
          <div className={cx('notes__info')}>
            <Switch
              isChecked={notesData.is_pinned}
              onChange={(e) => handleChangeValue('is_pinned', e.target.checked)}
            />
            <div className={cx('toggle-label')}>Pin</div>
          </div>
          <div className={cx('btn-wrap')}>
            <Button
              buttonType="outline"
              buttonText="Cancel"
              onClick={() => props.handleClose(false)}
            />
            <Button
              buttonType="primary"
              buttonText="Save"
              disabled={!validate}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;
