import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import { MultiSelect } from 'components/ui/MultiSelect';
import { ListValue } from 'models/ListValue';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

type Props = {
  desks: ListValue[];
  teams: ListValue[];
  agents: ListValue[];
  notificationTypes: ListValue[];
  notificationDetailsTypes: ListValue[];
};

export const NotificationsFields = ({
  agents,
  desks,
  notificationDetailsTypes,
  notificationTypes,
  teams,
}: Props) => {
  const { control } = useFormContext();

  return (
    <>
      <Row>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Desks"
            control={control}
            id="desk_ids"
            name="desk_ids"
            options={desks}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Teams"
            control={control}
            id="team_ids"
            name="team_ids"
            options={teams}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Agents"
            control={control}
            id="agents"
            name="agents"
            options={agents}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Notification type"
            control={control}
            id="type"
            name="type"
            options={notificationTypes}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Notification types details"
            control={control}
            id="details"
            name="details"
            options={notificationDetailsTypes}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <DatePickerRange
            name="created_at"
            control={control}
            label={'Notification creation date range'}
            placeholder="From - To"
          />
        </Col>
      </Row>
    </>
  );
};
