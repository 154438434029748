import React from 'react';
import classNames from 'classnames/bind';
import styles from './table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import moment from 'moment';

const cx = classNames.bind(styles);

const TradingActivityTable = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  manualSortHandler,
  colSortCheck,
  onSort,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      trade,
      type,
      trading_account,
      open_price,
      close_price,
      volume,
      swap,
      open_time,
      open_time_dt,
      close_time,
      profit,
    } = item;

    return {
      trade: (
        <div className={cx('payments-table_td')}>
          <span>{trade}</span>
        </div>
      ),
      type: (
        <div className={cx('payments-table_td', type.toLowerCase())}>
          <span>{type}</span>
        </div>
      ),
      trading_account: (
        <div className={cx('payments-table_td')}>
          {trading_account.demo === 1 && (
            <>
              <span style={{ color: 'var(--blue)' }}>DEMO</span>{' '}
            </>
          )}
          <span>{trading_account.trading_account}</span>
        </div>
      ),
      open_price: (
        <div className={cx('payments-table_td')}>
          <span>{open_price}</span>
        </div>
      ),
      // close_price: (
      //   <div className={cx('payments-table_td')}>
      //     <span>{close_price}</span>
      //   </div>
      // ),
      volume: (
        <div className={cx('payments-table_td')}>
          <span>{volume}</span>
        </div>
      ),
      // profit: (
      //   <div className={cx('payments-table_td')}>
      //     <span>{profit}</span>
      //   </div>
      // ),
      // swap: (
      //   <div className={cx('payments-table_td')}>
      //     <span>{swap}</span>
      //   </div>
      // ),
      open_time: (
        <div className={cx('payments-table_td', 'date-cell')}>
          <span>{moment(open_time_dt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
          <span>{moment(open_time_dt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</span>
        </div>
      ),
      // close_time: (
      //   <div className={cx('payments-table_td', 'date-cell')}>
      //     <span>{moment(close_time * 1000).format('DD.MM.YYYY')}</span>
      //     <span>{moment(close_time * 1000).format('HH:mm:ss')}</span>
      //   </div>
      // ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  const checkSortEnable = (colId) => {
    switch (colId) {
      case 'original_agent':
      case 'country':
      case 'amount':
      case 'trading_acc':
      case 'date':
        return true;
      default:
        return false;
    }
  };

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('payments-table')}
      manualSortHandler={manualSortHandler}
      colSortCheck={colSortCheck || checkSortEnable}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default TradingActivityTable;
