import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from '../clients-table.module.scss';
import moment from 'moment';
import { UserProfile } from 'models/User.ts';
import { upperFirst } from 'lodash';

const cx = classNames.bind(styles);

interface IStatusProps {
  user_profile: UserProfile;
}
const Status: FC<IStatusProps> = ({ user_profile }) => {
  return (
    <div className={cx('client__status')}>
      <span
        className={cx(
          user_profile?.profile_status === 'not verified'
            ? 'not_verified'
            : user_profile?.profile_status,
        )}
      >
        {upperFirst(user_profile?.profile_status)}
      </span>
      <span className={cx('date-time')}>
        since {moment.unix(user_profile?.profile_status_updated_at).format('DD.MM.YYYY HH:mm')}
      </span>
    </div>
  );
};

export default memo(Status);
