import Select, {
  ActionMeta,
  GroupBase,
  MenuPlacement,
  PropsValue,
  StylesConfig,
} from 'react-select';
import { Icon } from '../Icon';
import { simpleSelectStyles } from './styles';
import cn from 'classnames';

import s from './simple-select.module.scss';

type Option = { value: string; label: string };

type Props = {
  options: Option[];
  id?: string;
  name?: string;
  label?: string;
  isRequired?: boolean;
  value?: PropsValue<Option['value']>;
  defaultValue?: PropsValue<Option['value']>;
  placeholder?: string;
  menuPlacement?: MenuPlacement;
  isSearchable?: boolean;
  styles?: StylesConfig<Option, false, GroupBase<Option>>;
  wrapperClassName?: string;
  labelClassName?: string;
  onChange: (newValue: Option, actionMeta: ActionMeta<Option>) => void;
};

export const SimpleSelect = ({
  id,
  label,
  isRequired = false,
  value,
  defaultValue,
  placeholder = 'Select',
  isSearchable = false,
  styles,
  wrapperClassName,
  labelClassName,
  onChange,
  ...props
}: Props) => {
  return (
    <div className={cn(s.wrapper, wrapperClassName)}>
      {label && (
        <label htmlFor={id} className={cn(s.label, labelClassName)}>
          {label}
          {isRequired ? <span className={s.required}>*</span> : null}
        </label>
      )}
      <Select
        {...props}
        defaultValue={props.options.find((o) => o.value === defaultValue)}
        noOptionsMessage={() => 'No options'}
        isSearchable={isSearchable}
        placeholder={placeholder}
        onChange={onChange}
        styles={{ ...simpleSelectStyles({}), ...styles }}
        components={{ DropdownIndicator }}
      />
    </div>
  );
};

const DropdownIndicator = () => (
  <div style={{ padding: '0 12px', display: 'flex' }}>
    <Icon name="caretDown" size={16} />
  </div>
);
