import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { TABLE_LEADS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const leadsDefaultValues = {
  languages: [],
  countries: [],
  account_statuses: [],
  created_at: '',
  note_last: '',
  query: '',
  query_category: defaultQueryCategoryOption,
};

export const useSearchLeadsPage = () => {
  const table = useTableColumnOrder('TABLE_LEADS_COLUMNS_NAMES', TABLE_LEADS_COLUMNS_NAMES);

  const form = useForm({ reValidateMode: 'onChange', defaultValues: leadsDefaultValues });

  return { table, form };
};
