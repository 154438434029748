import { getCountryList } from 'api/country';
import React, { useEffect, useRef, useState } from 'react';

import { findOperatorsMethod, getDictionaryMethod } from 'api/managements/operators';
import OperatorsTable from 'components/ui/Table/OperatorsTable';
import TablePagination from 'components/ui/TablePagination';
import { firstLetterUppercase } from 'utils/firstLetterUppercase.js';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../../Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import OperatorsFilter from './Filter';

import { getTablePageParams } from 'utils/getTablePageParams.js';
import { CountryListValue } from 'models/Countries.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { ManagementOperator } from 'models/Management/Operators/Operator.js';
import { ManagementOperatorsFilter } from 'models/Management/Operators/Filter.js';
import { ListValue } from 'models/ListValue.js';
import { useAppDispatch } from 'store';
import { usePageTitle } from 'utils/usePageTitle';
import { toggleScrollToTop } from 'store/slice/tableSlice';
import { OperatorsPageTitle } from './OperatotsPageTitle';
import { useOperatorsPage } from './useOperatorsPage';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { FormProvider } from 'react-hook-form';
import { OperatorsPageFields } from './Fields/OperatorsPageFields';
import styles from './operators-page.module.scss';

const title = 'Operators';

const SORTED_FIELD_NAME = {
  operator: 'first_name',
  country: 'country_name',
  status: 'status',
  created_at: 'created_at',
};

const OperatorsPage = () => {
  const { permissionGiven: PermissionShowListOperators } = usePermission('admin.admin-user.index');

  const dispatch = useAppDispatch();

  const [operators, setOperators] = useState<ManagementOperator[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [statusList, setStatusList] = useState<ListValue[]>([]);
  const [countryOpt, setCountryOpt] = useState<CountryListValue[]>([]);
  const [branchTypeList, setBranchTypeList] = useState<ListValue[]>([]);
  const [filters, setFilters] = useState<ManagementOperatorsFilter>(null);

  const componentMounted = useRef(false);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const [queryMessage, setQueryMessage] = useState('');

  // const [sort, setSort] = useState();

  usePageTitle(title);

  useEffect(() => {
    if (PermissionShowListOperators) {
      componentMounted.current = true;
      getOperators();
    }

    return () => {
      componentMounted.current = false;
    };
  }, [PermissionShowListOperators]);

  useEffect(() => {
    fetchCountryList();
    fetchDictionaryList();
  }, []);

  const fetchCountryList = () => {
    getCountryList().then((res) => {
      const options = res.data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
        iso_3166_2: opt.iso_3166_2,
      }));
      setCountryOpt([{ value: 0, label: 'Other' }].concat(options));
    });
  };

  const fetchDictionaryList = () => {
    getDictionaryMethod().then((res) => {
      const list = res.data.branch_types.map((opt) => ({
        value: opt,
        label: opt,
      }));
      const list1 = res.data.operator_statuses.map((opt) => ({
        value: opt,
        label: firstLetterUppercase(opt),
      }));
      setBranchTypeList(list);
      setStatusList(list1);
    });
  };

  const getOperators = (options?, data?) => {
    setIsListLoading(true);

    findOperatorsMethod(
      getTablePageParams(options, perPageCount, tableLinks),
      data || filters || { filter: { statuses: ['active'] } },
    )
      .then((res) => {
        setOperators(res.data.data);
        setTableMeta(res.data.meta);
        setTableLinks(res.data.links);
        setPerPageCount(res.data.meta.per_page);
        setQueryMessage(res.data?.message);
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  const sortColumn = (col) => {
    const sorted = col.isSorted;
    const desc = col.isSortedDesc;
    const sortedOperator = SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.operator;

    if (!sorted) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedOperator ? 'asc' : 'desc',
      };
      // setSort(sort);
      getOperators(null, {
        ...filters,
        sort,
      });
      col.toggleSortBy(true);
    }

    if (sorted && desc) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedOperator ? 'desc' : 'asc',
      };
      // setSort(sort);
      getOperators(null, {
        ...filters,
        sort,
      });
      col.toggleSortBy(false);
    }

    if (sorted && !desc) {
      const data = {
        ...filters,
      };
      // setSort(null);
      getOperators(null, data);
      col.clearSortBy();
    }
  };

  const { form, table } = useOperatorsPage();

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <OperatorsPageTitle
            total={tableMeta?.total}
            branchTypeList={branchTypeList}
            updateComponentCallback={getOperators}
          />
        }
      >
        <>
          <FormProvider {...form}>
            <OperatorsPageFields
              countryList={countryOpt}
              statusList={statusList.concat([{ value: 'any', label: 'Any' }])}
            />
            <TableLayout
              header={
                <OperatorsFilter
                  onSubmit={(data) => {
                    setFilters(data);
                    getOperators({ navigate: 'first', perPage: data?.filter?.limit }, data);
                  }}
                  isLoading={isListLoading}
                  queryMessage={queryMessage}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              {PermissionShowListOperators ? (
                <OperatorsTable
                  manualSortHandler={sortColumn}
                  data={operators}
                  branchTypeList={branchTypeList}
                  perPage={operators?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={() => getOperators()}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              ) : null}
            </TableLayout>
          </FormProvider>
          {operators?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getOperators({ perPage: value });
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getOperators({ page });
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default OperatorsPage;
