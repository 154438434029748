import styles from './add-departent.module.scss';
import cn from 'classnames';
import { usePermission } from 'utils/usePermission';
import { RoleWithDepartment } from 'models/RolesAndPermissions/Department';
import { Icon } from 'components/ui/Icon';

type Props = {
  item: RoleWithDepartment;
  onDeleteDepartment: (role: RoleWithDepartment) => void;
};

const DepartmentItem = (props: Props) => {
  const { item, onDeleteDepartment } = props;

  const { permissionGiven: PermissionDeleteRoleOperator } = usePermission(
    'admin.admin-user.role-delete',
  );

  return (
    <div className={styles.departmentItem}>
      <span className={cn(styles.title_item, styles.department)}>{item.department?.name} </span>
      <span className={styles.divider}>-</span>
      <span className={cn(styles.title_item, styles.role)}>{item.name}</span>
      {PermissionDeleteRoleOperator ? (
        <Icon
          name="trash"
          size={20}
          className={styles.trashBtn}
          onClick={() => onDeleteDepartment(item)}
        />
      ) : null}
    </div>
  );
};
export default DepartmentItem;
