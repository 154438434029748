import React, { useState } from 'react';
import {
  getOfficesMethod,
  getOperatorsForBrandMethod,
  // getOperatorsForDeskMethod,
} from '../../api/hierarchy';
import HierarchyComponentDesks from './HierarchyComponentDesks';
import { checkDevise } from '../../utils/checkDevise';
import UserPopup from './UsersPopup';
import Button from '../../components/ui/Button';
import { HierarchyBrand } from 'models/Hierarchy/HierarchyData';
import { Icon } from 'components/ui/Icon';
import cn from 'classnames';
import styles from './hierarchy-page.module.scss';

type Props = {
  data: HierarchyBrand;
};

const HierarchyComponentOffices = ({ data }: Props) => {
  const [brand, setBrand] = useState(data);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const isMobile = checkDevise() === 'mobile';

  const handleClickOnBrand = () => {
    setOpen(true);
    getOfficesMethod().then((res) => {
      if (res.status === 200) {
        setBrand((prev) => ({ ...prev, offices: res.data.data }));
      }
    });
  };

  const ToggleButton = () => (
    <button
      className={styles.btnIcon}
      onClick={() => {
        open ? setOpen(false) : handleClickOnBrand();
      }}
    >
      <Icon name={open ? 'minusCircle' : 'plusCircle'} size={30} />
    </button>
  );

  const onHover = (v: boolean) => !isMobile && setHover(v);

  return (
    <div className={styles.wrapperItem}>
      <div
        className={styles.wrapperContent}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        {isMobile && brand?.branches_count > 0 ? (
          <ToggleButton />
        ) : brand?.branches_count > 0 && hover ? (
          <ToggleButton />
        ) : (
          <div className={styles.btnIcon}>
            <Icon name="star" size={30} />
          </div>
        )}

        <div className={cn(styles.wrapperInfo, styles.wrapperInfoHover)}>
          <div>
            <p className={styles.topBlock}>{`${brand?.name} Brand`}</p>
            <p className={styles.bottomBlock}>
              {`${brand?.branches_count} branches / ${brand?.operators_count}`}{' '}
              {brand?.operators_count === 1 ? 'user' : 'users'}
            </p>
          </div>
          {brand?.operators_count > 0 && (
            <UserPopup
              fetchMethod={getOperatorsForBrandMethod}
              data={brand}
              namePopup={`brand ${brand.name}`}
              triggerBtn={
                <Button
                  icon={<Icon name="user" />}
                  className={styles.userButton}
                  onClick={() => null}
                  buttonType={'outline'}
                />
              }
            />
          )}
        </div>
      </div>

      {open && brand?.offices?.length > 0 && (
        <div className={styles.wrapperList}>
          {brand?.offices?.map((office) => {
            return (
              <div key={office.id} className={styles.itemList}>
                <HierarchyComponentDesks data={office} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default HierarchyComponentOffices;
