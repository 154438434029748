import API from '../api';

export const getOperatorMethod = (id) => API.get(`/api/v1/admin-users/${id}`);
export const addRoleMethod = (data) => API.put(`/api/v1/admin-users/role/add`, data);
export const deleteRoleMethod = (data) =>
  API.delete(`/api/v1/admin-users/role/delete`, { params: data });
export const addBranchMethod = (data) => API.post(`/api/v1/branches`, data);
export const changeOperatorPassword = (id, data) =>
  API.put(`/api/v1/change/password/operators/${id}`, data);
export const resetOperatorPassword = (data) => API.post(`/api/v1/password/reset`, data);
export const changeBranchMethod = (data) => API.post(`/api/v1/branches/team/change`, data);
export const activationOperatorMethod = (id, data) =>
  API.put(`/api/v1/admin-user/${id}/activation`, data);
export const deleteBranchMethod = (id) => API.delete(`/api/v1/branches/${id}`);
export const deleteOperator = (id) => API.delete(`/api/v1/admin-users/${id}`);
export const getOperatorAutologinLink = (id: number) =>
  API.get(`/api/v1/admin-user/get-autologin/${id}`);
