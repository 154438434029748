import { Col, Row } from 'react-bootstrap';
import { notify } from 'utils/notify';
import { useState } from 'react';
import { removeImage } from 'helpers/deleteImageForLK';
import { uploadLogoMethod } from 'api/settingsLK';
import { logos } from './utils';
import DragDropUploadImage from 'components/ui/DragDropUploadImage/DragDropUploadImage';

import s from './logos-tab.module.scss';

type Props = {
  styles: any;
  updateComponent: () => void;
};

type LogoKey = typeof logos[number]['id']

const getValidKey = (key: LogoKey) => {
  switch (key) {
    case 'logo_src_crm':
      return 'logo_crm'
    case 'logo_src':
      return 'logo_pc';
    default:
      return key;
  }
}

export const LogosTab = ({ styles, updateComponent }: Props) => {
  const [images, setImages] = useState(styles);

  const handleRemoveImage = async (key: string) => {
    const isRemoved = await removeImage(getValidKey(key as LogoKey));

    if (isRemoved) {
      setImages((prev) => ({ ...prev, [key]: null }));
    }
  };

  const onSubmit = async (selectedImage, setPreview?: (preview: any[]) => void) => {
    try {
      const data = new FormData();
      data.append('key', selectedImage?.key);
      data.append('file', selectedImage?.img.file);
      await uploadLogoMethod(data);

      updateComponent();
      notify({
        type: 'success',
        message: 'Image upload success',
        timeOut: 2000,
      });
    } catch (error) {
      //Rollback image preview on error
      setPreview?.(images?.[selectedImage?.key] ? [images[selectedImage.key]] : []);
      notify({
        type: 'error',
        message: error.response,
        timeOut: 2000,
      });
    }
  };

  const onChangeImage = (img, key, setPreview) => {
    if (img) {
      onSubmit({ img, key: getValidKey(key) }, setPreview);
    }
  };

  const onTypeError = (error) => {
    if (error?.acceptType) {
      notify({
        type: 'error',
        message: 'Unsupported file type.',
        timeOut: 5000,
      });
    }
  };

  return (
    <Row>
      {logos.map((item) => (
        <Col xl={3} lg={4} md={6} sm={6} xs={12} key={item.id}>
          <div className={s.item}>
            <div className={s.title}>{item.title}</div>
            <span className={s.description}>{item.description}</span>
            <DragDropUploadImage
              src={images?.[item.id]}
              name={item.id}
              onChange={onChangeImage}
              onError={onTypeError}
              onRemove={handleRemoveImage}
            />
          </div>
        </Col>
      ))}
    </Row>
  );
};
