import React, { useState } from 'react';
import { getDeskForOfficeMethod, getOperatorsForOfficeMethod } from 'api/hierarchy';
import HierarchyComponentTeams from './HierarchyComponentTeams';
import { checkDevise } from 'utils/checkDevise';
import Button from 'components/ui/Button';
import UserPopup from './UsersPopup';
import { HierarchyOffice } from 'models/Hierarchy/HierarchyData';
import { Icon } from 'components/ui/Icon';
import cn from 'classnames';

import styles from './hierarchy-page.module.scss';

type Props = {
  data: HierarchyOffice;
};

const HierarchyComponentDesks = ({ data }: Props) => {
  const [office, setOffice] = useState(data);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const isMobile = checkDevise() === 'mobile';

  const handleClickOnOffice = () => {
    setOpen(true);
    getDeskForOfficeMethod(office.id).then((res) => {
      if (res.status === 200) {
        setOffice((prev) => ({ ...prev, desks: res.data.data }));
      }
    });
  };

  const ToggleButton = () => (
    <button
      className={styles.btnIcon}
      onClick={() => {
        open ? setOpen(false) : handleClickOnOffice();
      }}
    >
      <Icon name={open ? 'minusCircle' : 'plusCircle'} size={30} />
    </button>
  );

  const onHover = (v: boolean) => !isMobile && setHover(v);

  return (
    <div className={styles.wrapperItem}>
      <div
        className={cn(styles.wrapperContent, styles.wrapperOffice)}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        {isMobile && office?.branches_count > 0 ? (
          <ToggleButton />
        ) : office?.branches_count > 0 && hover ? (
          <ToggleButton />
        ) : (
          <div className={styles.btnIcon}>
            <Icon name="office" size={30} />
          </div>
        )}

        <div className={styles.wrapperInfo}>
          <div>
            <p className={styles.topBlock}>{`${office?.name} Office`}</p>
            <p className={styles.bottomBlock}>
              {`${office?.branches_count} branches / ${office?.operators_count}`}{' '}
              {office?.operators_count === 1 ? 'user' : 'users'}
            </p>
          </div>
          {office?.operators_count > 0 && (
            <UserPopup
              fetchMethod={getOperatorsForOfficeMethod}
              data={office}
              namePopup={`office ${office.name}`}
              triggerBtn={
                <Button
                  icon={<Icon name="user" />}
                  className={styles.userButton}
                  onClick={() => null}
                  buttonType={'outline'}
                />
              }
            />
          )}
        </div>
      </div>

      {open && office?.desks?.length > 0 && (
        <div className={styles.wrapperList}>
          {office.desks?.map((desk) => {
            return (
              <div key={desk.id} className={styles.itemList}>
                <HierarchyComponentTeams data={desk} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default HierarchyComponentDesks;
