import { SimpleCheckbox } from 'components/ui/SimpleCheckbox';
import styles from '../multi-select.module.scss';

type Props = {
  selectedCount: number;
  options: any[];
  onChange: (options: any[]) => void;
};

const getCheckboxStatus = (selectedCount: number, allCount: number) => {
  if (selectedCount === allCount) {
    return 'checked';
  }

  return selectedCount === 0 ? 'unchecked' : 'indeterminate';
};

export const MultiSelectAllCheckbox = ({ options, selectedCount, onChange }: Props) => {
  return (
    <SimpleCheckbox
      className={styles.allCheckbox}
      size={20}
      status={getCheckboxStatus(selectedCount, options.length)}
      onChange={() => {
        if (selectedCount === options.length) {
          onChange([]);
        } else {
          onChange(options);
        }
      }}
    />
  );
};
