import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { resetPassword } from 'api/auth';
import { notify } from 'utils/notify';

import { AuthLayout } from '../components/AuthLayout/AuthLayout';
import { AuthFormInput } from '../components/AuthFormInput/AuthFormInput';
import { AuthButton } from '../components/AuthButton/AuthButton';
import { ForgotPasswordSuccess } from './ForgotPasswordSuccess/ForgotPasswordSuccess';
import styles from './forgotPassword.module.scss';

type ForgotPasswordFormType = {
  email: string;
};

const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordFormType>({
    reValidateMode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);
    resetPassword(data)
      .then(() => setIsSuccess(true))
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    document.title = 'Reset Password';
  }, []);

  return (
    <AuthLayout>
      {!isSuccess ? (
        <>
          <h1 className={styles.title}>Reset Password</h1>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <AuthFormInput<ForgotPasswordFormType>
              wrapperClassName={styles.email}
              id="email"
              name="email"
              type="email"
              label="Email"
              control={control}
              rules={{ required: 'Field is required' }}
              errorMessage={errors.email?.message}
            />
            <AuthButton isLoading={isLoading} text="Reset password" />

            <span className={styles.forgotText}>
              Already have an account?{' '}
              <Link className={styles.forgotLink} to={'/'}>
                Sign In
              </Link>
            </span>
          </form>
        </>
      ) : (
        <ForgotPasswordSuccess />
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
