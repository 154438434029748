import { CryptoWithdrawWalletData } from 'models/CustomerService/CryptoWithdraw';
import { getCryptoRequestInfoTableData } from './utils';
import { Icon } from 'components/ui/Icon';
import cn from 'classnames';

import styles from './crypto-request-info.module.scss';

type Props = {
  wallet: CryptoWithdrawWalletData;
  status: 'rejected' | 'accepted' | 'new';
};

export const CryptoRequestInfo = ({ wallet, status }: Props) => {
  const tableData = getCryptoRequestInfoTableData({ wallet, status });

  return (
    <div>
      <div className={styles.title}>Request information</div>
      <table>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index}>
              <td className={styles.tableTitle}>
                {item?.icon && <Icon name={item.icon} size={14} />}
                {item.title}
              </td>
              <td
                className={cn(
                  styles.tableValue,
                  item?.onClick && styles.tableValue__clickable,
                  item.value === status.toUpperCase() && styles[status],
                )}
                onClick={item?.onClick}
              >
                {item.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
