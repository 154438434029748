import QRCode from 'react-qr-code';
import { CryptoWithdrawWalletData } from 'models/CustomerService/CryptoWithdraw';
import { getCryptoRequestUserInfoTableData } from './utils';
import cn from 'classnames';
import styles from './crypto-request-info.module.scss';

type Props = {
  address: CryptoWithdrawWalletData['wallet_address'];
  transaction?: CryptoWithdrawWalletData['deposit_transaction'];
};

export const CryptoRequestUserInfo = ({ address, transaction }: Props) => {
  const tableData = getCryptoRequestUserInfoTableData({ transaction });

  return (
    <>
      <div className={styles.title}>User information</div>
      <div className={styles.info}>
        <table>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index}>
                <td className={styles.tableTitle}>{item.title}</td>
                <td
                  className={cn(styles.tableValue, item?.onClick && styles.tableValue__clickable)}
                  onClick={item?.onClick}
                >
                  {item.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.qrCode}>
          <QRCode value={address} size={150} />
        </div>
      </div>
    </>
  );
};
