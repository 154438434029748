import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './notesTabs.module.scss';

import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import DatePickerRange from 'components/ui/DatePickerRange';
import { getDepartamentList } from 'api/departament';
import { Col, Row } from 'react-bootstrap';
import { getUserNotesList, searchNotesListAll } from 'api/notes';
import moment from 'moment';
import NotesItem from './NotesItem';
import EditNotePopup from './EditNotePopup';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePermission } from 'utils/usePermission';
import { isFormChange } from 'utils/isFormChange';
import { TableLinks, TableMeta } from 'models/Table';
import { ReloadButton } from 'components/ui/ReloadButton';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { Spinner } from 'components/ui/Spinner';

type Props = {
  updateHeadNotes: () => unknown;
  permission: boolean;
};

const NotesTabs = (props: Props) => {
  const { updateHeadNotes, permission } = props;

  const { permissionGiven: PermissionList } = usePermission('admin.notes.list');

  const [departamentList, setDepartamentList] = useState([]);
  const [notesList, setNotesList] = useState([]);

  const [meta, setMeta] = useState<TableMeta | undefined>(undefined);
  const [links, setLinks] = useState<TableLinks | undefined>(undefined);
  const [savedFilters, setSavedFilters] = useState(null);

  const [isListLoading, setIsListLoading] = useState(false);

  const { id } = useParams();

  const componentMounted = useRef(false);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setSavedFilters(data);
    setIsListLoading(true);
    fetchNotesList(data);
  };

  const getNotesByUser = () => {
    getUserNotesList(savedFilters, id)
      .then((res) => {
        if (res.status === 200) {
          setNotesList(res.data.data);
          setMeta(res.data.meta);
          setLinks(res.data.links);
          updateHeadNotes();
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => setIsListLoading(false));
  };

  const fetchNotesList = (data?, isNextCall?) => {
    updateHeadNotes();
    setIsListLoading(true);

    const postData = {
      filter: {
        id: +id,
        type: 'user',
      },
    };

    if (data && data['department_id']) {
      postData.filter['department_ids'] = [data['department_id'].value];
    }

    if (data && data['created_at']) {
      postData.filter['created_at'] = {
        from: moment(data.created_at.from).format('YYYY-MM-DD'),
        to: moment(data.created_at.to).format('YYYY-MM-DD'),
      };
    }

    let searchParams = '';
    if (isNextCall) {
      searchParams = links && links.next ? `?${links.next.split('?').reverse()[0]}` : '';
    }

    searchNotesListAll(searchParams, postData)
      .then((res) => {
        if (res.status === 200) {
          if (isNextCall) {
            setNotesList((prev) => prev.concat(res.data.data));
          } else {
            setNotesList(res.data.data);
          }
          setMeta(res.data.meta);
          setLinks(res.data.links);
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsListLoading(false);

          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const fetchDepartamentList = () => {
    setIsListLoading(true);
    getDepartamentList()
      .then((res) => {
        if (res.status === 200) {
          setDepartamentList(
            res.data.data.map((el) => ({
              value: el.id,
              label: el.name,
            })),
          );
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => setIsListLoading(false));
  };

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      fetchDepartamentList();
      fetchNotesList();
    }

    return () => {
      componentMounted.current = false;
    };
  }, [PermissionList]);

  return (
    <>
      <div className={styles.tabTitle}>
        Notes{' '}
        {permission ? (
          <EditNotePopup
            type="create"
            updateComponent={getNotesByUser}
            userId={id}
            triggerBtn={<Button buttonText="+ Add note" buttonType="outline" onClick={null} />}
          />
        ) : null}
      </div>
      <Row>
        <Col md={12} lg={6} xl={4} className="mb-3">
          <JokerSelect
            id="department_id"
            name="department_id"
            label="Department"
            control={control}
            options={departamentList}
          />
        </Col>
        <Col md={12} lg={6} xl={4} className="mb-3">
          <DatePickerRange
            name="created_at"
            control={control}
            label="Creation date range"
            placeholder="Start date - End date"
          />
        </Col>
      </Row>
      <TableLayout
        header={
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.buttons}>
              <ReloadButton isLoading={isListLoading} onClick={() => onSubmit(getValues())} />
              <Button
                buttonType="outline"
                isLoading={isListLoading}
                type="button"
                buttonText="Reset"
                disabled={!isFormChange(dirtyFields)}
                onClick={() => {
                  reset({
                    department_id: null,
                    created_at: null,
                  });
                  fetchNotesList();
                }}
              />
              <Button
                buttonText="Apply"
                isLoading={isListLoading}
                onClick={handleSubmit(onSubmit)}
                disabled={!isFormChange(dirtyFields)}
              />
            </div>
          </form>
        }
      >
        {PermissionList ? (
          <div className="position-relative">
            {isListLoading ? <Spinner /> : null}
            <div className={cn(isListLoading && 'p-0 blur')}>
              {notesList.length > 0 && meta && links ? (
                <InfiniteScroll
                  dataLength={notesList.length}
                  next={() => fetchNotesList(savedFilters, true)}
                  hasMore={!!links.next}
                  height={600}
                  scrollableTarget="scrollableDiv"
                  loader={<></>}
                >
                  {notesList.map((el) => (
                    <NotesItem key={el.id} noteItem={el} updateNotesList={getNotesByUser} />
                  ))}
                </InfiniteScroll>
              ) : (
                <span className={styles.noItems}>No items</span>
              )}
            </div>
          </div>
        ) : null}
      </TableLayout>
    </>
  );
};

export default NotesTabs;
