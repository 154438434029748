import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lead } from 'models/Leads/Lead';
import { ICountry } from 'models/Countries';
import { deleteLead } from 'api/leads';
import { notify } from 'utils/notify';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { usePermission } from 'utils/usePermission';
import PopupYesOrNo from 'components/ui/PopupYesOrNo/PopupYesOrNo';
import Button from 'components/ui/Button';
import ChangeStatusPopup from './LeadPopups/ChangeStatusPopup';
import PromotePopup from './LeadPopups/PromotePopup';
import Notes from 'components/ui/Notes/Notes';
import { Icon } from 'components/ui/Icon';
import styles from './lead-page.module.scss';

type Props = {
  clientName: string;
  clientData: Lead;
  countries: ICountry[];
  updateCallback: () => void;
  updateNotesCallback: () => void;
};

export const LeadPageHeader = ({
  clientName,
  clientData,
  countries,
  updateCallback,
  updateNotesCallback,
}: Props) => {
  const { permissionGiven: PermissionNotesCreate } = usePermission('admin.notes.create');
  const { permissionGiven: PermissionPromote } = usePermission('admin.lead.promote');
  const { permissionGiven: PermissionDelete } = usePermission('admin.lead.delete');

  const [showNotes, setShowNotes] = useState(false);

  const navigate = useNavigate();

  const onClickDelete = () => {
    deleteLead(clientData.id)
      .then(() => {
        navigate('/search-leads');
      })
      .catch((error) => {
        console.log(error);
        notify({
          type: 'error',
          message: 'Lead not deleted',
          timeOut: 3000,
        });
      });
  };

  return (
    <div className={styles.pageTitle}>
      <div className={styles.title}>
        <div className={styles.name}>{clientName}</div>
        <div className={styles.uuid} onClick={() => copyToBuffer(clientData.uuid, true)}>
          {getShortUuid(clientData.uuid)}
        </div>
      </div>
      <div className={styles.buttons}>
        <ChangeStatusPopup
          updateComponent={updateCallback}
          user={clientData}
          triggerBtn={<Button buttonType="outline" buttonText="Change status" />}
        />
        {PermissionNotesCreate ? (
          <Button
            buttonType="outline"
            buttonText="Add note"
            onClick={() => setShowNotes((p) => !p)}
          />
        ) : null}
        {PermissionPromote ? (
          <PromotePopup
            updateComponent={updateCallback}
            user={clientData}
            countryList={countries}
            langList={[]}
            triggerBtn={<Button buttonType="outline" buttonText="Promote to client" />}
          />
        ) : null}
        {showNotes && (
          <Notes
            userId={clientData.id}
            handleClose={(show) => setShowNotes(show)}
            type="lead"
            updateComponent={updateNotesCallback}
          />
        )}
        {PermissionDelete && (
          <PopupYesOrNo
            onClick={onClickDelete}
            headerTitle={'Delete lead'}
            bodyText={'Are you sure you want to delete this lead?'}
            triggerBtn={
              <Button
                className={styles.deleteButton}
                buttonType="delete"
                icon={<Icon name="trash" size={18} />}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
