import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import PaginateSelect from 'components/ui/PaginateSelect/PaginateSelect';
import { ListValue } from 'models/ListValue';
import { ManagementDesksFilter } from 'models/Management/Desks/Filter';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { fetchOffices } from 'utils/managment/fetchData';

type Props = {
  deskTypes: ListValue[];
};

export const DesksPageFields = ({ deskTypes }: Props) => {
  const { control } = useFormContext<ManagementDesksFilter>();

  return (
    <Row>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <PaginateSelect
          isMulti={false}
          label="Office"
          control={control}
          id="office_ids"
          name="office_ids"
          onChange={(page, search) => fetchOffices(page, search)}
        />
      </Col>

      {deskTypes && (
        <Col xl={3} lg={4} md={6} className="mb-3">
          <JokerSelect
            label={'Desk Type'}
            control={control}
            id="types"
            name="types"
            options={deskTypes}
          />
        </Col>
      )}
    </Row>
  );
};
