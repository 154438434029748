import API from '../api';
import { APIDeleteImage, IData } from '../../models/API/SettingsLK/SettingsLK';

export const getStyleListMethod = () => API.get(`api/v1/get-style-list`);
export const setStyleListMethod = (data) => API.post(`/api/v1/theme`, data);
export const setStyleListOldMethod = (data) => API.post(`api/v1/set-styles`, data);
export const uploadLogoMethod = (data) => API.post(`api/v1/upload-logo`, data);
export const deleteImage = (data: IData) => API.post<APIDeleteImage>(`api/v1/delete-logo`, data);

export const getStatusTypes = () => API.get(`api/v1/status-type`);
export const getDynamicStatusesTypeModelList = () => API.get(`api/v1/status-type/model-type`);
export const getLanguagesList = () => API.get(`api/v1/locales`);
export const getDynamicStatusesList = (type) => API.get(`api/v1/status?type=${type}`);
export const createDynamicStatus = (data) => API.post(`api/v1/status`, data);
export const getDynamicStatusInfo = (id) => API.get(`api/v1/status-with-translates/${id}`);
export const deleteDynamicStatus = (id) => API.delete(`api/v1/status/${id}`);
export const updateDynamicStatus = (id, data) => API.put(`api/v1/status/${id}`, data);
export const changeColorDynamicStatus = (data) => API.post(`api/v1/settings/color-status`, data);
