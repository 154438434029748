import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './style.scss';
import NotificationsPopupFilter from './NotificationsPopupFilter';
import NotificationsPopupTable from '../Table/NotificationsPopupTable';
import { getSystemData } from 'api/system';
import { notify } from 'utils/notify';
import { findMyNotificationsMethod, markAsReadMethod, multyMarkAsRead } from 'api/notifications';
import TablePagination from 'components/ui/TablePagination/TablePagination';
import { getTablePageParams } from 'utils/getTablePageParams';
import { TableLinks, TableMeta } from 'models/Table';
import { IOptions } from 'models/components/Select/Select';
import { INotificationFilters } from 'models/components/NotificationPopup/NotificationPopup';
import { usePermission } from 'utils/usePermission';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleScrollToTop } from 'store/slice/tableSlice';
import { mapNotificationType } from 'constants/notifications';
import {
  decreaseNotificationCount,
  getNotifications,
  getNotificationsPage,
  readNotifications,
  setCurrentPage,
  setNotifications,
} from 'store/slice/notifySlice';
import { Icon } from '../Icon';
import { notification_close_icon_id } from '../Header/Header';

const cx = classNames.bind(styles);

const NotificationPopup = () => {
  const dispatch = useAppDispatch();

  const { permissionGiven: PermissionNotification } = usePermission('admin.notifications.view');

  const notificationsList = useAppSelector(getNotifications);
  const currentPage = useAppSelector(getNotificationsPage);

  const [filters, setFilters] = useState<INotificationFilters | null>(null);
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [tableMeta, setTableMeta] = useState<TableMeta>(undefined);
  const [tableLinks, setTableLinks] = useState<TableLinks>(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const [notificationTypesOpt, setNotificationTypesOpt] = useState<IOptions[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const prevCurrentPageRef = useRef<number>();

  const markAsRead = (id) => {
    markAsReadMethod(id).then((res) => {
      if (res && res.status === 200) {
        notify({ type: 'success', message: 'Marked as read' });
        dispatch(decreaseNotificationCount(1));
        dispatch(readNotifications([id]));
      }
    });
  };

  const markAsReadHandle = () => {
    setIsListLoading(true);
    const ids = selectedRows.reduce((result, row) => {
      if (!notificationsList[row.index].is_read) {
        result.push(notificationsList[row.index].id);
      }
      return result;
    }, []);
    multyMarkAsRead(ids);
    setTimeout(() => {
      dispatch(decreaseNotificationCount(ids.length));
      dispatch(readNotifications(ids));
      setIsListLoading(false);
    }, 3000);
  };

  const selectRowHandle = (row) => {
    setSelectedRows(row);
  };

  const fetchNotyficationsTypesOpt = () => {
    getSystemData()
      .then((res) => {
        if (res.status === 200) {
          setNotificationTypesOpt(
            res.data['notification_types'].map(
              (item: string): IOptions => ({
                value: item,
                label: mapNotificationType[item] || item,
              }),
            ),
          );
        }
      })
      .catch((error) => {
        if (error && error?.response?.data !== null) {
          notify({
            type: 'error',
            message: error?.response,
            timeOut: 3000,
          });
        }
      });
  };

  const fetchNotificationsList = (options?, filtersData?) => {
    setIsListLoading(true);
    findMyNotificationsMethod(getTablePageParams(options, perPageCount, tableLinks), filtersData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setNotifications(res.data.data));
          dispatch(setCurrentPage(res.data.meta.current_page));
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
        }
      })
      .catch((error) => {
        if (error && error?.response?.data !== null) {
          if (PermissionNotification) {
            notify({
              type: 'error',
              message: error?.response,
              timeOut: 3000,
            });
          }
        }
      })
      .finally(() => setIsListLoading(false));
  };

  useEffect(() => {
    fetchNotyficationsTypesOpt();
  }, []);

  useEffect(() => {
    fetchNotificationsList({ navigate: 'first', perPage: perPageCount }, filters);
  }, []);

  useEffect(() => {
    prevCurrentPageRef.current = currentPage;
  }, [currentPage]);

  return (
    <div className={cx('notificationPopup')}>
      <div className={cx('notificationPopup__header')}>
        <span>{`${tableMeta?.total || 0} Notifications found & Selected ${
          selectedRows.length
        }`}</span>
        <Icon
          id={notification_close_icon_id}
          name="close"
          size={16}
          color="var(--red)"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className={cx('notificationPopup__content')}>
        <NotificationsPopupFilter
          notificationsTypesOptions={notificationTypesOpt}
          onSubmit={(data) => {
            setFilters(data);
            fetchNotificationsList({ navigate: 'first', perPage: data?.filter?.limit }, data);
          }}
          reloadFilters={isListLoading}
        />
        <NotificationsPopupTable
          markAsReadDisableBtn={!selectedRows.length}
          markAsReadHandle={markAsReadHandle}
          onRowSelect={selectRowHandle}
          data={notificationsList}
          perPage={perPageCount}
          showLoader={isListLoading}
          className={cx('notificationPopupTable')}
          markAsRead={(id) => markAsRead(id)}
        />
        {notificationsList?.length ? (
          <TablePagination
            currentPage={tableMeta?.current_page}
            pagesLength={tableMeta?.last_page}
            perPageChange={(value): void => {
              setPerPageCount(value);
              fetchNotificationsList({ perPage: value }, filters);
              dispatch(toggleScrollToTop());
            }}
            goToSelectedPage={(page): void => {
              fetchNotificationsList({ page });
              dispatch(toggleScrollToTop());
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default NotificationPopup;
