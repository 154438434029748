import styles from './notesTabs.module.scss';
import moment from 'moment';
import EditNotePopup from './EditNotePopup';
import { deleteNote } from 'api/notes';
import { notify } from 'utils/notify';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { usePermission } from 'utils/usePermission';
import { Icon } from 'components/ui/Icon';

const NotesItem = ({ noteItem, updateNotesList }) => {
  const { permissionGiven: PermissionNotesDelete } = usePermission('admin.notes.delete');
  const { permissionGiven: PermissionNotesUpdate } = usePermission('admin.notes.update');

  const removeNote = () => {
    deleteNote(noteItem?.id)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: 'Notes deleted successfully',
            timeOut: 3000,
          });
          updateNotesList();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  return (
    <div className={styles.note}>
      <div className={styles.header}>
        <div>
          <div className={styles.header__name}>
            {`${noteItem.operator.first_name} ${
              noteItem && noteItem.operator && noteItem.operator.last_name
                ? noteItem.operator.last_name
                : ''
            }`}{' '}
            {noteItem && noteItem.is_pinned ? (
              <span className={styles.header__pinned}>Pinned</span>
            ) : null}
          </div>
          <div className={styles.header__uuid}>
            {noteItem && noteItem.operator.uuid ? (
              <span
                className="copuToBuffer"
                onClick={() => copyToBuffer(noteItem.operator.uuid, true)}
              >
                {getShortUuid(noteItem.operator.uuid)}
              </span>
            ) : (
              '---'
            )}
          </div>
          <div className={styles.header__date}>
            {moment(noteItem.create_at * 1000).format('DD.MM.YYYY HH:mm:ss')} to{' '}
            <span className="copuToBuffer" onClick={() => copyToBuffer(noteItem.to, true)}>
              {getShortUuid(noteItem.to)}
            </span>
          </div>
        </div>
        <div className={styles.header__actions}>
          {PermissionNotesUpdate ? (
            <EditNotePopup
              updateComponent={updateNotesList}
              noteData={noteItem}
              triggerBtn={
                <Icon name="notePencil" size={20} color="var(--main-color)" onClick={null} />
              }
              type="edit"
            />
          ) : null}

          {PermissionNotesDelete ? (
            <Icon name="trash" size={20} color="var(--red)" onClick={removeNote} />
          ) : null}
        </div>
      </div>
      <div>
        <div className={styles.subject}>{noteItem.subject}</div>
        <div className={styles.body}>{noteItem.body}</div>
      </div>
    </div>
  );
};
export default NotesItem;
