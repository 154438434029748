import { useState } from 'react';
import { Control, UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { TransactionDetailFormValues } from '../TransactionDetailPopup/TransactionDetailPopup';
import { Col, Container, Row } from 'react-bootstrap';
import { truncate } from 'lodash';
import Popup from 'reactjs-popup';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate.tsx';
import FormInput from 'components/ui/FormInput/FormInput';
import Button from 'components/ui/Button';
import cn from 'classnames';
import styles from './external-id.module.scss';

type Props = {
  name: 'reference_id';
  control: Control<TransactionDetailFormValues, any>;
  canEdit: boolean;
  id?: string | null;
  className?: string;
  reset: UseFormReset<TransactionDetailFormValues>;
  setValue: UseFormSetValue<TransactionDetailFormValues>;
  getValues: UseFormGetValues<TransactionDetailFormValues>;
};

export const ExternalId = ({
  name,
  control,
  canEdit,
  id,
  className,
  reset,
  setValue,
  getValues,
}: Props) => {
  const [editedValue, setEditedValue] = useState('');
  const [isShowFullValue, setIsShowFullValue] = useState(false);

  const value = editedValue ? editedValue : getValues(name);
  const truncatedValue = truncate(value, { length: isShowFullValue ? 255 : 32 });

  const isLongValue = value && value.length > 32;

  const renderValue = () => (
    <span
      className={cn(styles.v, !isShowFullValue && isLongValue && styles.value)}
      onClick={() => isLongValue && setIsShowFullValue(true)}
    >
      {truncatedValue || '---'}
    </span>
  );

  if (!canEdit) {
    return (
      <>
        <p className={className}>External Id</p>
        {renderValue()}
      </>
    );
  }

  return (
    <>
      <Popup
        key={'External Id'}
        nested
        modal
        closeOnDocumentClick={false}
        closeOnEscape={false}
        trigger={() => <p className={cn(className, styles.title)}>External Id</p>}
      >
        {(close: () => void) => (
          <PopupTemplate
            size="sm"
            dismissModal={close}
            headerTitle="Edit External ID"
            rightContent={
              <Container>
                <Row>
                  <Col md={12}>
                    <FormInput control={control} name={name} label="External ID" maxLength={255} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={styles.buttons}>
                      <Button
                        buttonText="Cancel"
                        buttonType="outline"
                        onClick={() => {
                          close();
                          !editedValue && reset({ reference_id: id });
                        }}
                      />
                      <Button
                        buttonType="primary"
                        buttonText="Save"
                        type="submit"
                        onClick={() => {
                          setEditedValue(getValues(name));
                          setValue(name, getValues(name));
                          setIsShowFullValue(false);
                          close();
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            }
          />
        )}
      </Popup>
      {renderValue()}
    </>
  );
};
