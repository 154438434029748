import { SimpleInput } from 'components/ui/SimpleInput';
import styles from './table-pagination-input.module.scss';

type Props = {
  max: number;
  defaultValue: number;
  pageCount: number;
  onChangePage: (page: number) => void;
};

export const TablePaginationInput = ({ pageCount, onChangePage, ...props }: Props) => {
  return (
    <SimpleInput
      type="number"
      label="Go to"
      min={1}
      inputClassName={styles.input}
      fieldClassName={styles.field}
      {...props}
      onChange={(e) => {
        let page = e.target.value ? Number(e.target.value) : 0;

        if (page > pageCount) {
          e.target.value = pageCount.toString();
          page = pageCount;
        } else if (page < 0) {
          e.target.value = '1';
          page = 1;
        }

        onChangePage(page);
      }}
    />
  );
};
