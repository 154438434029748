import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { TABLE_CALLBACKS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const cllientCallbacksDefaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  created_at: '',
  statuses: undefined,
};

export const useCallbacks = () => {
  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: cllientCallbacksDefaultValues,
  });

  const table = useTableColumnOrder('TABLE_CALLBACKS_COLUMNS_NAMES', TABLE_CALLBACKS_COLUMNS_NAMES);

  return { form, table };
};
