import { Pencil } from "phosphor-react";
import { useAppDispatch } from "../../../../store";
import { openJokerModal } from "../../../../store/slice/modalSlice";
import { MenuOption } from "./MenuOption";
import { RENAME_GROUP_CHAT_MODAL_KEY } from "../modals/RenameGroupModal/RenameGroupModal";

export const GroupRenameOption = () => {
    const dispatch = useAppDispatch();

    const openParticipantsModal = () => {
        dispatch(openJokerModal({ key: RENAME_GROUP_CHAT_MODAL_KEY }));
    };

    return (
        <MenuOption title='Rename group' onClick={openParticipantsModal}>
            <Pencil size={32} color="var(--main-color)"/>
        </MenuOption>
    )
}