import { Icon } from 'components/ui/Icon';
import { ChatBadge } from '../ChatBadge/ChatBadge';
import './ChatIcon.scss';

export const ChatIcon = ({ messagesCount }) => {
  return (
    <div className="chat-icon">
      <Icon name="chat" />
      {messagesCount ? <ChatBadge count={messagesCount} /> : null}
    </div>
  );
};
