import React from "react";
import { useAppDispatch } from "../../../../store";
import { deleteParticipant } from "../../../../store/slice/chat/thunks";
import { MenuOption } from "./MenuOption";
import { Icon } from "components/ui/Icon";

type DeleteParticipantOptionProps = {
    id: string;
    threadId: string;
    ownerId;
};

export const DeleteParticipantOption = ({ id, threadId, ownerId }: DeleteParticipantOptionProps) => {
    const dispatch = useAppDispatch();

    const handleDeleteParticipant = () => {
        dispatch(deleteParticipant({ threadId, ownerId, id }));
    };

    return (
        <MenuOption title='Remove' onClick={handleDeleteParticipant}>
            <Icon name="trash" size={16} color="var(--red-color-hover)"/>
        </MenuOption>
    )
}