import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import {
  RAP_ActiveRole as ActiveRole,
  PermissionList,
} from 'models/RolesAndPermissions/Permissions.ts';
import Button from 'components/ui/Button';
import { Permissions } from './permissions/Permissions';
import { resetToDefaultPermissions } from 'api/rolesAndPermissions';
import { NoPermissionText } from './NoPermissionText';

import styles from '../rolesPermissions-page.module.scss';

type Props = {
  role: ActiveRole;
  activeRoleId: string;
  permissions: PermissionList;
  PermissionList: boolean;
  onUpdatePermission: (action: string, show: boolean, id: number) => void;
  onResetToDefaultPermissions: () => void;
};

export const DepartmentRole = ({
  role,
  activeRoleId,
  permissions,
  PermissionList,
  onUpdatePermission,
  onResetToDefaultPermissions,
}: Props) => {
  const [isResetLoading, setIsResetLoading] = useState(false);

  const userRole = JSON.parse(localStorage.getItem('role'))[0];

  const onReset = () => {
    setIsResetLoading(true);
    resetToDefaultPermissions(activeRoleId)
      .then((r) => {})
      .finally(() => {
        onResetToDefaultPermissions();
        setIsResetLoading(false);
      });
  };

  return (
    <Accordion.Item key={role.id} eventKey={String(role.id)}>
      <Accordion.Header>{role.name}</Accordion.Header>
      <Accordion.Body>
        {userRole === 'Super Admin' && PermissionList ? (
          <Button
            className={styles.resetButton}
            isLoading={isResetLoading}
            buttonText="Reset to Default"
            buttonType="outline"
            type="reset"
            onClick={onReset}
          />
        ) : null}
        {!PermissionList && <NoPermissionText />}
        <Permissions permissions={permissions} onUpdatePermission={onUpdatePermission} />
      </Accordion.Body>
    </Accordion.Item>
  );
};
