import cn from 'classnames';
import styles from './table-pagination-button.module.scss';

type Props = {
  page: number;
  isSelected: boolean;
  onClick: () => void;
};

export const TablePaginationButton = ({ page, isSelected, onClick }: Props) => {
  return (
    <button className={cn(styles.button, isSelected && styles.button__selected)} onClick={onClick}>
      {page}
    </button>
  );
};
