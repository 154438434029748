import { Client } from 'models/Clients/Clients';
import cn from 'classnames';

import styles from './client-sales.module.scss';

type Props = {
  client: Client;
  onClick: () => null;
};

export const ClientSales = ({ client, onClick }: Props) => {
  return (
    <div
      style={{ border: `1px solid ${client?.color_status || 'var(--main-color)'}` }}
      className={cn(
        styles.container,
        client?.assigned_status_types?.hasOwnProperty('sales') && styles.active,
      )}
      onClick={onClick}
    >
      <div className={styles.title}>
        <span>Sales: </span>
        <span style={{ color: `${client?.color_status || 'var(--main-color)'}` }}>
          {client?.status_name || 'None'}
        </span>
      </div>
      <div className={styles.info}>
        <div>
          <span>
            {client?.sales?.operator?.full_name ? client?.sales?.operator?.full_name : '---'}
          </span>
        </div>
        <div>
          <span>Desk: </span>
          {client?.sales?.team?.desk?.name ? client?.sales?.team?.desk?.name : '---'}
        </div>
        <div>
          <span>Team: </span>
          {client?.sales?.team?.name ? client?.sales?.team?.name : '---'}
        </div>
      </div>
    </div>
  );
};
