import { changeOperatorInTransaction } from 'api/clients';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button/Button';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { firstLetterUppercase } from 'utils/firstLetterUppercase';
import styles from './change-operator-popup.module.scss';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import PaginateSelect from 'components/ui/PaginateSelect';
import { fetchOperatorsForBulkActions, fetchTeamsForDesk } from 'utils/managment/fetchData';
import { notify } from 'utils/notify';
import { deskList } from 'api/desk';
import JokerSelect from 'components/ui/JokerSelect';

const cx = classNames.bind(styles);

const ChangeOperatorPopup = ({ trigger, user, onCloseModal, transactionId, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [desk, setDesk] = useState();
  const [teams, setTeams] = useState();
  const currentLang = localStorage.getItem('lang');
  const componentMounted = useRef(true);
  const [desksList, setDesksList] = useState([]);
  const [typeDesk, setTypeDesk] = useState('');

  const setDefaultValues = () => {
    return {
      desk_id: null,
      team_id: null,
      admin_user_id: null,
      transaction_id: null,
    };
  };
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const closeModal = (closeModal) => {
    setDesk(null);
    setTeams(null);
    reset(setDefaultValues());
    closeModal();
    onCloseModal?.();
  };

  const onSubmit = async (close, data) => {
    setIsLoading(true);

    const postData = {
      type: typeDesk,
      desk_id: getValue(data['desk_id']),
      team_id: getValue(data['team_id']),
      admin_user_id: getValue(data['admin_user_id']),
      user_id: user.id,
    };

    changeOperatorInTransaction(transactionId, postData)
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          onSuccess();
          NotificationManager.success(`Change was applied`, 'Success', 3000);
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const onOpen = () => {};
  const setDefaultValuesTeam = () => {
    return {
      type: typeDesk,
      desk_id: getValues('desk_id'),
      team_id: '',
      admin_user_id: '',
      user_id: user.id,
      transaction_id: transactionId,
    };
  };
  const setDefaultValuesOperator = () => {
    return {
      type: typeDesk,
      desk_id: getValues('desk_id'),
      team_id: getValues('team_id'),
      admin_user_id: '',
      user_id: user.id,
      transaction_id: transactionId,
    };
  };
  const updateTeamWithDesk = (desk) => {
    setTypeDesk(desk.type);
    setDesk(desk);
    reset(setDefaultValuesTeam() as any);
  };
  const updateOperatorWithTeam = (team) => {
    setTeams(team);
    reset(setDefaultValuesOperator());
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  useEffect(() => {
    deskList('per_page=100').then((res) => {
      const opt = res.data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
        type: opt.type,
      }));

      setDesksList(opt);
    });
  }, []);

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <div className={cx('popup-title')}>
                <span>{`${firstLetterUppercase('Change operator', currentLang)}`}</span>
                <span>{`Client ${user.user_profile.first_name} ${user.user_profile.last_name}`}</span>
              </div>
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-title')}></div>
                <div className={cx('content-form')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <JokerSelect
                            isRequired={true}
                            label="Desks"
                            control={control}
                            id="desk_id"
                            name="desk_id"
                            options={desksList}
                            rules={{
                              required: 'Desk field is required',
                            }}
                            onSelectChange={() => {
                              updateTeamWithDesk(getValues('desk_id'));
                              updateOperatorWithTeam(getValues('team_id'));
                            }}
                            errors={errors.desk_id}
                          />
                        </Col>
                        <Col md={12} className="mb-3">
                          <PaginateSelect
                            isRequired={true}
                            isMulti={false}
                            label="Teams"
                            control={control}
                            id="team_id"
                            name="team_id"
                            onChange={(page, search, desk) => {
                              return fetchTeamsForDesk(page, search, desk?.value);
                            }}
                            mainVal={desk}
                            onSelectChange={() => {
                              updateOperatorWithTeam(getValues('team_id'));
                            }}
                            cacheUniqs={[getValues('team_id'), getValues('desk_id')]}
                            errors={errors.team_id}
                            rules={{
                              required: 'Teams field is required',
                            }}
                          />
                        </Col>
                        <Col md={12} className="mb-3">
                          <PaginateSelect
                            isRequired={true}
                            isMulti={false}
                            label="Representative"
                            control={control}
                            id="admin_user_id"
                            name="admin_user_id"
                            onChange={(page, search, teams) => {
                              return fetchOperatorsForBulkActions(typeDesk, teams?.value);
                            }}
                            cacheUniqs={[getValues('team_id'), getValues('desk_id')]}
                            mainVal={teams}
                            errors={errors.admin_user_id}
                            rules={{
                              required: 'Representative field is required',
                            }}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          type="button"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          type="submit"
                          onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                          buttonText={'Apply changes'}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default ChangeOperatorPopup;
