import React from "react";
import { CrownSimple } from "phosphor-react";
import { useAppDispatch } from "../../../../store";
import { promoteParticipant } from "../../../../store/slice/chat/thunks";
import { MenuOption } from "./MenuOption";

type PromoteAdminOptionProps = {
    id: string;
    threadId: string
};

export const PromoteAdminOption = ({ id, threadId }: PromoteAdminOptionProps) => {
    const dispatch = useAppDispatch();

    const handlePromoteParticipant = () => {
        dispatch(promoteParticipant({ threadId, id }));
    };

    return (
        <MenuOption title='Promote admin' onClick={handlePromoteParticipant}>
            <CrownSimple size={32} color="var(--main-color)"/>
        </MenuOption>
    )
}