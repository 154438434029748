import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './сallbacksTable.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';

import moment from 'moment';

import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { CallbacksTableAdminProps } from 'models/components/Table/CallbacksTableAdmin/CallbacksTableAdmin.ts';
import { Link } from 'react-router-dom';
import { upperFirst } from 'lodash';

const cx = classNames.bind(styles);

const CallbacksTableAdmin: FC<CallbacksTableAdminProps> = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, operator, client, time, created_at, updated_at, status, reminders } = item;

    return {
      operator: (
        <div className={cx('callback-table_td')}>
          <span className={cx('callback-table_td__operator')}>{operator?.full_name || '---'}</span>
          <span
            className={cx('callback-table_td__uuid')}
            onClick={() => copyToBuffer(operator?.uuid, true)}
          >
            {getShortUuid(operator?.uuid) || '---'}
          </span>
        </div>
      ),
      client: (
        <div className={cx('callback-table_td')}>
          <Link
            target={'_blank'}
            to={`/client/${client?.id ?? 0}`}
            className={cx('callbacks-table_td-link')}
            style={{ display: 'block' }}
          >
            <span className={cx('callback-table_td__client')}>
              {`${client?.user_profile?.first_name ? client?.user_profile?.first_name : ''} ${
                client?.user_profile?.last_name ? client?.user_profile?.last_name : ''
              }`}
            </span>
          </Link>
          <span
            className={cx('callback-table_td__uuid')}
            onClick={() => copyToBuffer(client?.uuid, true)}
          >
            {getShortUuid(client?.uuid) || '---'}
          </span>
        </div>
      ),
      time: (
        <div className={cx('callback-table_td')}>
          <span className={cx('date')}>{moment(time * 1000).format('DD.MM.YYYY') || '---'}</span>
          <span className={cx('date')}>{moment(time * 1000).format('HH:mm:ss') || '---'}</span>
        </div>
      ),
      created_at: (
        <div className={cx('callback-table_td')}>
          <span className={cx('date')}>{moment(created_at * 1000).format('DD.MM.YYYY')}</span>
          <span className={cx('date')}>{moment(created_at * 1000).format('HH:mm:ss')}</span>
        </div>
      ),
      updated_at: (
        <div className={cx('callback-table_td')}>
          <span className={cx('date')}>{moment(updated_at * 1000).format('DD.MM.YYYY')}</span>
          <span className={cx('date')}>{moment(updated_at * 1000).format('HH:mm:ss')}</span>
        </div>
      ),
      status: (
        <div className={cx('callback-table_td')}>
          <span className={cx(status)}>{upperFirst(status) || '---'}</span>
        </div>
      ),
      reminders: (
        <div className={cx('callback-table_td')}>
          {reminders.length ? (
            <>
              <span className={cx('date')}>{moment(reminders[0] * 1000).format('DD.MM.YYYY')}</span>
              {reminders.map((item) => (
                <span>{moment(item * 1000).format('HH:mm')}</span>
              ))}
            </>
          ) : null}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('callback-table')}
      isSelected={false}
      saveColumnOrder={saveColumnOrder}
      getCellProps={(cellInfo, cx) => {
        const { row } = cellInfo;
        const odd = row.index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default CallbacksTableAdmin;
