import { NestedCryptoTransaction } from 'models/CustomerService/CryptoTransaction';
import { TransactionPageAcceptedCrypto } from './TransactionPageAcceptedCrypto';
import { TransactionPageAcceptedCryptoClient } from './TransactionPageAcceptedCryptoClient';

import styles from './transaction-page-crypto-info.module.scss';

type Props = {
  cryptoTransaction: NestedCryptoTransaction;
  currencyType: string;
};

export const TransactionPageCryptoInfo = ({ cryptoTransaction, currencyType }: Props) => {
  return (
    <div className={styles.container}>
      <TransactionPageAcceptedCrypto {...{ cryptoTransaction, currencyType }} />
      <TransactionPageAcceptedCryptoClient {...{ cryptoTransaction }} />
    </div>
  );
};
