import React, { useState } from "react";
import Button from "../../../../../components/ui/Button";
import { ChatSelectSearch } from "../../ChatSelectSearch/ChatSelectSearch";
import { useForm, useWatch } from "react-hook-form";
import { addParticipants, searchChatUsers } from "../../../../../store/slice/chat/thunks";
import { useAppDispatch } from "../../../../../store";
import { UserTypes } from "../../../../../models/Chat";

export const AddParticipants = ({threadId, participants, switchMode}) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { handleSubmit, control, setValue } = useForm({ reValidateMode: 'onChange' });

    const users = useWatch({ control, name: "users" });

    const onSearchUsers = async (search) => {
        const users = await searchChatUsers(search);
        return users
            .filter(user => !participants[user.value] && user.base.type === UserTypes.MANAGERS)
            .map(user => ({label: user.name, value: user.provider_id}));
    }

    const onSubmit = (data) => {
        setIsLoading(true);
        dispatch(addParticipants({
            threadId,
            data: { providers: (data?.users || []).map(user => ({ alias: 'user', id: user.value })) }
        })).finally(() => {
            setIsLoading(false);
            switchMode();
        });
    };

    return (
        <div className='add-participants-form'>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <ChatSelectSearch
                    id='add_group_users'
                    name="users"
                    label='Search user(s)'
                    placeholder="Choose user(s)"
                    control={control}
                    isMulti={true}
                    onChange={onSearchUsers}
                    setValue={setValue}
                    disabled={isLoading}
                />
                <div className='content-controls'>
                    <Button
                        buttonText="Cancel"
                        buttonType="outline"
                        type="button"
                        onClick={switchMode}
                        disabled={isLoading}
                    />
                    <Button
                        buttonText={'Invite selected'}
                        isLoading={isLoading}
                        disabled={!users?.length}
                        type="submit"
                        onClick={handleSubmit((data) => onSubmit(data))}
                    />
                </div>
            </form>
        </div>
    )
};
