import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React, { FC } from 'react';
import { copyToBuffer } from 'utils/copyToBuffer';
import { makeTableData } from '../../../../helpers/table.helper';
import CreateOrEditDesksPopup from '../../../../pages/Management/Desks/DesksPopups/CreateOrEditDesksPopup';
import DeleteDesksPopup from '../../../../pages/Management/Desks/DesksPopups/DeleteDesksPopup';
import { getShortUuid } from '../../../../utils/getShortUuid';
import { usePermission } from '../../../../utils/usePermission.tsx';
import Table from '../Table';
import styles from './desks-table.module.scss';
import { IDesksTableProps } from '../../../../models/components/Table/DesksTable/DesksTable.ts';
import { ManagementDeskPopup } from './Popup.tsx';
import { Icon } from 'components/ui/Icon';
import { upperFirst } from 'lodash';

const cx = classNames.bind(styles);

const DesksTable: FC<IDesksTableProps> = ({
  data,
  langList,
  officeList,
  deskTypeList,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  columns,
  saveColumnOrder,
}) => {
  const { permissionGiven: PermissionDelete } = usePermission('admin.desk.delete');
  const { permissionGiven: PermissionUpdate } = usePermission('admin.desk.update');

  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, langList, deskTypeList, officeList],
  );

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name, uuid, office, type, language } = item;
    return {
      desk: (
        <div className={cx('rule__name')}>
          <ManagementDeskPopup
            triggerElement={<span className={cx('rule__id')}>{name}</span>}
            deskID={id}
            deskName={name}
          />
          <span className={cx('rule__id')} onClick={() => copyToBuffer(uuid, true)}>
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      office: (
        <div className={cx('rule__name')}>
          <span>{office?.name}</span>
          <span className={cx('rule__id')} onClick={() => copyToBuffer(office?.uuid, true)}>
            {getShortUuid(office?.uuid)}
          </span>
        </div>
      ),
      deskType: (
        <div className={cx('rule__name')}>
          <span className={cx('rule__name-normal')}>{upperFirst(type)}</span>
        </div>
      ),
      language: (
        <div className={cx('rule__name')}>
          <span className={cx('rule__name-normal')}>{upperFirst(language?.name)}</span>
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {PermissionUpdate ? (
              <CreateOrEditDesksPopup
                deskTypeList={deskTypeList}
                officeList={officeList}
                langList={langList}
                ruleData={item}
                actionType={SALES_RULES_ACTION_TYPE.EDIT}
                triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
                onCloseModal={() => console.log('a')}
                updateComponent={modifyDataHandler}
              />
            ) : null}
          </div>
          <div>
            {PermissionDelete ? (
              <DeleteDesksPopup
                rule={item}
                triggerBtn={<Icon name="trash" size={20} color="var(--red)" />}
                updateComponent={modifyDataHandler}
              />
            ) : null}
          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default DesksTable;
