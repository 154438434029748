import Button from 'components/ui/Button';
import { Icon } from 'components/ui/Icon';
import { BulkActions } from './BulkActions';
import cn from 'classnames';
import styles from './search-clients-title.module.scss';

type Props = {
  total?: number;
  isExpanded: boolean;
  isLoading: boolean;
  selectedRows: number[];
  selectedClients: string[];
  onToggleFilters: () => void;
};

export const SearchClientsPageTitle = ({
  total,
  isExpanded,
  selectedRows = [],
  selectedClients = [],
  onToggleFilters,
}: Props) => {
  const selectedRowsCount = selectedRows.length;

  return (
    <div className={styles.pageTitle}>
      <div className={styles.titleBlock}>
        <p>{`${total || 0} Clients found & Selected ${selectedRowsCount}`}</p>
        {selectedRowsCount > 0 ? (
          <BulkActions selectedRows={selectedRows} selectedClients={selectedClients} />
        ) : null}
      </div>
      <div className={styles.buttonsBlock}>
        <Button
          className={cn(
            styles.toggleFilterButton,
            isExpanded && styles.toggleFilterButton__rotated,
          )}
          onClick={onToggleFilters}
          icon={<Icon name="doubleCaretDown" size={20} />}
        />
      </div>
    </div>
  );
};
