import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';

import { makeTableData } from 'helpers/table.helper';
import EditNameRolePopup from 'pages/Templates/RolesTab/EditNameRolePopup';
import EditTemplateRolePopup from 'pages/Templates/RolesTab/EditTemplateRolePopup';
import React from 'react';
import Button from '../../../Button';
import Table from '../../Table';
import styles from './style.module.scss';

const cx = classNames.bind(styles);

const RoleTable = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  manualSortHandler,
  colSortCheck,
  templateList,
  onSort,
  PermissionEdit,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data, templateList]);
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name } = item;
    return {
      name: (
        <div className={cx('rule__name')}>
          <span>{name}</span>
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          {PermissionEdit ? (
            <>
              <EditNameRolePopup
                dataItem={item}
                actionType={SALES_RULES_ACTION_TYPE.EDIT}
                updateComponent={modifyDataHandler}
                triggerBtn={
                  <Button buttonText={'Change name'} onClick={() => null} buttonType={'outline'} />
                }
              />
              <EditTemplateRolePopup
                templateList={templateList}
                dataItem={item}
                actionType={SALES_RULES_ACTION_TYPE.EDIT}
                updateComponent={modifyDataHandler}
                triggerBtn={
                  <Button
                    buttonText={'Change template'}
                    onClick={() => null}
                    buttonType={'outline'}
                  />
                }
                // onCloseModal={() => {
                // }}
              />
            </>
          ) : null}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      saveColumnOrder={saveColumnOrder}
      getCellProps={(cellInfo, cx) => {
        const { row } = cellInfo;
        const odd = row.index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default RoleTable;
