import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './files.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import FormInput from 'components/ui/FormInput';
import { updateStatusFile } from 'api/documents';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

const cx = classNames.bind(styles);

const fileStatusOpt = [
  {
    value: 1,
    label: 'new',
  },
  {
    value: 2,
    label: 'approved',
  },
  {
    value: 3,
    label: 'rejected',
  },
];

const EditFilesPopup = ({ triggerBtn, updateComponent, editData, typesOpt }) => {
  const [categoryOptions, setCategoryOptions] = useState();
  const [typesOptions, setTypesOptions] = useState();

  const [categoryValue, setCategoryValue] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, reset, getValues, formState } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
    updateComponent();
  };

  const onSubmit = (close, data) => {
    setIsLoading(true);
    const sendData = {
      original_name: data.original_name,
      type_key: data.type_key.value,
      category_key: data.category_key.value,
      user_id: editData.user_id,
      status: data.status?.label,
    };

    updateStatusFile(editData.id, sendData)
      .then((res) => {
        if (res.status === 200) {
          updateComponent();
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          closeModal(close);
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const filterCategoryOpt = () => {
    const opt = typesOpt.map((item) => ({
      value: item.key,
      label: item.translate,
    }));
    const filterPayment = opt.filter(
      (item) => item.value !== 'proof_of_payment' || item.label !== 'proof_of_payment',
    );
    setCategoryOptions(filterPayment);
  };

  useEffect(() => {
    filterCategoryOpt();
  }, []);

  useEffect(() => {
    if (editData !== undefined && typesOpt.length > 0) {
      const val = typesOpt.filter((opt) => opt.value === editData.kyc_category.value);

      setCategoryValue(val[0].value);
      changeTypesDefaultValue(val[0].value);

      const filterId = val[0].activeStatuses
        .filter((item) => item.key !== 'id_card' || item.translate !== 'id_card')
        .map((item) => ({ value: item.key, label: item.translate }));

      setTypesOptions(filterId);

      reset({
        original_name: editData.original_name,
        status: { value: editData.status, label: editData.status },
        category_key: {
          value: editData.kyc_category.key,
          label: editData.kyc_category.translate,
        },
        type_key: { value: editData.kyc_type.key, label: editData.kyc_type.key },
      });
    }
  }, [editData]);

  const changeTypesDefaultValue = (option) => {
    if (option) {
      const typeOpt = typesOpt.filter((el) => el.key === option?.value)[0]?.activeStatuses;

      if (typeOpt) {
        const filterId = typeOpt
          .filter((item) => item.key !== 'id_card' || item.translate !== 'id_card')
          .map((item) => ({ value: item.key, label: item.translate }));
        setTypesOptions(filterId);

        reset({
          ...getValues(),
          category_key: {
            value: option.value,
            label: option.label,
          },
          type_key: { value: filterId[0].key, label: filterId[0].translate },
        });

        return;
      }
      setTypesOptions(typeOpt);
      reset({ ...formValues, type_key: null });
    } else {
      const typeOpt = typesOpt.filter((el) => el.key === editData?.kyc_category?.value)[0]
        ?.activeStatuses;

      setTypesOptions(typeOpt);
    }
  };

  useEffect(() => {
    filterCategoryOpt();
  }, [categoryValue]);

  const formValues = getValues();

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            size="xs"
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Edit file'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'editFilesForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row className={cx('form-line')}></Row>
                      <Row className={cx('form-line')}>
                        <Col xs={12} className="mb-3">
                          <FormInput control={control} name="original_name" label="Name" />
                        </Col>
                        <Col xs={12} className="mb-3">
                          <JokerSelect
                            id="status"
                            name="status"
                            label="Change status"
                            control={control}
                            options={fileStatusOpt}
                          />
                        </Col>
                        <Col xs={12} className="mb-3">
                          <JokerSelect
                            id="category_key"
                            name="category_key"
                            label="Change category"
                            control={control}
                            options={categoryOptions}
                            onSelectChange={(option) => changeTypesDefaultValue(option)}
                          />
                        </Col>
                        <Col xs={12} className="mb-3">
                          <JokerSelect
                            id="type_key"
                            name="type_key"
                            label="Change type"
                            control={control}
                            options={typesOptions}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <div className={cx('content-controls')}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                buttonText={'Change'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default EditFilesPopup;
