import { ManagementPartner } from 'models/Management/Partners/Partner';
import moment from 'moment';
import styles from './partner-personal-info.module.scss';

type Props = {
  partner: ManagementPartner;
};

export const PartnerPersonalInfo = ({ partner }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <span>Name:</span>
        {partner && partner?.first_name}
      </div>
      <div className={styles.row}>
        <span>Email:</span>
        {partner && partner?.email ? partner.email : '---'}
      </div>
      <div className={styles.row}>
        <span>Phone number:</span>
        {partner && partner?.phone ? partner.phone : '---'}
      </div>
      {partner && partner?.created_at ? (
        <div className={styles.row}>
          <span>Registration date:</span>
          <span>{moment.unix(partner.created_at).format('DD.MM.YYYY')}</span>{' '}
          <span>{moment.unix(partner.created_at).format('HH:mm')}</span>
        </div>
      ) : null}
    </div>
  );
};
