import { getCryptoTransaction } from 'api/customer-service/crypto-payments';
import { getCryptoExchangeRate } from 'api/customer-service/request';
import cn from 'classnames';
import Button from 'components/ui/Button';
import CryptoInnerTransactionsTable from 'components/ui/Table/CryptoInnerTransactionsTable/CryptoInnerTransactionsTable';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { usePermission } from '../../../utils/usePermission';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import { CryptoTransaction } from 'models/CustomerService/CryptoTransaction';
import { usePageTitle } from 'utils/usePageTitle';
import { Icon, IconName } from 'components/ui/Icon';
import { TransactionPageInfo } from './TransactionPageInfo/TransactionPageInfo';
import { TransactionPageCryptoInfo } from './TransactionPageAcceptedCrypto/TransactionPageCryptoInfo';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import { TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES } from 'constants/table';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { Spinner } from 'components/ui/Spinner';
import styles from './transaction-page.module.scss';

const TransactionPage = () => {
  const { permissionGiven: PermissionModerateReceivedCryptoPayments } = usePermission(
    'admin.crypto_transaction.edit',
  );

  const params = useParams();

  const [hash, setHash] = useState('');
  const [currencyType, setCurrencyType] = useState('');
  const [cryptoFullType, setCryptoFullType] = useState('');
  const [transactionData, setWalletData] = useState<CryptoTransaction>();

  const [isLoading, setIsLoading] = useState(true);
  const [cryptoRate, setCryptoRate] = useState('');

  let title = currencyType + ' Transaction - ' + hash;

  const componentMounted = useRef(false);

  useEffect(() => {
    title = 'Transaction...';
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchCryptoTransaction = (params) => {
    setIsLoading(true);

    getCryptoTransaction(params.id)
      .then((res) => {
        if (componentMounted.current) {
          setWalletData(res.data.data);
          setHash(res.data.data.crypto_hash);
          setCurrencyType(res.data.data.crypto_type);
          setCryptoFullType(res.data.data.crypto_type_full);
          fetchCryptoRate();
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  usePageTitle(title);

  const fetchCryptoRate = () => {
    getCryptoExchangeRate(transactionData.crypto_type.toLowerCase()).then((res) => {
      setCryptoRate(res.data.data?.rateUsd);
    });
  };

  useEffect(() => {
    fetchCryptoTransaction(params);
  }, [params]);

  const { columns, saveColumnOrder, setDefaultColumnsOrder } = useTableColumnOrder(
    'TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES',
    TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES,
  );

  const Title = () => {
    return (
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <Icon name={transactionData?.crypto_type?.toLowerCase() as IconName} size={30} />
          <div className={styles.title}>
            <span className={styles.currencyType}>{currencyType} Transaction</span>
            <span className={cn(styles[transactionData?.status], styles.status)}>
              {transactionData?.status.toUpperCase()}
            </span>
          </div>
        </div>
        <Link to={`/customer-transactions`} className={styles.link}>
          <Button buttonText="To transaction list" buttonType="outline" />
        </Link>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <Page isStickyTitle title={!isLoading ? <Title /> : null}>
        {!isLoading ? (
          <>
            <div className={styles.info}>
              <div className={styles.block}>
                <TransactionPageInfo transaction={transactionData} />
              </div>

              {transactionData && transactionData?.accept_crypto_transaction ? (
                <div className={styles.block}>
                  <TransactionPageCryptoInfo
                    cryptoTransaction={transactionData?.accept_crypto_transaction}
                    currencyType={currencyType}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.tableContainer}>
              <span className={styles.tableTitle}>New transactions</span>
              <TableLayout
                header={
                  <Button
                    className={styles.resetColumnButton}
                    buttonText="Reset column order"
                    onClick={setDefaultColumnsOrder}
                  />
                }
              >
                {transactionData.new_crypto_transaction &&
                transactionData.new_crypto_transaction.length ? (
                  <CryptoInnerTransactionsTable
                    data={transactionData.new_crypto_transaction}
                    perPage={transactionData.new_crypto_transaction.length}
                    cryptoType={currencyType}
                    mainWebHookId={transactionData.id}
                    transactionData={transactionData}
                    showLoader={false}
                    onRowSelect={null}
                    modifyDataHandler={(callback) => fetchCryptoTransaction(params)}
                    cryptoRate={cryptoRate}
                    cryptoFullType={cryptoFullType}
                    permission={PermissionModerateReceivedCryptoPayments}
                    columns={columns}
                    onSaveColumnOrder={saveColumnOrder}
                  />
                ) : (
                  <div className={styles.emptyTransactions}>No new transactions</div>
                )}
              </TableLayout>
            </div>
          </>
        ) : (
          <div className={styles.preloader}>
            <div className={styles.preloader__inner}>
              <Spinner />
            </div>
          </div>
        )}
      </Page>
    </DashboardLayout>
  );
};

export default TransactionPage;
