import React from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import EditTemplatesPopup from 'pages/Templates/TemplatesPopups/EditTemplatesPopup';
import DeleteTemplatesPopup from 'pages/Templates/TemplatesPopups/DeleteTemplatesPopup';
import { Icon } from 'components/ui/Icon';

const cx = classNames.bind(styles);

const TemplateTable = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  // onRowSelect,
  PermissionDelete,
  PermissionEdit,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name } = item;
    return {
      name: (
        <div className={cx('rule__name')}>
          <span>{name}</span>
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {PermissionEdit ? (
              <EditTemplatesPopup
                // departmentsList={departmentsList}
                // permissionsList={permissionsList}
                dataItem={item}
                actionType={SALES_RULES_ACTION_TYPE.EDIT}
                triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
                updateComponent={modifyDataHandler}
              />
            ) : null}
          </div>
          <div>
            {PermissionDelete ? (
              <DeleteTemplatesPopup
                rule={item}
                triggerBtn={<Icon name="trash" size={20} color="var(--red)" />}
                updateComponent={modifyDataHandler}
              />
            ) : null}
          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      // onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row } = cellInfo;
        const odd = row.index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default TemplateTable;
