import { components, MenuProps } from 'react-select';
import styles from '../multi-select.module.scss';

export const MultiSelectMenu = (props: MenuProps) => {
  const { getValue } = props;

  return (
    <components.Menu {...props}>
      {props.children}
      <div className={styles.menuCounter}>
        <b>{getValue().length}</b> items selected
      </div>
    </components.Menu>
  );
};
