import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TABLE_CLIENTS_COLUMNS_NAMES = [
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Last Activity',
    accessor: 'last_activity',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Deposits',
    accessor: 'deposit',
  },
  {
    Header: 'Affiliate',
    accessor: 'affiliate_refferer',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Sales',
    accessor: 'sales',
  },
  {
    Header: 'Retention',
    accessor: 'retention',
  },
  {
    Header: 'Registration',
    accessor: 'registration',
  },
  {
    Header: 'Last note',
    accessor: 'last_note',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];
export const TABLE_TRADING_ACCOUNT_NAMES = [
  {
    Header: 'Account name',
    accessor: 'trading_account',
  },
  {
    Header: 'Trading Acc',
    accessor: 'login',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Leverage',
    accessor: 'leverage',
  },
  {
    Header: 'Trading Status',
    accessor: 'status',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const TABLE_TRADING_ACCOUNT_NAMES_WITH_WT = [
  {
    Header: 'Account Name',
    accessor: 'trading_account',
  },
  {
    Header: 'Trading Acc',
    accessor: 'login',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Demo Account',
    accessor: 'demo',
  },
  {
    Header: 'Leverage',
    accessor: 'leverage',
  },
  {
    Header: 'Trading Status',
    accessor: 'status',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const TABLE_LEADS_COLUMNS_NAMES = [
  {
    Header: 'Lead',
    accessor: 'lead',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Sales Agent',
    accessor: 'sales',
  },
  {
    Header: 'Registration',
    accessor: 'registration',
  },
  {
    Header: 'Last Note',
    accessor: 'last_note',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_SALES_RULES_COLUMNS_NAMES = [
  {
    Header: 'Rule',
    accessor: 'rule',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Priority',
    accessor: 'priority',
  },
  {
    Header: 'Language',
    accessor: 'language',
  },
  {
    Header: 'Affiliate',
    accessor: 'affiliate',
  },
  {
    Header: 'Operator',
    accessor: 'operator',
  },
  {
    Header: 'Ratio',
    accessor: 'ratio',
  },
  {
    Header: 'Source',
    accessor: 'source',
  },
  {
    Header: 'Active',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];

export const TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="management.offices.HeaderColumn1" defaultMessage="Offices" />,
    accessor: 'office',
  },
  {
    Header: <FormattedMessage id="management.offices.HeaderColumn2" defaultMessage="Country" />,
    accessor: 'country',
  },
  {
    Header: <FormattedMessage id="management.offices.HeaderColumn3" defaultMessage="Action" />,
    accessor: 'action',
  },
];

export const TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn1" defaultMessage="Desk" />,
    accessor: 'desk',
  },
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn2" defaultMessage="Offices" />,
    accessor: 'office',
  },
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn3" defaultMessage="Desk Type" />,
    accessor: 'deskType',
  },
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn5" defaultMessage="Language" />,
    accessor: 'language',
  },
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn4" defaultMessage="Actions" />,
    accessor: 'action',
  },
];
export const TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="management.teams.HeaderColumn1" defaultMessage="Team" />,
    accessor: 'team',
  },
  {
    Header: <FormattedMessage id="management.teams.HeaderColumn2" defaultMessage="Office" />,
    accessor: 'office',
  },
  {
    Header: <FormattedMessage id="management.teams.HeaderColumn3" defaultMessage="Desk" />,
    accessor: 'desk',
  },
  {
    Header: <FormattedMessage id="management.teams.HeaderColumn4" defaultMessage="Actions" />,
    accessor: 'action',
  },
];
export const TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="management.operators.HeaderColumn1" defaultMessage="Operator" />,
    accessor: 'operator',
  },
  {
    Header: <FormattedMessage id="management.operator.HeaderColumn2" defaultMessage="Country" />,
    accessor: 'country',
  },
  {
    Header: <FormattedMessage id="management.operator.HeaderColumn3" defaultMessage="Registered" />,
    accessor: 'created_at',
  },
  {
    Header: <FormattedMessage id="management.operator.HeaderColumn4" defaultMessage="Status" />,
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'action',
  },
];
export const TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES = [
  {
    Header: 'Affiliate',
    accessor: 'partner',
  },
  {
    Header: 'External ID',
    accessor: 'external_id',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Registered',
    accessor: 'created_at',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT = [
  {
    Header: 'Affiliate',
    accessor: 'partner',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Registered',
    accessor: 'created_at',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_CALLBACKS_COLUMNS_NAMES = [
  {
    Header: 'Callback ID',
    accessor: 'id',
  },
  {
    Header: 'Operator',
    accessor: 'operator',
  },
  {
    Header: 'Callback Time',
    accessor: 'time',
  },
  {
    Header: 'Create',
    accessor: 'created_at',
  },
  {
    Header: 'Modified',
    accessor: 'updated_at',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Reminders',
    accessor: 'reminders',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];
export const TABLE_ALL_CALLBACKS_COLUMNS_NAMES = [
  {
    Header: 'Operator',
    accessor: 'operator',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Callback Time',
    accessor: 'time',
  },
  {
    Header: 'Create',
    accessor: 'created_at',
  },
  {
    Header: 'Modified',
    accessor: 'updated_at',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Reminders',
    accessor: 'reminders',
  },
];

export const TABLE_CRYPTO_WALLETS_COLUMNS_NAMES = [
  {
    Header: 'Type',
    accessor: 'crypto_type',
  },
  {
    Header: 'Description',
    accessor: 'wallet',
  },
  {
    Header: 'Shared Wallet',
    accessor: 'shared_wallet',
  },
  {
    Header: 'User',
    accessor: 'user_profile',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];

export const TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES = [
  {
    Header: 'Type',
    accessor: 'crypto_type',
  },
  {
    Header: 'Description',
    accessor: 'wallet',
  },
  {
    Header: 'Shared Wallet',
    accessor: 'shared_wallet',
  },
];
export const TABLE_FEED_COLUMNS_NAMES = [
  // {
  //   Header: '№',
  //   accessor: 'number',
  // },

  {
    Header: 'Feed',
    accessor: 'created_at',
  },
  // {
  //   id: 'expanded',
  //   Cell: ({ row }) => (
  //     <span {...row.getToggleRowExpandedProps()} className="link">
  //       {row.isExpanded ? 'Hide details' : 'Show details'}
  //     </span>
  //   ),
  // },
];

export const TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES = [
  {
    Header: 'Type',
    accessor: 'crypto_type',
  },
  {
    Header: 'Wallet / Transaction Info',
    accessor: 'wallet',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'User',
    accessor: 'user_profile',
  },
  {
    Header: 'Created At',
    accessor: 'created',
  },

  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES = [
  {
    Header: 'Transaction ID',
    accessor: 'transaction_id',
  },
  {
    Header: 'Amount',
    accessor: 'crypto_transaction',
  },
  {
    Header: 'Inner Transaction',
    accessor: 'inner_transaction',
  },
  {
    Header: 'User',
    accessor: 'user',
  },
  {
    Header: 'Created At',
    accessor: 'created',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_KYC_DOCUMENTS_COLUMNS_NAMES = [
  {
    Header: 'Client',
    accessor: 'user_name',
  },
  {
    Header: 'File',
    accessor: 'file_name',
  },
  {
    Header: 'Date & Time',
    accessor: 'created_at',
  },
  {
    Header: 'Category',
    accessor: 'kyc_category',
  },
  {
    Header: 'Document Type',
    accessor: 'kyc_type',
  },
];

export const TABLE_NOTIFICATIONS_COLUMNS_NAMES = [
  {
    Header: 'Notification Type',
    accessor: 'type',
  },
  {
    Header: 'Notification Details',
    accessor: 'details',
  },
  {
    Header: 'Agent',
    accessor: 'agent',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Notification Date',
    accessor: 'created_at',
  },
  {
    Header: 'Priority',
    accessor: 'priority',
  },
];

export const TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES = [
  {
    Header: 'Notification Type',
    accessor: 'type',
  },
  {
    Header: 'Notification Details',
    accessor: 'details',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Date',
    accessor: 'created_at',
  },
  {
    Header: 'Priority',
    accessor: 'priority',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const TABLE_TEMPLATES_COLUMNS_NAMES = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Template',
    accessor: 'template',
  },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    Header: 'Languages',
    accessor: 'lang',
  },
  {
    Header: '',
    accessor: 'action',
  },
];

export const TABLE_WALLET_BINDINGS = [
  // {
  //   Header: 'User ID',
  //   accessor: 'id',
  // },
  {
    Header: 'User',
    accessor: 'user',
  },
  {
    Header: 'Bind',
    accessor: 'bind',
  },
  {
    Header: '',
    accessor: 'action',
  },
];
export const TABLE_ROLES_AND_PERMISSIONS = [
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Permission View',
    accessor: 'permission_view',
  },
  {
    Header: 'Permission Edit',
    accessor: 'permission_edit',
  },
  {
    Header: 'Preview',
    accessor: 'preview',
  },
];
export const TABLE_TRADING_ACC_COLUMNS_NAMES = [
  // {
  //   Header: 'MT4 Login',
  //   accessor: 'ta_login',
  // },
  {
    Header: 'Trading Acc',
    accessor: 'trading_account',
  },
  {
    Header: 'Login',
    accessor: 'trading_login',
  },
  {
    Header: 'Client',
    accessor: 'user',
  },
  // {
  //   Header: 'Source Name',
  //   accessor: 'name',
  // },
  {
    Header: 'Date',
    accessor: 'created_at',
  },
  {
    Header: 'Credit',
    accessor: 'credit',
  },
  {
    Header: 'Leverage',
    accessor: 'leverage',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
];

export const TABLE_PAYMENTS_COLUMNS = [
  {
    Header: 'Transactions',
    accessor: 'transactions',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Affiliate',
    accessor: 'affiliate',
  },
  {
    Header: 'Operator',
    accessor: 'original_agent',
  },
  {
    Header: 'Internal ID',
    accessor: 'internal_id',
  },
  {
    Header: 'Payment Type',
    accessor: 'payment_type',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Trading Acc',
    accessor: 'trading_acc',
    dependencies: ['MT'],
  },
  {
    Header: 'Payment Method',
    accessor: 'payment_method',
  },
  {
    Header: 'Gateway',
    accessor: 'gateway',
    dependencies: ['PAYMENTS'],
  },
  {
    Header: 'Date & Time',
    accessor: 'date',
  },
  {
    Header: 'Crypto Wallets',
    accessor: 'cripto_walets',
  },
  {
    Header: 'Credit Card',
    accessor: 'credit_card',
    dependencies: ['CCW'],
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'External Reference ID',
    accessor: 'reference_id',
  },
];

export const getPaymentsColumns = (withMT, withPayments, withCCWithdraw) => {
  return TABLE_PAYMENTS_COLUMNS.filter(
    (col) =>
      !(col.dependencies?.includes('MT') && !withMT) &&
      !(col.dependencies?.includes('PAYMENTS') && !withPayments) &&
      !(col.dependencies?.includes('CCW') && !withCCWithdraw),
  );
};

const TABLE_CLIENT_PAYMENTS_COLUMNS = [
  {
    Header: 'Transactions',
    accessor: 'transactions',
  },
  {
    Header: 'Operator',
    accessor: 'original_agent',
  },
  {
    Header: 'Internal ID',
    accessor: 'internal_id',
  },
  {
    Header: 'External Reference ID',
    accessor: 'reference_id',
    dependencies: ['MT'],
  },
  {
    Header: 'Payment Type',
    accessor: 'payment_type',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Trading Acc',
    accessor: 'trading_acc',
    dependencies: ['MT'],
  },
  {
    Header: 'Payment Method',
    accessor: 'payment_method',
  },
  {
    Header: 'Gateway',
    accessor: 'gateway',
    dependencies: ['PAYMENTS'],
  },
  {
    Header: 'Date & Time',
    accessor: 'date',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Crypto Wallets',
    accessor: 'cripto_walets',
  },
  {
    Header: 'Credit Card',
    accessor: 'credit_card',
    dependencies: ['CCW'],
  },
  {
    Header: '',
    accessor: 'action',
  },
];

export const getClientPaymentsColumns = (withMT, withPayments, withCCWithdraw) => {
  return TABLE_CLIENT_PAYMENTS_COLUMNS.filter(
    (col) =>
      !(col.dependencies?.includes('MT') && !withMT) &&
      !(col.dependencies?.includes('PAYMENTS') && !withPayments) &&
      !(col.dependencies?.includes('CCW') && !withCCWithdraw),
  );
};

export const TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES = [
  // {
  //   Header: 'ID',
  //   accessor: 'id',
  // },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Priority',
    accessor: 'priority',
  },
  {
    Header: 'Source',
    accessor: 'source',
  },
];
export const TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES = [
  {
    Header: 'Active',
    accessor: 'active',
  },
  {
    Header: 'Day',
    accessor: 'day',
  },
  {
    Header: 'Working Hours',
    accessor: 'working_hours',
  },
  {
    Header: 'Total Leads Limit',
    accessor: 'total_leads_limit',
  },
  {
    Header: 'Total Limits',
    accessor: 'total_limit',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
export const TABLE_TEMPLATE_ROLES = [
  {
    Header: 'Name Template',
    accessor: 'name',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];

export const TABLE_TRADING_ACTIVITY_COLUMNS_NAMES = [
  {
    Header: 'Trade',
    accessor: 'trade',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Trading Acc',
    accessor: 'trading_account',
  },
  // {
  //   Header: 'Original agent',
  //   accessor: 'original_agent',
  // },
  {
    Header: 'Transaction Price',
    accessor: 'open_price',
  },
  // {
  //   Header: 'Close price',
  //   accessor: 'close_price',
  // },
  {
    Header: 'Volume',
    accessor: 'volume',
  },
  // {
  //   Header: 'Profit',
  //   accessor: 'profit',
  // },
  // {
  //   Header: 'Swap',
  //   accessor: 'swap',
  // },
  {
    Header: 'Transaction Time',
    accessor: 'open_time',
  },
  // {
  //   Header: 'Close time',
  //   accessor: 'close_time',
  // },
];
export const TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES = [
  {
    Header: 'Wallet Address',
    accessor: 'wallet_address',
  },
  {
    Header: 'Type',
    accessor: 'crypto_type',
  },
  {
    Header: 'Amount USD',
    accessor: 'amount_usd',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
  },
  {
    Header: 'View Detail',
    accessor: 'action',
  },
];
export const TABLE_DYNAMIC_STATUS_TYPES = [
  {
    Header: 'Key',
    accessor: 'key',
  },
  {
    Header: 'Translate',
    accessor: 'translate',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Active',
    accessor: 'active',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
  },
  {
    Header: 'Color',
    accessor: 'change_color',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
export const TABLE_FILES_COLUMNS_NAMES = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Publish Date',
    accessor: 'created_at',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const TABLE_OPENED_POSITIONS_COLUMNS_NAMES = [
  {
    Header: 'Account',
    accessor: 'account',
  },
  {
    Header: 'Side',
    accessor: 'side',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
  },
  {
    Header: 'Open Price',
    accessor: 'open_price',
  },
  {
    Header: 'Current Price',
    accessor: 'current_price',
  },
  {
    Header: 'Volume',
    accessor: 'volume',
  },
  {
    Header: 'Profit',
    accessor: 'profit',
  },
  {
    Header: 'Open Time',
    accessor: 'open_time',
  },
];
export const TABLE_DEALS_COLUMNS_NAMES = [
  {
    Header: 'Account',
    accessor: 'account',
  },
  {
    Header: 'Side',
    accessor: 'side',
  },
  {
    Header: 'Deal',
    accessor: 'deal',
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
  },
  {
    Header: 'Open Price',
    accessor: 'open_price',
  },
  {
    Header: 'Close Price',
    accessor: 'close_price',
  },
  {
    Header: 'Volume',
    accessor: 'volume',
  },
  {
    Header: 'Profit',
    accessor: 'profit',
  },
  {
    Header: 'Close Time',
    accessor: 'close_time',
  },
];

export const tablesColumnData = {
  TABLE_CLIENTS_COLUMNS_NAMES: TABLE_CLIENTS_COLUMNS_NAMES,
  TABLE_TRADING_ACCOUNT_NAMES: TABLE_TRADING_ACCOUNT_NAMES,
  TABLE_TRADING_ACCOUNT_NAMES_WITH_WT: TABLE_TRADING_ACCOUNT_NAMES_WITH_WT,
  TABLE_LEADS_COLUMNS_NAMES: TABLE_LEADS_COLUMNS_NAMES,
  TABLE_SALES_RULES_COLUMNS_NAMES: TABLE_SALES_RULES_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES: TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES: TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES: TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES: TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES: TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT: TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT,
  TABLE_CALLBACKS_COLUMNS_NAMES: TABLE_CALLBACKS_COLUMNS_NAMES,
  TABLE_ALL_CALLBACKS_COLUMNS_NAMES: TABLE_ALL_CALLBACKS_COLUMNS_NAMES,
  TABLE_CRYPTO_WALLETS_COLUMNS_NAMES: TABLE_CRYPTO_WALLETS_COLUMNS_NAMES,
  TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES: TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES,
  TABLE_FEED_COLUMNS_NAMES: TABLE_FEED_COLUMNS_NAMES,
  TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES: TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES,
  TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES: TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES,
  TABLE_KYC_DOCUMENTS_COLUMNS_NAMES: TABLE_KYC_DOCUMENTS_COLUMNS_NAMES,
  TABLE_NOTIFICATIONS_COLUMNS_NAMES: TABLE_NOTIFICATIONS_COLUMNS_NAMES,
  TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES: TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES,
  TABLE_TEMPLATES_COLUMNS_NAMES: TABLE_TEMPLATES_COLUMNS_NAMES,
  TABLE_WALLET_BINDINGS: TABLE_WALLET_BINDINGS,
  TABLE_ROLES_AND_PERMISSIONS: TABLE_ROLES_AND_PERMISSIONS,
  TABLE_TRADING_ACC_COLUMNS_NAMES: TABLE_TRADING_ACC_COLUMNS_NAMES,
  TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES: TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES,
  TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES: TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES,
  TABLE_TEMPLATE_ROLES: TABLE_TEMPLATE_ROLES,
  TABLE_TRADING_ACTIVITY_COLUMNS_NAMES: TABLE_TRADING_ACTIVITY_COLUMNS_NAMES,
  TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES: TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES,
  TABLE_DYNAMIC_STATUS_TYPES: TABLE_DYNAMIC_STATUS_TYPES,
  TABLE_FILES_COLUMNS_NAMES: TABLE_FILES_COLUMNS_NAMES,
  TABLE_OPENED_POSITIONS_COLUMNS_NAMES: TABLE_OPENED_POSITIONS_COLUMNS_NAMES,
  TABLE_DEALS_COLUMNS_NAMES: TABLE_DEALS_COLUMNS_NAMES,
};

export const tablesColumnDataKeys = Object.keys(tablesColumnData);

const targetIndex = 3;
const regex = new RegExp(`th:nth-child\\(${targetIndex}\\)\\s*{[\\s\\S]*?}`, 'g');
