import { getCountryList } from 'api/country';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { activationPartnerMethod, getPartnerMethod } from 'api/partners/index';

import { notify } from 'utils/notify';
import { usePermission } from 'utils/usePermission';

import styles from './partner-page.module.scss';
import PartnerPersonalInfoForm from './PartnerForms/PartnerPersonalInfoForm';
import PartnerSchedules from './PartnerShedules';
import Feed from './PartnerTabs/Feed';
import SalesRules from './PartnerTabs/SalesRules';
import { ManagementPartner } from 'models/Management/Partners/Partner';
import { CountryListValue } from 'models/Countries';
import { ManagementPartnerSchedule } from 'models/Management/Partners/Schedule';
import { usePageTitle } from 'utils/usePageTitle';
import { PartnerPageHeader } from './PartnerPageHeader';
import { PartnerInfo } from './PartnerInfo/PartnerInfo';

const PartnerPage = () => {
  const { permissionGiven: PermissionShow } = usePermission('admin.partner.show', true);
  const { permissionGiven: PermissionShowSchedule } = usePermission('admin.partner-schedule.show');
  const { permissionGiven: PermissionViewPartnerLogs } = usePermission('admin.logs.partner');

  const params = useParams();

  const [nameItem, setItemName] = useState('');
  const [partnerData, setPartnerData] = useState<ManagementPartner>();
  const [isLoading, setIsLoading] = useState(true);
  const [countryListArr, setCountryListArr] = useState<CountryListValue[]>([]);
  const [scheduleList, setScheduleList] = useState<ManagementPartnerSchedule>();

  const title = 'Affiliate - ' + nameItem;
  const componentMounted = useRef(true);

  const changedStatus = (status) => {
    const data = {
      active: status === 'active' ? 1 : 0,
    };
    activationPartnerMethod(partnerData.id, data)
      .then((res) => {
        if (res.status === 200) {
          getPartner(params);
          notify({
            type: 'success',
            message: 'status changed',
            timeOut: 3000,
          });
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response.data.message,
          timeOut: 3000,
        });
      });
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getCountryList().then((res) => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryListArr(opt);
      }
    });
  }, [PermissionShow]);

  const getPartner = (params) => {
    getPartnerMethod(params.id)
      .then((res) => {
        if (res.status === 200) {
          setPartnerData(res.data.data);
          setScheduleList(res.data.data.schedules);
          setItemName(res.data.data.first_name);
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  usePageTitle(title);

  useEffect(() => {
    getPartner(params);
  }, [params]);

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page
          isStickyTitle
          title={
            <PartnerPageHeader
              id={params.id}
              partnerName={nameItem}
              partnerUuid={partnerData.uuid}
              updateCallback={() => getPartner(params)}
            />
          }
        >
          <div>
            <PartnerInfo partner={partnerData} onChangeStatus={changedStatus} />
            <div className={styles.tabs}>
              <Tabs>
                <TabList>
                  <Tab>Profile</Tab>
                  <Tab>Sales Rules</Tab>
                  {PermissionShowSchedule ? <Tab>Schedules</Tab> : null}
                  {PermissionViewPartnerLogs ? <Tab>Feed</Tab> : null}
                </TabList>
                <TabPanel>
                  <div className={styles.tabContainer}>
                    <PartnerPersonalInfoForm
                      formData={partnerData}
                      countryList={countryListArr}
                      updateComponent={() => getPartner(params)}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={styles.tabContainer}>
                    <SalesRules salesRulesList={partnerData?.sale_rules} />
                  </div>
                </TabPanel>
                {PermissionShowSchedule ? (
                  <TabPanel>
                    <div className={styles.tabContainer}>
                      <PartnerSchedules
                        countryOpt={countryListArr}
                        list={scheduleList}
                        modifyDataHandler={() => getPartner(params)}
                      />
                    </div>
                  </TabPanel>
                ) : null}
                {PermissionViewPartnerLogs ? (
                  <TabPanel>
                    <div className={styles.tabContainer}>
                      <Feed />
                    </div>
                  </TabPanel>
                ) : null}
              </Tabs>
            </div>
          </div>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default PartnerPage;
