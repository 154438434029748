import React from 'react';
import classNames from 'classnames/bind';
import styles from './partners-table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';

import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getShortUuid } from 'utils/getShortUuid';
import { useSelector } from 'react-redux';
import { getEnableMT } from 'constants/selectors';
import { upperFirst } from 'lodash';

const cx = classNames.bind(styles);

const PartnersTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  manualSortHandler,
  onSort,
  columns,
  saveColumnOrder,
}) => {
  const ENABLE_MT = useSelector(getEnableMT);

  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const checkSortEnable = (colId): boolean => {
    switch (colId) {
      case 'partner':
      case 'external_id':
      case 'country':
      case 'created_at':
      case 'status':
        return true;
      default:
        return false;
    }
  };
  const prepearedTableData = tableData.map((item, pos) => {
    const {
      id,
      first_name,
      uuid,
      country,
      status,
      created_at,
      status_updated_at,
      last_name,
      external_id,
    } = item;
    return {
      partner: (
        <div className={cx('rule__name')}>
          <Link target="_blank" to={`/partner/${id}`} className={cx('logo')}>
            <span>{first_name}</span>
          </Link>
          <span className={cx('rule__id')} onClick={() => copyToBuffer(uuid, true)}>
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      external_id:
        ENABLE_MT &&
        (external_id ? (
          <div className={cx('rule__country')}>
            <span>{external_id}</span>
          </div>
        ) : (
          <div className={cx('rule__name')}>
            <span>&mdash;</span>
          </div>
        )),
      country: country ? (
        <div className={cx('rule__country')}>
          {/*<b>{country?.length} Countries</b>*/}
          <div className={cx('client__country-flag')}>
            <i className={cx('icon')} style={{ backgroundImage: `url(${country.flag})` }} />
          </div>
          <span>
            {country.iso_3166_2} {country.name}
          </span>
        </div>
      ) : (
        <div className={cx('rule__name')}>
          <span>&mdash;</span>
        </div>
      ),
      created_at: (
        <div className={cx('rule__date')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status: (
        <div className={cx('rule__name')}>
          <span
            className={cx('status_text')}
            style={status === 'active' ? { color: 'var(--green)' } : { color: 'var(--red)' }}
          >
            {upperFirst(status)}
          </span>
          <span>
            <FormattedMessage id="management.operators.HeaderColumn6" defaultMessage="since " />
            {moment.unix(status_updated_at).format('DD.MM.YYYY')}
          </span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
      <Table
        saveColumnOrder={saveColumnOrder}
        manualSortHandler={manualSortHandler}
        colSortCheck={checkSortEnable}
        columns={columns}
        data={tableDataMeMo}
        perPage={perPage}
        showLoader={showLoader}
        onRowSelect={onRowSelect}
        className={cx('rules-table')}
        isSelected={false}
        getCellProps={(cellInfo) => {
          const { index } = cellInfo.row;
          const odd = index % 2 !== 0;
          return {
            style: {
              backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
            },
          };
        }}
      />
    </>
  );
};

export default PartnersTable;
