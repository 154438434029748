import { useEffect, useRef, useState } from 'react';
import { Client as ClientType } from 'models/Clients/Clients';
import moment from 'moment';
import { ChatButton } from 'pages/Chat/components/ChatButton/ChatButton';
import Button from 'components/ui/Button';
import { Icon } from 'components/ui/Icon';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import cn from 'classnames';
import styles from './client-page.module.scss';
import { isChatEnabled } from 'pages/Chat/helpers/isChatEnabled';
import { usePermission } from 'utils/usePermission';
import { deleteClient, getClientAutologinLink, resetPasswordForClient } from 'api/clients';
import ChangeAcquisitionStatus from './ClientPopups/ChangeAcquisitionStatus';
import ClientChangePasswordPopup from './ClientPopups/ClientChangePasswordPopup';
import PopupYesOrNo from 'components/ui/PopupYesOrNo/PopupYesOrNo';
import Notes from 'components/ui/Notes';
import { notify } from 'utils/notify';
import { useNavigate } from 'react-router-dom';
import { ReloadButton } from 'components/ui/ReloadButton';
import { Spinner } from 'components/ui/Spinner';

type Props = {
  client: ClientType | undefined;
  isListLoading: boolean;
  isShowNotes: boolean;
  notesCreatePermission: boolean;
  onToggleNotes: (v?: boolean) => void;
  updateCallback: () => void;
};

export const ClientPageHeader = ({
  client,
  isListLoading,
  isShowNotes,
  notesCreatePermission,
  onToggleNotes,
  updateCallback,
}: Props) => {
  const chatEnabled = isChatEnabled();

  const navigate = useNavigate();

  const { permissionGiven: PermissionAutologin } = usePermission('admin.client.get-autologin');
  const { permissionGiven: PermissionAssociateClientToOperator } =
    usePermission('admin.client.team');
  const { permissionGiven: PermissionDelete } = usePermission('admin.client.delete');
  const { permissionGiven: PermissionChangePassword } = usePermission(
    'admin.client.change-password',
  );

  const passwordMenu = useRef(null);

  const [autologinURL, setAutologinURL] = useState('');
  const [passMenu, setPassMenu] = useState(false);
  const [isLoadingResetPass, setIsLoadingResetPass] = useState(false);

  const getAutologinLink = async () => {
    const response = await getClientAutologinLink(client.id);
    if (response.status === 200) {
      setAutologinURL(response.data.url);
    }
  };

  useEffect(() => {
    const onClickOutside = (event) => {
      if (passwordMenu.current && !passwordMenu.current.contains(event.target)) {
        setPassMenu(false);
      }
    };

    document.addEventListener('click', onClickOutside, true);

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  useEffect(() => {
    if (client && client?.id !== undefined && PermissionAutologin) {
      getAutologinLink();
    }
  }, [client, PermissionAutologin]);

  const onClickDelete = () => {
    deleteClient(client.id)
      .then(() => {
        navigate('/search-clients');
      })
      .catch((error) => {
        console.log(error);
        notify({
          type: 'error',
          message: 'Сlient not deleted',
          timeOut: 3000,
        });
      });
  };

  const onResetPassword = () => {
    setIsLoadingResetPass(true);
    resetPasswordForClient(client.id)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          setPassMenu(false);
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response.data.message,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoadingResetPass(false);
      });
  };

  return (
    <div className={styles.pageHeader}>
      <div className={styles.title}>
        <div className={styles.name}>
          {client?.user_profile?.first_name} {client?.user_profile?.last_name}{' '}
          {client?.user_profile?.birthday !== null
            ? `(${Math.floor(
                moment(new Date()).diff(
                  moment(client?.user_profile?.birthday * 1000).format('MM/DD/YYYY'),
                  'years',
                  true,
                ),
              )})`
            : '(?)'}
        </div>
        <div className={styles.uuid} onClick={() => copyToBuffer(client?.uuid, true)}>
          {getShortUuid(client?.uuid)}
        </div>
      </div>
      <div className={styles.buttons}>
        {chatEnabled ? <ChatButton id={client?.id} /> : null}
        {autologinURL && PermissionAutologin && (
          <Button buttonText="Autologin" onClick={() => window.open(autologinURL)} />
        )}
        {client?.hasOwnProperty('sales') && PermissionAssociateClientToOperator ? (
          <ChangeAcquisitionStatus
            actionType="sales"
            updateComponent={updateCallback}
            userId={String(client?.id)}
            triggerBtn={<Button buttonType="outline" buttonText="Change acquisition status" />}
          />
        ) : null}

        {client?.hasOwnProperty('retention') && PermissionAssociateClientToOperator ? (
          <ChangeAcquisitionStatus
            actionType="retention"
            updateComponent={updateCallback}
            userId={String(client?.id)}
            triggerBtn={<Button buttonType="outline" buttonText="Change acquisition status" />}
          />
        ) : null}
        {notesCreatePermission ? (
          <Button buttonType="outline" buttonText="Add note" onClick={onToggleNotes} />
        ) : null}
        <ReloadButton isLoading={isListLoading} onClick={updateCallback} />
        <div className={styles.customDropdown}>
          {PermissionChangePassword ? (
            <Button
              ref={passwordMenu}
              className={styles.circleButton}
              buttonType="outline"
              isLoading={isLoadingResetPass}
              icon={<Icon name="resetPassword" size={18} />}
              onClick={() => setPassMenu((p) => !p)}
            />
          ) : null}
          <div
            className={cn(styles.customDropdown__list, passMenu && styles.open)}
            ref={passwordMenu}
          >
            <ul className={styles.passMenu}>
              <ClientChangePasswordPopup
                updateComponent={updateCallback}
                userId={String(client?.id)}
                triggerBtn={<li className={styles.passMenu__item}>Change password</li>}
              />

              <li
                onClick={onResetPassword}
                className={cn(styles.passMenu__item, styles.withLoader)}
              >
                Reset password
                {isLoadingResetPass ? <Spinner size="small" /> : null}
              </li>
            </ul>
          </div>
        </div>
        {isShowNotes && (
          <Notes
            userId={client.id}
            handleClose={onToggleNotes}
            type="client"
            updateComponent={updateCallback}
          />
        )}
        {PermissionDelete && (
          <PopupYesOrNo
            onClick={onClickDelete}
            headerTitle={'Delete client'}
            bodyText={'Are you sure you want to delete this client?'}
            triggerBtn={
              <Button
                buttonType="delete"
                className={styles.circleButton}
                icon={<Icon name="trash" size={18} />}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
