import { PushPin, PushPinSlash } from "phosphor-react";
import { useAppDispatch } from "../../../../store";
import { MenuOption } from "./MenuOption";
import { pinThread } from "../../../../store/slice/chat/thunks";

type PinThreadOptionProps = {
    threadId: string;
    isPinned: boolean;
};

export const PinThreadOption = ({ threadId, isPinned }: PinThreadOptionProps) => {
    const dispatch = useAppDispatch();
    const Icon = isPinned ? PushPinSlash : PushPin;

    const handlePinContact = () => {
        dispatch(pinThread({ threadId, pin: !isPinned }));
    };

    return (
        <MenuOption title={`${isPinned ? 'Unpin' : 'Pin'} contact`} onClick={handlePinContact}>
            <Icon size={32} color="var(--main-color)"/>
        </MenuOption>
    )
}