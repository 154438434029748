import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchUserInfo } from '../../api/auth';
import { APIGetMTResponse } from 'models/API/Auth/MT';

type UserInfo = {
  userInfo: {
    id: number | null;
    uuid: string | null;
    email: string | null;
    phone: string | null;
    sip_account: string | null;
    first_name: string | null;
    last_name: string | null;
    activated: number | null;
    status: string | null;
    status_updated_at: number | null;
    email_verified_at: number | null;
    created_at: number | null;
    country: string | null;
    role: string[];
  } | null;
  progressBar: {
    progress: number;
    total: number;
    status: null | string;
  };
};

type UserInfoSettings = {
  ENABLE_MT: null | boolean;
  IS_SUPER_ADMIN: null | boolean;
  ENABLE_LK: null | boolean;
  ENABLE_PAYMENT: null | boolean;
  ENABLE_CREDIT_CARD_WITHDRAW: null | boolean;
  ENABLE_WEB_TRADER: null | boolean;
  ALLOW_DEMO_ACCOUNTS: boolean;
  VOIP_PREFIXES: null | boolean;
  VOIP_STATUS: null | boolean;
};

const userInfoSettings: UserInfoSettings = {
  ENABLE_MT: null,
  IS_SUPER_ADMIN: null,
  ENABLE_LK: null,
  ENABLE_PAYMENT: null,
  ENABLE_WEB_TRADER: null,
  ENABLE_CREDIT_CARD_WITHDRAW: null,
  ALLOW_DEMO_ACCOUNTS: false,
  VOIP_PREFIXES: null,
  VOIP_STATUS: null,
};

const initialState: UserInfo & UserInfoSettings = {
  userInfo: null,
  ...userInfoSettings,
  progressBar: {
    progress: 0,
    total: 0,
    status: null,
  },
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    getUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    cleanUserInfo(state) {
      state.userInfo = null;
    },
    setProgress(state, action) {
      state.progressBar.progress = action.payload;
    },
    setStatus(state, action) {
      state.progressBar.status = action.payload;
    },
    setTotal(state, action) {
      state.progressBar.total = action.payload;
    },
    setInstanceSettings: (state, { payload }: PayloadAction<APIGetMTResponse>) => {
      Object.keys(userInfoSettings).forEach((key) => {
        state[key] = Boolean(Number(payload[key]));
      });
    },
  },
});
export const getUserInfoMethod = () => async (dispatch) => {
  try {
    const res = await fetchUserInfo();
    dispatch(getUserInfo(res.data.data));
    localStorage.setItem('userInfo', JSON.stringify(res.data.data));
    localStorage.setItem('role', JSON.stringify(res.data.data.role));
    localStorage.setItem('user_id', JSON.stringify(res.data.data.id));
    return res;
  } catch (err) {
    console.log(err);
  } finally {
  }
};
export const cleanInfoMethod = () => async (dispatch) => {
  try {
    dispatch(cleanUserInfo());
    localStorage.removeItem('userInfo');
  } catch (err) {
  } finally {
  }
};
export const { getUserInfo, cleanUserInfo, setProgress, setStatus, setTotal, setInstanceSettings } =
  userInfoSlice.actions;

export default userInfoSlice.reducer;
