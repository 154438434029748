import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import Button from 'components/ui/Button/Button';
import styles from './filter.module.scss';
import { getClientFeedDefaultValues } from '../Feed';

const FeedFilter = ({ onSubmit, reloadFilters, queryMessage }) => {
  const componentMounted = useRef(true);

  const { handleSubmit, reset, getValues, control, formState } = useFormContext();
  const { dirtyFields } = formState;

  const submit = (data) => {
    const postData = {
      action_type: getValue(data['action_type']) && getValue(data['action_type']),
      from: new Date(data.created_at?.from).getTime() / 1000,
      to: new Date(data.created_at?.to).getTime() / 1000,
      query: data?.query,
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getClientFeedDefaultValues());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={styles.form}
    >
      <SearchBar
        className={styles.searchBar}
        queryMessage={queryMessage}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: 'Search by Operator UUID',
          onSearch: () => submit(getValues()),
          onChange: (v) => !v?.length && submit(getValues()),
        }}
      />
      <div className={styles.buttons}>
        <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
        <Button
          buttonText="Reset"
          buttonType="outline"
          isLoading={reloadFilters}
          type="reset"
          onClick={resetForm}
          disabled={!getDirtyFieldsLength()}
        />
        <Button
          buttonText="Apply"
          isLoading={reloadFilters}
          type="submit"
          onClick={handleSubmit(submit)}
          disabled={!getDirtyFieldsLength()}
        />
      </div>
    </form>
  );
};

export default FeedFilter;
