import { usePermission } from 'utils/usePermission';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { CountryListValue } from 'models/Countries';
import Button from 'components/ui/Button';
import CreateOfficesPopup from './OfficesPopups/CreateOfficesPopup';
import styles from './offices-page.module.scss';

type Props = {
  total?: number;
  countries: CountryListValue[];
  updateComponentCallback: () => void;
};

export const OfficesPageTitle = ({ total, countries, updateComponentCallback }: Props) => {
  const { permissionGiven: PermissionCreate } = usePermission('admin.office.create');

  return (
    <div className={styles.pageTitle}>
      <div>{total || 0} Offices</div>
      {PermissionCreate ? (
        <CreateOfficesPopup
          updateComponent={updateComponentCallback}
          countryList={countries}
          actionType={SALES_RULES_ACTION_TYPE.CREATE}
          triggerBtn={
            <Button buttonText={'+ Add office'} onClick={() => null} buttonType={'outline'} />
          }
        />
      ) : null}
    </div>
  );
};
