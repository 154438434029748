import { Icon } from 'components/ui/Icon';
import { SimpleSelect, simpleSelectStyles } from 'components/ui/SimpleSelect';
import { Client } from 'models/Clients/Clients';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import CheckRole from 'utils/RoleComponent';
import { usePermission } from 'utils/usePermission';
import styles from './client-personal-info.module.scss';
import { ListValue } from 'models/ListValue';
import { getPrefixes, initCall } from 'api/voip/';
import { notify } from 'utils/notify';
import { getCurrentCallPhone, getCurrentAltCallPhone } from 'helpers/clientPage';
import { SimpleInput } from 'components/ui/SimpleInput';
import cn from 'classnames';

type Props = {
  client: Client;
};

const initShowState = { phone: false, altPhone: false, email: false, altEmail: false };

export const ClientPersonalInfo = ({ client }: Props) => {
  const { permissionGiven: PermissionPersonalInfo } = usePermission(
    'admin.client.view-personal-info',
  );
  const { permissionGiven: PermissionViewPhone } = usePermission('admin.client.view-phone');
  const { permissionGiven: PermissionViewEmail } = usePermission('admin.client.view-email');

  const [show, setShow] = useState(initShowState);
  const [prefixes, setPrefixes] = useState<ListValue[]>([]);
  const [prefix, setPrefix] = useState('');
  const [missClickCall, setMissClickCall] = useState(false);

  const VOIP_PREFIXES = useAppSelector((state) => state.userInfo.VOIP_PREFIXES);
  const VOIP_STATUS = useAppSelector((state) => state.userInfo.VOIP_STATUS);
  const sip_account = useAppSelector((state) => state.userInfo?.userInfo?.sip_account);

  useEffect(() => {
    getPrefixes()
      .then((data) => {
        const result = data.data.map((item) => {
          return {
            value: item.prefix.toString(),
            label: `${item.prefix.toString()} ${item.country}`,
          };
        });
        setPrefixes(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!PermissionPersonalInfo) {
    return null;
  }

  const call = (alt) => {
    const callPhone = alt ? getCurrentAltCallPhone(client) : getCurrentCallPhone(client);
    if (sip_account) {
      notify({
        type: 'info',
        message: 'Wait for connection...',
        timeOut: 2000,
      });

      initCall(prefix, callPhone)
        .then((res) => console.log('Connect...'))
        .catch((error) => {
          notify({
            type: 'error',
            message: error || 'Connection error',
            timeOut: 3000,
          });
        });
    } else {
      notify({
        type: 'info',
        message: 'You cannot make calls. Add a SIP account in the operator settings',
        timeOut: 7000,
      });
    }
  };

  const ClickToCall = (alt) => {
    if (!missClickCall) {
      call(alt);
      setMissClickCall(true);
      setTimeout(() => setMissClickCall(false), 3000);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <span>Date of birth:</span>
        <span>
          {client?.user_profile?.birthday
            ? moment(client?.user_profile?.birthday * 1000).format('DD.MM.YYYY')
            : '---'}
        </span>
      </div>
      <div className={styles.row}>
        <span>Gender:</span>
        <span>{client?.user_profile?.gender ? client?.user_profile?.gender : '---'}</span>
      </div>
      {VOIP_STATUS && (
        <div className={styles.row}>
          <div>Prefix:</div>
          <div className="ms-auto">
            {VOIP_PREFIXES ? (
              <SimpleSelect
                options={prefixes}
                defaultValue={prefixes[0]}
                styles={{
                  control: (s, p) => ({ ...simpleSelectStyles({}).control(s, p), minHeight: 27 }),
                }}
                onChange={(option) => {
                  setPrefix(option.value.toString());
                }}
              />
            ) : (
              <SimpleInput
                fieldClassName={styles.prefixInput}
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              />
            )}
          </div>
        </div>
      )}
      <div className={styles.row}>
        <span>Phone:</span>
        <span>
          {PermissionViewPhone
            ? show.phone
              ? client && client.phone
                ? client.phone
                : '---'
              : '***********'
            : null}
        </span>
        {PermissionViewPhone && client?.phone && VOIP_STATUS ? (
          <span className={styles.call} onClick={() => ClickToCall(false)}>
            <Icon name="call" size={16} />
          </span>
        ) : null}
        <CheckRole permissionType="admin.client.view-phone" roleType={['Super Admin']}>
          <Icon
            className={cn(
              styles.icon,
              PermissionViewPhone && client?.phone && VOIP_STATUS && styles.phoneIcon,
            )}
            name={!show.phone ? 'eye' : 'eyeClose'}
            size={17}
            onClick={() => setShow((p) => ({ ...p, phone: !p.phone }))}
          />
        </CheckRole>
      </div>

      <div className={styles.row}>
        <span>Phone (alt):</span>
        <span>
          {PermissionViewPhone
            ? show.altPhone
              ? client && client.user_profile.alt_phone
                ? client.user_profile.alt_phone
                : '---'
              : '***********'
            : null}
        </span>
        {PermissionViewPhone && client?.user_profile?.alt_phone && VOIP_STATUS ? (
          <span className={styles.call} onClick={() => ClickToCall(true)}>
            <Icon name="call" size={16} />
          </span>
        ) : null}
        <CheckRole permissionType="admin.client.view-phone" roleType={['Super Admin']}>
          <Icon
            className={cn(
              styles.icon,
              PermissionViewPhone &&
                client?.user_profile?.alt_phone &&
                VOIP_STATUS &&
                styles.phoneIcon,
            )}
            name={!show.altPhone ? 'eye' : 'eyeClose'}
            size={17}
            onClick={() => setShow((p) => ({ ...p, altPhone: !p.altPhone }))}
          />
        </CheckRole>
      </div>

      <div className={styles.row}>
        <span>E-mail:</span>
        <span>
          {PermissionViewEmail
            ? show.email
              ? client && client.email
                ? client.email
                : '---'
              : '***********'
            : null}
        </span>
        <CheckRole permissionType="admin.client.view-email" roleType={undefined}>
          <Icon
            className={styles.icon}
            name={!show.email ? 'eye' : 'eyeClose'}
            size={17}
            onClick={() => setShow((p) => ({ ...p, email: !p.email }))}
          />
        </CheckRole>
      </div>

      <div className={styles.row}>
        <span>E-mail (alt):</span>
        <span>
          {PermissionViewEmail
            ? show.altEmail
              ? client && client.user_profile.alt_email
                ? client.user_profile.alt_email
                : '---'
              : '***********'
            : null}
        </span>
        <CheckRole permissionType="admin.client.view-email" roleType={undefined}>
          <Icon
            className={styles.icon}
            name={!show.altEmail ? 'eye' : 'eyeClose'}
            size={17}
            onClick={() => setShow((p) => ({ ...p, altEmail: !p.altEmail }))}
          />
        </CheckRole>
      </div>
      <div className={styles.row}>
        <span>Country:</span>
        <span>{client?.country?.name ? client?.country?.name : '---'}</span>
      </div>
      <div className={styles.row}>
        <span>Language:</span>
        <span>{client?.language?.name ? client?.language?.name : '---'}</span>
      </div>
      <div className={styles.row}>
        <span>Additional info:</span>
        <span>
          {client?.user_profile?.additional_info ? client?.user_profile?.additional_info : '---'}
        </span>
      </div>
    </div>
  );
};
