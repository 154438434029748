import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import { WALLET_ACTION_TYPE } from 'constants/payments.const';
import { usePermission } from 'utils/usePermission';

import styles from './crypto-payments-page.module.scss';
import CryptoPaymentsFilters from './CryptoPaymentsFilters/CryptoPaymentsFilters';
import AddCryptoWalletPopup from './CryptoPaymentsPopups/AddCryptoWalletPopup';
import Button from 'components/ui/Button';
import CryptoWalletTable from 'components/ui/Table/CryptoWalletTable';
import TablePagination from 'components/ui/TablePagination';
import { getCryptoWalletsAll } from 'api/customer-service/crypto-payments';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import { ShortCryptoWallet } from 'models/CustomerService/CryptoWallets';
import { CryptoWalletsFilter } from 'models/CustomerService/CryptoWalletsFilter';
import { TableLinks, TableMeta } from 'models/Table';
import { useAppDispatch } from 'store';
import { toggleScrollToTop } from 'store/slice/tableSlice.ts';
import { useCheckAutoRefresh } from 'utils/useCheckAutoRefresh.tsx';
import Page from 'pages/Page';
import { usePageTitle } from 'utils/usePageTitle';
import { useCryptoPaymentsPage } from './useCryptoPaymentsPage';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { CryptoPaymentsFields } from './CryptoPaymentsFields/CryptoPaymentsFields';

const cx = classNames.bind(styles);

const pageTitle = 'Crypto Wallets';

const CryptoPaymentsPage = () => {
  const { permissionGiven: PermissionViewCryptoWallets } = usePermission(
    'admin.crypto_wallets.index',
  );
  const { permissionGiven: PermissionCreateNewCryptoWallet } = usePermission(
    'admin.crypto_wallets.create',
  );

  const dispatch = useAppDispatch();

  const [walletList, setWalletList] = useState<ShortCryptoWallet[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState<CryptoWalletsFilter>();
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(15);

  const checkAutoRefresh = useCheckAutoRefresh('Page Crypto Wallets');

  const componentMounted = useRef(false);

  useEffect(() => {
    if (PermissionViewCryptoWallets) {
      componentMounted.current = true;
      getWallets();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionViewCryptoWallets]);

  usePageTitle(pageTitle);

  const getWallets = (options?, filter?) => {
    setIsLoading(true);

    getCryptoWalletsAll(getTablePageParams(options, filter))
      .then((res) => {
        if (componentMounted.current) {
          setWalletList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  const getTablePageParams = (pageParams, filter) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    const perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    const params = `${toPage}&${perPageParams}`;

    if (filter) {
      let withFilters = `?${params}`;
      if (filter['type']) {
        withFilters = `${withFilters}&type=${filter['type']}`;
      }
      if (filter['available'] !== null) {
        withFilters = `${withFilters}&available=${filter['available']}&time_from=${filter['time_from']}&time_to=${filter['time_to']}`;
      }
      if (filter['query'] !== '') {
        withFilters = `${withFilters}&query=${filter['query']}`;
      }
      if (filter['searchCategory'] && filter['searchCategory'] !== '') {
        withFilters = `${withFilters}&searchCategory=${filter['searchCategory']}`;
      }
      return withFilters;
    }

    return `?${params}`;
  };

  const { form, table } = useCryptoPaymentsPage();

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <div className={styles.pageTitle}>
            <div>{tableMeta?.total || 0} Crypto Wallets</div>
            {PermissionCreateNewCryptoWallet ? (
              <AddCryptoWalletPopup
                updateComponent={() => getWallets()}
                actionType={WALLET_ACTION_TYPE.CREATE}
                triggerBtn={
                  <Button buttonText="+ Add Wallet" onClick={() => null} buttonType={'outline'} />
                }
              />
            ) : null}
          </div>
        }
      >
        <>
          <FormProvider {...form}>
            <CryptoPaymentsFields />
            {PermissionViewCryptoWallets ? (
              <TableLayout
                header={
                  <CryptoPaymentsFilters
                    onSubmit={(data) => {
                      if (!filters && !data) {
                        return;
                      }

                      if (filters && !data) {
                        getWallets({ navigate: 'first' });
                        setFilters(null);
                      } else {
                        setFilters(data);
                        getWallets({ navigate: 'first' }, data);
                      }
                    }}
                    reloadFilters={isLoading}
                    queryMessage={queryResponseMessage}
                    setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                  />
                }
              >
                <CryptoWalletTable
                  data={walletList}
                  perPage={perPageCount}
                  showLoader={isLoading}
                  columns={table.columns}
                  onSaveColumnOrder={table.saveColumnOrder}
                  clientsList={[]}
                  modifyDataHandler={(callback) => getWallets()}
                />
              </TableLayout>
            ) : null}
          </FormProvider>

          {walletList?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getWallets({ perPage: value }, filters);
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getWallets({ page }, filters);
                dispatch(toggleScrollToTop());
              }}
              hidePageSelect={false}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default CryptoPaymentsPage;
