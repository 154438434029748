import { useForm } from 'react-hook-form';
import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { TABLE_CRYPTO_WALLETS_COLUMNS_NAMES } from 'constants/table';
import { CryptoWalletsFilter } from 'models/CustomerService/CryptoWalletsFilter';
import useTableColumnOrder from 'utils/useTableColumnOrder';

const defaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  type: null,
  available: null,
  time_from: undefined,
  time_to: undefined,
};

export const useCryptoPaymentsPage = () => {
  const table = useTableColumnOrder(
    'TABLE_CRYPTO_WALLETS_COLUMNS_NAMES',
    TABLE_CRYPTO_WALLETS_COLUMNS_NAMES,
  );

  const form = useForm<CryptoWalletsFilter>({
    reValidateMode: 'onChange',
    defaultValues,
  });

  return { form, table };
};
