import Button from "../../../../components/ui/Button";
import { FileUploader } from "react-drag-drop-files";
import { chatFileTypes } from "../../constants";
import { Paperclip } from "phosphor-react";
import { notify } from "../../../../utils/notify";

export const ChatUploadInput = ({ onChangeHandler }) => {
    const handleError = (message) => {
        notify({
            type: 'error',
            message: message,
            timeOut: 3000,
        })
    };

    return (
        <FileUploader
            name="chat_upload"
            types={chatFileTypes}
            maxSize={16}
            handleChange={onChangeHandler}
            onTypeError={() => handleError('Wrong file type!')}
            onSizeError={() => handleError('The file is too large. Allowed file size is 16Mb.')}
        >
            <Button
                className='attach-file-button'
                buttonType='outline'
                icon={<Paperclip size={24} color="var(--main-color)"/>}
            />
        </FileUploader>
    )
};