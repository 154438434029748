import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import {
  changePermissionsMethod,
  getDepartmentRolesMethod,
  getDepartmentsListMethod,
  getPermissionsTempleRole,
} from 'api/rolesAndPermissions/index';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../Page';
import { DashboardLayout } from '../UserView/DashboardLayout';
import { Department2 } from 'models/RolesAndPermissions/Department.js';
import {
  PermissionList as PermissionListType,
  RAP_ActiveRole as ActiveRole,
} from 'models/RolesAndPermissions/Permissions.ts';
import { usePageTitle } from 'utils/usePageTitle';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { DepartmentRole } from './components/DepartmentRole';
import { parseTemplate } from './utils';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { NoPermissionText } from './components/NoPermissionText';
import styles from './rolesPermissions-page.module.scss';

const title = 'Roles and Permissions';

const RolesPermissionsPage = () => {
  const { permissionGiven: PermissionList } = usePermission('admin.permission.list');
  const { permissionGiven: PermissionShow } = usePermission('admin.role.show');

  const [departmentsTabs, setDepartmentsTabs] = useState<Department2[]>([]);
  const [departmentsRoles, setDepartmentsRoles] = useState<ActiveRole[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeRoleId, setActiveRoleId] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<PermissionListType>();

  usePageTitle(title);

  useEffect(() => {
    getDepartmentsListMethod().then((res) => {
      if (res.status === 200) {
        const departments = res.data.data;
        setDepartmentsTabs(departments);

        if (!activeRoleId) {
          const [{ id: initialRoleId }] = departments;

          getDepartmentRolesMethod(initialRoleId).then((res) => {
            setDepartmentsRoles(res.data.data.roles);
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    const activeDepartment = departmentsTabs[activeTab];

    if (activeDepartment && PermissionShow) {
      getDepartmentRolesMethod(activeDepartment.id).then((res) => {
        setDepartmentsRoles(res.data.data.roles);
        setActiveRoleId(activeRoleId);
      });
    }
  }, [activeTab, PermissionShow]);

  const getPermissions = () => {
    if (activeRoleId) {
      getPermissionsTempleRole(activeRoleId).then((res) => {
        const permissions = parseTemplate(res.data.data);

        setPermissions(permissions);
      });
    }
  };

  const onUpdatePermission = (action: string, show: boolean, id: number) => {
    const formData = {
      id_role: Number(activeRoleId),
      selected_permission: id,
      status: show ? 1 : 0,
    };
    changePermissionsMethod(activeRoleId, formData);
    setPermissions((prev) => ({
      ...prev,
      [action]: prev[action].map((item) => (item.id === id ? { ...item, show } : item)),
    }));
  };

  const onToggleRole = (key: AccordionEventKey) => {
    if (PermissionList) {
      setActiveRoleId(key as string);
    } else {
      setActiveRoleId(null);
    }
  };

  useEffect(() => {
    getPermissions();
  }, [activeRoleId]);

  return (
    <DashboardLayout>
      <Page title={'Roles and Permissions'}>
        <Tabs selectedIndex={activeTab} onSelect={setActiveTab}>
          <TabList>
            {departmentsTabs.map((tab) => (
              <Tab key={tab.id}>{tab.name}</Tab>
            ))}
          </TabList>
          <div className={styles.tabContainer}>
            {PermissionShow ? (
              departmentsTabs.map((department) => (
                <TabPanel key={department.id}>
                  <Accordion defaultActiveKey="0" onSelect={onToggleRole}>
                    {departmentsRoles.map((role) => (
                      <DepartmentRole
                        role={role}
                        activeRoleId={activeRoleId}
                        permissions={permissions}
                        PermissionList={PermissionList}
                        onUpdatePermission={onUpdatePermission}
                        onResetToDefaultPermissions={getPermissions}
                      />
                    ))}
                  </Accordion>
                </TabPanel>
              ))
            ) : (
              <NoPermissionText />
            )}
          </div>
        </Tabs>
      </Page>
    </DashboardLayout>
  );
};

export default RolesPermissionsPage;
