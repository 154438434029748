import { SimpleSelect } from 'components/ui/SimpleSelect';
import { SHOW_ITEMS } from 'constants/pagination';

import styles from './table-pagination-select.module.scss';

type Props = {
  isRareCase: boolean;
  perPage?: number;
  onChange: (perPage: number) => void;
};

export const TablePaginationSelect = ({ isRareCase, perPage, onChange }: Props) => {
  const items = isRareCase ? SHOW_ITEMS.RARE_CASE : SHOW_ITEMS.ALL;

  const options = items.map((v: number) => ({ label: String(v), value: String(v) }));

  return (
    <SimpleSelect
      label="Show"
      menuPlacement="top"
      wrapperClassName={styles.wrapper}
      options={options}
      defaultValue={String(perPage)}
      onChange={(option) => onChange(Number(option.value))}
    />
  );
};
