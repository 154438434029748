import moment from 'moment';
import cn from 'classnames';

import styles from './operator-account-status.module.scss';
import { ManagementOperator } from 'models/Management/Operators/Operator';
import { Icon } from 'components/ui/Icon';
import { useEffect, useState } from 'react';
import { usePermission } from 'utils/usePermission';
import { getDictionaryMethod } from 'api/managements/operators';

type Props = {
  operator: ManagementOperator;
  onChangeStatus: (status: string) => void;
};

export const OperatorAccountStatus = ({ operator, onChangeStatus }: Props) => {
  const { permissionGiven: PermissionActivate } = usePermission('admin.admin-user.activation');
  const { permissionGiven: PermissionShowOperator } = usePermission('admin.admin-user.show', true);

  const [isOpen, setIsOpen] = useState(false);
  const [statusList, setStatusList] = useState<string[]>([]);

  const fetchStatusList = () => {
    getDictionaryMethod().then((res) => {
      if (res.status === 200) {
        const arrFilter = res.data.operator_statuses.filter((item) => item !== operator.status);
        setStatusList(arrFilter);
      }
    });
  };

  useEffect(() => {
    if (operator) {
      fetchStatusList();
    }
  }, [operator, PermissionShowOperator]);

  const onToggleOpen = () => setIsOpen((p) => !p);

  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.status, styles[operator.status])}>
          <span onClick={onToggleOpen}>
            {operator && operator?.status ? operator.status : '---'}
          </span>
          <Icon
            name="caretDown"
            size={24}
            className={cn(isOpen && styles.active)}
            onClick={() => {
              if (PermissionActivate) {
                onToggleOpen();
              }
            }}
          />
        </div>
        {operator?.status_updated_at && (
          <span>
            since {moment(operator.status_updated_at * 1000).format('DD.MM.YYYY HH:mm:ss')}
          </span>
        )}
        <div className={cn(styles.dropdown, isOpen && styles.dropdown__open)}>
          {statusList.map((status, index) => {
            return (
              <div
                key={index}
                className={status === 'active' ? 'unblock' : 'blocked'}
                onClick={() => onChangeStatus(status)}
              >
                {status}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
