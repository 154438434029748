import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import Button from 'components/ui/Button';
import { Icon } from 'components/ui/Icon';
import OperatorChangePasswordPopup from './OperatorPopups/OperatorChangePasswordPopup';
import PopupYesOrNo from 'components/ui/PopupYesOrNo/PopupYesOrNo';
import { usePermission } from 'utils/usePermission';
import { ManagementOperator } from 'models/Management/Operators/Operator';
import { deleteOperator, getOperatorAutologinLink, resetOperatorPassword } from 'api/operator';
import { notify } from 'utils/notify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsTempleRole } from 'api/rolesAndPermissions';
import styles from './operator-page.module.scss';

type Props = {
  name: string;
  operator: ManagementOperator;
  updateComponentCallback: () => void;
};

export const OperatorPageHeader = ({ name, operator, updateComponentCallback }: Props) => {
  const navigate = useNavigate();

  const { permissionGiven: PermissionChangePassword } = usePermission(
    'admin.admin-user.change-password-for-other',
  );
  const { permissionGiven: PermissionDeleteOperator } = usePermission('admin.admin-user.delete');
  const { permissionGiven: PermissionAutologin } = usePermission(
    'admin.admin-user.enable-autologin',
  );

  const [autologinURL, setAutologinURL] = useState('');
  const [isLoadingResetPass, setIsLoadingResetPass] = useState(false);

  useEffect(() => {
    if (operator && operator.id && PermissionAutologin) {
      getOperatorAutologinLink(operator.id)
        .then((res) => {
          if (res.data) {
            setAutologinURL(res.data.url);
          }
        })
        .catch(console.log);
    }
  }, [operator, PermissionAutologin]);

  const onResetPassword = () => {
    const data = {
      email: operator.email,
    };
    setIsLoadingResetPass(true);
    resetOperatorPassword(data)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response.data.message,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoadingResetPass(false);
      });
  };

  return (
    <div className={styles.pageTitle}>
      <div className={styles.title}>
        <div className={styles.name}>{name}</div>
        <div className={styles.uuid} onClick={() => copyToBuffer(operator.uuid, true)}>
          {getShortUuid(operator.uuid)}
        </div>
      </div>
      <div className={styles.buttons}>
        {autologinURL && PermissionAutologin && (
          <Button
            buttonText="Autologin"
            onClick={() => {
              const roleId = operator?.roles_with_department[0]?.id;

              if (roleId) {
                getPermissionsTempleRole(roleId)
                  .then((response) => {
                    localStorage.setItem('permissions', JSON.stringify(response.data.data));
                    window.location.href = autologinURL;
                  })
                  .catch(console.log);
              }
            }}
          />
        )}

        <Button
          buttonType="outline"
          isLoading={isLoadingResetPass}
          buttonText="Reset password"
          type="submit"
          onClick={() => onResetPassword()}
        />
        {PermissionChangePassword ? (
          <OperatorChangePasswordPopup
            updateComponent={updateComponentCallback}
            userId={String(operator.id)}
            triggerBtn={<Button buttonType="outline" buttonText="Change password" />}
          />
        ) : null}
        {PermissionDeleteOperator ? (
          <PopupYesOrNo
            onClick={() => {
              deleteOperator(operator.id).then(() => navigate('/management-operators'));
            }}
            headerTitle={'Delete operator'}
            bodyText={`Are you sure that you want to delete operator ${operator.first_name} ${operator.last_name}?`}
            triggerBtn={
              <Button
                className={styles.deleteButton}
                buttonType="delete"
                icon={<Icon name="trash" size={18} />}
              />
            }
          />
        ) : null}
      </div>
    </div>
  );
};
