import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button/Button';
import { isFormChange } from 'utils/isFormChange';
import { clearEmptyFilter } from 'utils/clearEmptyFilter';
import {
  IDefaultValue,
  IprepareFiltersData,
  NotificationsPopupFilterProps,
} from 'models/components/NotificationPopup/NotificationPopupFilter.ts';
import { ReloadButton } from 'components/ui/ReloadButton';
import { MultiSelect } from 'components/ui/MultiSelect';

const cx = classNames.bind(styles);

const NotificationsPopupFilter: FC<NotificationsPopupFilterProps> = ({
  onSubmit,
  reloadFilters,
  notificationsTypesOptions,
}) => {
  const componentMounted = useRef<boolean>(true);

  const getDefaultValue = (): IDefaultValue => {
    return {
      type: [],
      is_read: [],
    };
  };

  const { handleSubmit, reset, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const prepareFiltersData = (data) => {
    const postData: IprepareFiltersData = {
      filter: {
        type: getValue(data['type']),
        is_read: getValue(data['is_read']),
      },
    };

    return postData;
  };

  const submit = (data): void => {
    const prepeared: IprepareFiltersData = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = (): void => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    return (): void => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  return (
    <form onSubmit={handleSubmit(submit)} className={cx('filters')}>
      <Row className="mb-3 align-items-end row-gap-3">
        <Col xs={12} md={12} lg={6} xl={4}>
          <MultiSelect
            label="Notification types"
            control={control}
            id="type"
            name="type"
            options={notificationsTypesOptions}
          />
        </Col>
        <Col xs={12} md={12} lg={6} xl={3}>
          <MultiSelect
            label="Read/Unread"
            control={control}
            id="is_read"
            name="is_read"
            options={[
              { value: 1, label: 'Read' },
              { value: 0, label: 'Unread' },
            ]}
          />
        </Col>
        <Col xs={12} md={12} lg={12} xl={5}>
          <div className={cx('filters__btns')}>
            <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
            <Button
              buttonText="Reset"
              buttonType="outline"
              isLoading={reloadFilters}
              type="reset"
              onClick={resetForm}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              isLoading={reloadFilters}
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default NotificationsPopupFilter;
