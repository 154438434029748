import { getCountryList } from 'api/country';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { activationOperatorMethod, getOperatorMethod } from 'api/operator/index';
import { notify } from 'utils/notify.tsx';
import { usePermission } from 'utils/usePermission.tsx';
import AddDepartmentList from './AddDepartmentList/index';
import BranchesBlock from './BranchesBlock/BranchesBlock';
import styles from './operator-page.module.scss';
import HierarchyForm from './OperatorForms/HierarchyForm';
import OperatorPersonalInfoForm from './OperatorForms/OperatorPersonalInfoForm';
import Feed from './OperatorTabs/Feed/Feed';
import SalesRules from './OperatorTabs/SalesRules';
import { CountryListValue } from 'models/Countries.js';
import { SalesRuleShort } from 'models/Management/SalesRules/Rule.js';
import { ManagementOperator } from 'models/Management/Operators/Operator.js';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { OperatorPageHeader } from './OperatorPageHeader.tsx';
import { OperatorInfo } from './OperatorInfo/OperatorInfo.tsx';

const OperatorPage = () => {
  const { permissionGiven: PermissionViewOperatorLogs } = usePermission('admin.logs.admin-user');
  const { permissionGiven: PermissionViewListBranch } = usePermission('admin.branch.index');
  const { permissionGiven: PermissionViewDepartment } = usePermission('admin.department.view');

  const params = useParams();

  const [nameItem, setItemName] = useState('');
  const [operatorData, setOperatorData] = useState<ManagementOperator>();

  const [openAccStatus, setOpenAccStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countryListArr, setCountryListArr] = useState<CountryListValue[]>([]);
  const [salesRulesList, setSalesRulesList] = useState<SalesRuleShort[]>([]);

  const title = 'Operator - ' + nameItem;
  const componentMounted = useRef(true);

  const changedStatus = (status) => {
    setOpenAccStatus(!openAccStatus);
    const data = {
      activation: status === 'active' ? 1 : 0,
    };
    activationOperatorMethod(operatorData.id, data)
      .then((res) => {
        if (res.status === 200) {
          getOperator(params);
          notify({
            type: 'success',
            message: 'status changed',
            timeOut: 3000,
          });
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response.data.message,
          timeOut: 3000,
        });
      });
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getCountryList().then((res) => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryListArr(opt);
      }
    });
  }, []);

  const getOperator = (params?) => {
    getOperatorMethod(params.id)
      .then((res) => {
        if (res.status === 200) {
          setOperatorData(res.data.data);
          setSalesRulesList(res.data.data.sale_rules);
          setIsLoading(false);
          setItemName(res.data.data.first_name + ' ' + res.data.data.last_name);
        }
      })
      .catch(console.log);
  };

  usePageTitle(title);

  useEffect(() => {
    getOperator(params);
  }, [params]);

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page
          isStickyTitle
          title={
            <OperatorPageHeader
              name={nameItem}
              operator={operatorData}
              updateComponentCallback={getOperator}
            />
          }
        >
          <>
            <OperatorInfo operator={operatorData} onChangeStatus={changedStatus} />
            <div className={styles.tabs}>
              <Tabs>
                <TabList>
                  <Tab>Profile</Tab>
                  <Tab>Sales Rules</Tab>
                  {PermissionViewOperatorLogs ? <Tab>Feed</Tab> : null}
                </TabList>

                <TabPanel>
                  <div className={styles.tabContainer}>
                    <OperatorPersonalInfoForm
                      formData={operatorData}
                      countryList={countryListArr}
                      updateComponent={() => getOperator(params)}
                    />
                    {PermissionViewDepartment ? (
                      <AddDepartmentList
                        operatorData={operatorData}
                        list={operatorData.roles_with_department}
                        updateComponent={() => getOperator(params)}
                      />
                    ) : null}
                    <HierarchyForm
                      formData={operatorData}
                      updateComponent={() => getOperator(params)}
                    />
                    {PermissionViewListBranch ? (
                      <BranchesBlock
                        operatorData={operatorData}
                        list={operatorData.branches}
                        role={operatorData.role}
                        updateComponent={() => getOperator(params)}
                      />
                    ) : null}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={styles.tabContainer}>
                    <SalesRules salesRulesList={salesRulesList} />
                  </div>
                </TabPanel>
                {PermissionViewOperatorLogs ? (
                  <TabPanel>
                    <div className={styles.tabContainer}>
                      <Feed />
                    </div>
                  </TabPanel>
                ) : null}
              </Tabs>
            </div>
          </>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default OperatorPage;
