import React, { useState } from 'react';
import { getOperatorsForTeamMethod, getOperatorsMethod } from 'api/hierarchy';
import { checkDevise } from 'utils/checkDevise';
import UserPopup from './UsersPopup';
import Button from 'components/ui/Button';
import { HierarchyTeam } from 'models/Hierarchy/HierarchyData';
import { Icon } from 'components/ui/Icon';
import cn from 'classnames';

import styles from './hierarchy-page.module.scss';

type Props = {
  data: HierarchyTeam;
};

const HierarchyComponentOperators = ({ data }: Props) => {
  const [team, setTeam] = useState(data);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const isMobile = checkDevise() === 'mobile';

  const handleClickOnTeam = () => {
    setOpen(true);
    getOperatorsMethod(team.id).then((res) => {
      if (res.status === 200) {
        setTeam((prev) => ({ ...prev, operators: res.data.data.operators }));
      }
    });
  };

  const ToggleButton = () => (
    <button
      className={styles.btnIcon}
      onClick={() => {
        open ? setOpen(false) : handleClickOnTeam();
      }}
    >
      <Icon name={open ? 'minusCircle' : 'plusCircle'} size={30} />
    </button>
  );

  const onHover = (v: boolean) => !isMobile && setHover(v);

  return (
    <div className={styles.wrapperItem}>
      <div
        className={cn(styles.wrapperContent, styles.wrapperTeam)}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        {(isMobile && team?.operators_count > 0) || (team?.branches_count > 0 && hover) ? (
          <ToggleButton />
        ) : (team?.operators_count > 0 && hover) || (team?.branches_count > 0 && hover) ? (
          <ToggleButton />
        ) : (
          <div className={styles.btnIcon}>
            <Icon name="team" size={30} />
          </div>
        )}

        <div className={styles.wrapperInfo}>
          <div>
            <p className={styles.topBlock}>{`${team?.name} Team`}</p>
            <p className={styles.bottomBlock}>
              {`${team?.branches_count} branches / ${team?.operators_count}`}{' '}
              {team?.operators_count === 1 ? 'user' : 'users'}
            </p>
          </div>
          {team?.operators_count > 0 && (
            <UserPopup
              fetchMethod={getOperatorsForTeamMethod}
              data={team}
              namePopup={`team ${team.name}`}
              triggerBtn={
                <Button
                  icon={<Icon name="user" />}
                  className={styles.userButton}
                  onClick={() => null}
                  buttonType={'outline'}
                />
              }
            />
          )}
        </div>
      </div>
      {open && team?.operators?.length > 0 && (
        <div className={styles.wrapperList}>
          {team.operators?.map((operator) => {
            return (
              <div key={operator.id} className={styles.itemList}>
                <div className={styles.wrapperContent}>
                  <div className={styles.btnIcon}>
                    <Icon name="userLogo" size={30} />
                  </div>
                  <div className={styles.wrapperInfo}>
                    <div>
                      <p className={styles.topBlock}>{`${operator?.first_name} Operator`}</p>
                      <div className={styles.bottomBlock}>
                        {operator.role.map((item, ind) => {
                          return (
                            <span key={ind} className={styles.greyText}>
                              {item}
                              {operator.role.length - 1 !== ind && <span>, </span>}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default HierarchyComponentOperators;
