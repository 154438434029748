import cn from 'classnames';
import { format } from "date-fns";

import { ChangeEvent, useEffect, useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useController } from 'react-hook-form';
import {
  combineDateTime,
  DateRangeInputProps,
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultFromTime,
  defaultTimeFormat,
  defaultToTime,
  InputField,
  isValidDate,
  isValidTime,
  ModernDateInputHeader,
} from './';

import './DateInput.scss';
import { Icon } from '../Icon';

type DateValue = Date | null;
type DateHandlerProps = DateValue | [DateValue, DateValue] | undefined;

export const ModernDateRangeInput = (
  {
    id,
    name,
    label,
    disabled,
    tooltip,
    rules,
    onChange,
    control,
    dateFormat,
    placeholder,
    showTimeInput,
    showTimeSelect,
    renderCustomHeader,
    portalId,
    allowToChangeViaInput,
  }: DateRangeInputProps
) => {
  const [time, setTime] = useState<{ fromTime: string | null; toTime: string | null }>();
  const {field, formState,} = useController({
    name,
    control,
    disabled: !!disabled,
    rules,
    defaultValue: {from: null, to: null},
  });

  const {onChange: onChangeField, value} = field;

  const onChangeHandler = (dates: DateHandlerProps): void => {
    if (Array.isArray(dates)) {
      let [from, to] = dates;

      onChangeField({
        from: combineDateTime(from, time?.fromTime, defaultFromTime),
        to: combineDateTime(to, time?.toTime, defaultToTime),
      });
      onChange?.({
        from: combineDateTime(from, time?.fromTime, defaultFromTime),
        to: combineDateTime(to, time?.toTime, defaultToTime),
      });
    }
  };

  const onChangeRawHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!allowToChangeViaInput) return;

    const {value} = event.target;

    if (!value) return;

    const [startDateString, endDateString] = event.target.value.split(' - ');
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    let dates = [startDate, endDate] as DateHandlerProps;
    let dateStrings = [startDateString, endDateString];

    if (!isValidDate(startDate) && !isValidDate(endDate)) return;

    if (isValidDate(startDate) && !isValidDate(endDate)) {
      dates = [startDate, field.value.to] as DateHandlerProps;
      dateStrings = [startDateString, field.value.to ? format(field.value.to, defaultDateTimeFormat) : ''];
    }

    if (isValidDate(endDate) && !isValidDate(startDate)) {
      dates = [field.value.from, endDate] as DateHandlerProps;
      dateStrings = [format(field.value.from, defaultDateTimeFormat), endDateString];
    }

    onChangeHandler(dates);

    dateStrings
      .filter((dateTimeString) => !!dateTimeString)
      .forEach((dateTimeString, index) => {
        const time = dateTimeString.split(' ')[1];

        if (!isValidTime(time)) return;

        const [hours, minutes] = time.split(':').map(Number);
        const dateTime = new Date().setHours(hours, minutes, 0, 0);
        const timeName = index === 0 ? 'fromTime' : 'toTime';

        onTimeChangeHandler({
          target: {name: timeName, value: format(dateTime, defaultTimeFormat)},
        } as unknown as Event);
      });
  };

  const onBlurHandler = () => {
    if (!field.value.to && field.value.from) {
      onChangeHandler([field.value.from, new Date(new Date().setHours(23, 59, 0, 0))]);
    }
  };

  const onRenderCustomHeaderHandler = (props: ReactDatePickerCustomHeaderProps) => {
    return renderCustomHeader ? (
      renderCustomHeader({...props, from, to}, onTimeChangeHandler)
    ) : (
      <ModernDateInputHeader {...props} from={from} to={to} showRange/>
    );
  };

  const onTimeChangeHandler = (event: Event): void => {
    const inputElement = event.target as HTMLInputElement;

    setTime((state) => ({
      fromTime: inputElement.name === 'fromTime' ? `${inputElement.value}:00` : state?.fromTime ?? null,
      toTime: inputElement.name === 'toTime' ? `${inputElement.value}:00` : state?.toTime ?? null,
    }));
  };

  useEffect(() => {
    if (time) {
      onChangeField({
        from: combineDateTime(from, time?.fromTime, defaultFromTime),
        to: combineDateTime(to, time?.toTime, defaultToTime),
      });
      onChange?.({
        from: combineDateTime(from, time?.fromTime, defaultFromTime),
        to: combineDateTime(to, time?.toTime, defaultToTime),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const fieldError = `${formState.errors?.[name]?.message || ''}`;
  const from = value?.from ? new Date(value.from) : null;
  const to = value?.to ? new Date(value.to) : null;

  return (
    <InputField
      id={id}
      label={label}
      error={fieldError}
      tooltip={tooltip}
      className={cn('date-range-input date-input', {required: rules?.required})}
    >
      <DatePicker
        id={id}
        portalId={portalId}
        renderCustomHeader={onRenderCustomHeaderHandler}
        onChange={onChangeHandler}
        onChangeRaw={onChangeRawHandler}
        onBlur={onBlurHandler}
        onClickOutside={onBlurHandler}
        className="input"
        selected={from}
        startDate={from}
        endDate={to}
        showTimeSelect={showTimeSelect}
        showTimeInput={showTimeInput}
        selectsRange
        placeholderText={placeholder}
        shouldCloseOnSelect={false}
        dateFormat={dateFormat ?? defaultDateFormat}
      />
      <div className="field-icon">
        <Icon name="calendar" />
      </div>
    </InputField>
  );
};
