import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Select from 'components/ui/Select/Select';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { notify } from 'utils/notify';
import { createScheduleEvent, updateScheduleEvent } from 'api/partners';

import CounterTime from 'components/ui/CounterTime';
import { useFilteredList } from 'utils/filteredList';
import styles from './style-popup.module.scss';
import { Icon } from 'components/ui/Icon';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const cx = classNames.bind(styles);

const EditPartnerSchedulesPopup = ({
  triggerBtn,
  updateComponent,
  countryList,
  langList,
  operatorsList,
  partnersList,
  actionType,
  ruleData,
  sourceArray,
  day,
}) => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [source, setSource] = useState(sourceArray || []);
  const [sourceIsChange, seSourceIsChange] = useState<boolean>(false);
  const contentToScrol = useRef(null);
  const dynamicHeightContent = useRef(null);
  const { filteredList, onFilterList } = useFilteredList(countryList);

  const getTime = (time) => {
    const time_from_arr = time?.split(':');
    return { hours: time_from_arr[0], minutes: time_from_arr[1] };
  };

  const setDefaultValues = () => {
    let partner_ids = [];
    let countries = [];
    if (!ruleData) {
      return;
    }

    ruleData.partners?.forEach((item): void => {
      const target = partnersList.filter((target): boolean => target.value === item.id);
      if (target.length) {
        partner_ids = partner_ids.concat(target);
      }
    });

    ruleData.countries?.forEach((item): void => {
      const target = countryList.filter((target): boolean => target.value === item.id);

      if (target.length) {
        countries = countries.concat({
          id: target[0],
          limit: item.limit,
        });
      }
    });

    return {
      id: ruleData.id,
      from: ruleData?.from ? getTime(ruleData?.from) : { hours: 0, minutes: 0 },
      to: ruleData?.to ? getTime(ruleData?.to) : { hours: 0, minutes: 0 },
      partner_id: ruleData.partner_id,
      active: ruleData.active,
      day: ruleData.day,
      total_leads_limit: ruleData.total_leads_limit,
      countries: countries.concat([
        {
          limit: null,
          id: null,
        },
      ]),
    };
  };

  const { handleSubmit, getValues, control, reset, watch, formState, setError } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  useEffect(() => {
    onFilterList(getValues('countries'));
  }, [getValues('countries')]);

  const { isDirty } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'countries',
  });

  const closeModal = (close) => {
    sourceArray = source;
    setSource(sourceArray || []);
    reset(setDefaultValues(), { keepDefaultValues: true });
    close?.();
  };

  useEffect(() => {
    const defaultValue = sourceArray ? sourceArray.join(', ') : '';
    const target = source ? source.join(', ') : '';

    if (defaultValue !== target) {
      seSourceIsChange(true);
    } else {
      seSourceIsChange(false);
    }
  }, [source]);

  useEffect(() => {
    reset(setDefaultValues(), { keepDefaultValues: true });
  }, [countryList, langList, partnersList, ruleData]);

  const onSubmit = (close, data) => {
    const countries = [];

    const time = {
      hours_from: data?.from?.hours < 10 ? `0${data?.from?.hours}` : data?.from?.hours,
      minutes_from: data?.from?.minutes < 10 ? `0${data?.from?.minutes}` : data?.from?.minutes,
      hours_to: data?.to?.hours < 10 ? `0${data?.to?.hours}` : data?.to?.hours,
      minutes_to: data?.to?.minutes < 10 ? `0${data?.to?.minutes}` : data?.to?.minutes,
    };

    data.countries?.forEach((item, index) => {
      if (!item['id']?.value) {
        return;
      }

      countries.push({
        ...item,
        id: item['id'].value,
      });
    });

    const sourceJson = {};

    source.forEach((item, index) => {
      sourceJson[index] = item;
    });

    const sendData = {
      total_leads_limit: data.total_leads_limit,
      partner_id: data.partner_id,
      active: data.active ? data.active : 0,
      day: day,
      ...time,
      countries,
    };
    const countryLimit = data.countries
      ?.filter((item) => item.limit)
      ?.map((item) => +item.limit)
      ?.reduce((prev, item) => prev + item, 0);

    if (countryLimit > +data.total_leads_limit) {
      setError('total_leads_limit', {
        type: 'custom',
        message: 'Countries limits must not exceed Total leads limit',
      });
    } else {
      editRuleHandler(sendData, close);
    }
  };

  const editRuleHandler = (sendData, close) => {
    setIsLoading(true);
    if (ruleData.id) {
      updateScheduleEvent(ruleData.id, sendData)
        .then((res) => {
          if (res.data.data) {
            notify({
              type: 'success',
              message: 'Event edited successfully',
              timeOut: 3000,
            });
            updateComponent();
            closeModal(close);
          }
        })
        .catch((error) => {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      createScheduleEvent(ruleData.id, { ...sendData, partner_id: params.id })
        .then((res) => {
          if (res.data.data) {
            notify({
              type: 'success',
              message: 'Event created successfully',
              timeOut: 3000,
            });
            updateComponent();
            closeModal(close);
          }
        })
        .catch((error) => {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getLastIndex = () => {
    return fields.length - 1;
  };
  const showDelete = (ind): boolean => {
    if (ind === 0 && fields.length < 2) {
      return false;
    } else {
      return true;
    }
  };

  const checkIsLastSelectInArrayIsEmpty = (currentIndex) => {
    return (
      // !fields[getLastIndex()].id && !fields[getLastIndex()].limit &&
      getLastIndex() === currentIndex
    );
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onClose={closeModal}
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={`${days[day - 1]} API Settings`}
            style={{
              wrapper: {
                maxWidth: '600px',
              },
            }}
            rightContent={
              <div className={cx('content')}>
                <div
                  className={cx('content-form', 'popupForm', 'sales-rules')}
                  ref={contentToScrol}
                >
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container ref={dynamicHeightContent}>
                      <p className={cx('infoText')}>
                        Please use 00.00 - 24.00 time format to set the working time period.
                      </p>
                      <Row>
                        <Col md={12} className="mb-3">
                          <FormInput
                            min={0}
                            type={'number'}
                            label="Total leads limit"
                            id="total_leads_limit"
                            control={control}
                            name="total_leads_limit"
                            placeholder="Total leads limit"
                            errors={formState.errors.total_leads_limit}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <CounterTime
                            label="Working Hours From"
                            id="from"
                            control={control}
                            name="from"
                          />
                        </Col>
                        <Col md={6}>
                          <CounterTime
                            label="Working Hours To"
                            id="to"
                            control={control}
                            name="to"
                          />
                        </Col>
                      </Row>
                      <div>
                        {fields.map((item, index) => (
                          <Row key={item.id}>
                            <Col md={6} className="mb-3">
                              <JokerSelect
                                label="Country"
                                control={control}
                                id="country"
                                // onMenuOpen={() => {
                                //   setTimeout(
                                //     () =>
                                //       contentToScrol.current.scrollTo({
                                //         // top: dynamicHeightContent.current.clientHeight,
                                //         top: (index + 1) * 130,
                                //         left: 0,
                                //         behavior: 'smooth',
                                //       }),
                                //     100,
                                //   );
                                // }}
                                name={`countries.${index}.id`}
                                placeholder={'--Select--'}
                                onSelectChange={() => {
                                  if (checkIsLastSelectInArrayIsEmpty(index)) {
                                    append({
                                      limit: undefined,
                                      id: undefined,
                                    });
                                  }
                                }}
                                rightaligned={true}
                                options={filteredList}
                              />
                            </Col>
                            <Col md={5} className="mb-3">
                              <FormInput
                                label="Country limit"
                                id={`countries.${index}.limit`}
                                control={control}
                                type={'number'}
                                min={0}
                                name={`countries.${index}.limit`}
                                placeholder="Country limit"
                                disabled={!getValues(`countries.${index}.id`)}
                                rules={{
                                  required: !!getValues(`countries.${index}.id`),
                                }}
                              />
                            </Col>
                            <Col md={1}>
                              {showDelete(index) ? (
                                <div
                                  className={cx('delete-col')}
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <Icon name="trash" size={20} />
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Container>
                    <div className={cx('content-controls')}>
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                disabled={!isDirty && !sourceIsChange}
                                buttonText={'Apply change'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default EditPartnerSchedulesPopup;
