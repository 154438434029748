import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';
import { ArrowsLeftRight, Wallet } from 'phosphor-react';
import moment from 'moment';
import { changeWebHookStatus } from 'api/customer-service/crypto-payments';
import { notify } from 'utils/notify';
import { Link } from 'react-router-dom';
import { usePermission } from 'utils/usePermission';
import { CRYPTO_WALLETS_TYPES } from 'constants/payments.const';
import { ICryptoTransactionsTableProps } from 'models/components/Table/CryptoTransactionsTable/CryptoTransactionsTable.ts';
import { getShortUuid } from 'utils/getShortUuid';
import { Icon } from 'components/ui/Icon';
import { upperFirst } from 'lodash';
import styles from './crypto-transactions-table.module.scss';

const cx = classNames.bind(styles);

const CryptoTransactionsTable: FC<ICryptoTransactionsTableProps> = ({
  data,
  perPage,
  showLoader,
  columns,
  onSaveColumnOrder,
  modifyDataHandler,
  onRowSelect,
}) => {
  const { permissionGiven: PermissionViewCryptoTransactionsDetails } = usePermission(
    'admin.crypto_transaction.view-details',
  );

  // const columns = React.useMemo(() => TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item) => {
    const {
      id,
      wallet_number,
      crypto_type,
      amount,
      status,
      created_at,
      block_cypher_explorer_link,
      crypto_hash,
      fintegrity,
    } = item;

    const changeStatus = (id, status, setShowActionMenu): void => {
      changeWebHookStatus(id, { web_hook_status: status })
        .then((res: any) => {
          if (!res.errors) {
            modifyDataHandler();
            notify({
              type: 'success',
              message: 'Status changed success',
              timeOut: 3000,
            });
          }
        })
        .catch((err) => {
          notify({
            type: 'error',
            message: err.response,
            timeOut: 3000,
          });
        });
    };

    return {
      crypto_type: (
        <div className={cx('transaction__type')}>
          {PermissionViewCryptoTransactionsDetails ? (
            <Link to={`/customer-transactions/${id}`} className={cx('logo')}>
              <div className={cx('transaction__item')}>
                <Icon name={crypto_type?.toLowerCase()} size={18} />
                <div className={cx('transaction__amount')}>
                  <span>{CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}</span>
                  {fintegrity === 1 && (
                    <span>
                      <br />
                      Fintegrity
                    </span>
                  )}
                </div>
              </div>
            </Link>
          ) : (
            <div className={cx('transaction__item')}>
              <Icon name={crypto_type?.toLowerCase()} size={18} />
              <div className={cx('transaction__amount')}>
                <span>{CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}</span>
                {fintegrity === 1 && (
                  <span>
                    <br />
                    Fintegrity
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      ),
      wallet: (
        <div className={cx('transaction__desc')}>
          <div>
            <Icon name="wallet" size={18} /> Wallet:&nbsp;
            <span className={cx('transaction__name')} onClick={() => copyToBuffer(wallet_number, true)}>
              {wallet_number}
            </span>
          </div>

          {crypto_hash && (
            <div>
              <Icon name="arrowsRepeat" size={18} /> Crypto hash:&nbsp;
              <span className={cx('transaction__name')} onClick={() => copyToBuffer(crypto_hash, true)}>
                {crypto_hash}
              </span>
            </div>
          )}

          {PermissionViewCryptoTransactionsDetails ? (
            <div className={cx('transaction__link')}>
              <a href={block_cypher_explorer_link} target="_blank">
                Block cypher Explore
              </a>
            </div>
          ) : null}
        </div>
      ),
      amount: (
        <div className={cx('transaction__amount')}>
          <span>{amount}</span>&nbsp;<span>{crypto_type}</span>
        </div>
      ),
      created: (
        <div className={cx('transaction__created')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      user_profile: (
        <div className={cx('wallet_table_td')}>
          <Link
            target={'_blank'}
            to={`/client/${item?.user_profile?.user_id ?? 0}`}
            className={cx('wallet_table_td-link')}
            style={{ display: 'block' }}
          >
            <span>
              {item && item.user_profile && item.user_profile.first_name
                ? item.user_profile.first_name + ' '
                : ''}
              {item && item.user_profile && item.user_profile.last_name
                ? item.user_profile.last_name
                : ''}
            </span>
          </Link>
          {item.user_profile !== null && item?.user_profile?.user_uuid && (
            <span
              className={cx('uuid')}
              onClick={() => copyToBuffer(item?.user_profile?.user_uuid, true)}
            >
              {getShortUuid(item?.user_profile?.user_uuid)}
            </span>
          )}
        </div>
      ),
      status: (
        <div className={cx('transaction__pinned')}>
          <span className={cx(status)}>{upperFirst(status)}</span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('transaction-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
      saveColumnOrder={onSaveColumnOrder}
    />
  );
};

export default CryptoTransactionsTable;
