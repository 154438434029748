import React from 'react';
import classNames from 'classnames/bind';
import styles from './operators-table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getShortUuid } from 'utils/getShortUuid';
import PopupYesOrNo from 'components/ui/PopupYesOrNo/PopupYesOrNo';
import { usePermission } from 'utils/usePermission';
import { deleteOperator } from 'api/operator';
import { Icon } from 'components/ui/Icon';
import { upperFirst } from 'lodash';

const cx = classNames.bind(styles);

const OperatorsTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  manualSortHandler,
  onSort,
  modifyDataHandler,
  columns,
  saveColumnOrder,
}) => {
  // const columns = React.useMemo(() => TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);
  const { permissionGiven: PermissionDeleteOperator } = usePermission('admin.admin-user.delete');

  const checkSortEnable = (colId) => {
    switch (colId) {
      case 'operator':
      case 'country':
      case 'status':
      case 'created_at':
        return true;
      default:
        return false;
    }
  };
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, first_name, uuid, country, status, created_at, status_updated_at, last_name } =
      item;
    return {
      operator: (
        <div className={cx('rule__name')}>
          <Link target="_blank" to={`/operator/${id}`} className={cx('logo')}>
            <span>{first_name + ' ' + last_name}</span>
          </Link>
          <span className={cx('rule__id')} onClick={() => copyToBuffer(uuid, true)}>
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      country: country ? (
        <div className={cx('rule__country')}>
          {/*<b>{country?.length} Countries</b>*/}
          <div className={cx('client__country-flag')}>
            <i className={cx('icon')} style={{ backgroundImage: `url(${country.flag})` }} />
          </div>
          <span>{country.iso_3166_2}</span>
        </div>
      ) : (
        <div>
          <span>&mdash;</span>
        </div>
      ),
      created_at: (
        <div className={cx('rule__date')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status: (
        <div className={cx('rule__name')}>
          <span
            className={cx('status_text')}
            style={status === 'active' ? { color: 'var(--green)' } : { color: 'var(--red)' }}
          >
            {upperFirst(status)}
          </span>
          <span>
            <FormattedMessage id="management.operators.HeaderColumn6" defaultMessage="since " />
            {moment.unix(status_updated_at).format('DD.MM.YYYY')}
          </span>
        </div>
      ),
      action:
        PermissionDeleteOperator && id ? (
          <div className={cx('rule__actions')}>
            <PopupYesOrNo
              onClick={() => {
                deleteOperator(id).then(() => modifyDataHandler());
              }}
              headerTitle={'Delete operator'}
              bodyText={`Are you sure that you want to delete operator ${first_name} ${last_name}?`}
              triggerBtn={<Icon name="trash" size={20} color="var(--red)" />}
            />
          </div>
        ) : (
          <div>
            <span>&mdash;</span>
          </div>
        ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      manualSortHandler={manualSortHandler}
      colSortCheck={checkSortEnable}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default OperatorsTable;
