import { ChatCircleText } from "phosphor-react";
import { openInNewTab } from "../../helpers/openInNewTab";
import { MenuOption } from "./MenuOption";

export const OpenInNewTabOption = ({ threadId }) => {
    return (
        <MenuOption title='Open chat in new tab' onClick={() => openInNewTab(`/chat/${threadId}`)}>
            <ChatCircleText size={32} color="var(--main-color)"/>
        </MenuOption>
    )
}