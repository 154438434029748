import { updatePersonalClientInfo } from 'api/profile';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import React, { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { checkPerimissionForShowData } from 'utils/checkPermissionForShowData';
import { notify } from 'utils/notify';
import { VALID_EMAIL } from 'constants/validators';
import styles from './../client-page.module.scss';

const cx = classNames.bind(styles);

const ContactForm = ({ formData, updateComponent }) => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    const payload = { ...data };

    if (formData.phone === payload.phone) {
      delete payload.phone;
    }
    if (formData.email === payload.email) {
      delete payload.email;
    }
    if (formData.user_profile.alt_email === payload.alt_email) {
      delete payload.alt_email;
    }
    if (formData.user_profile.alt_phone === payload.alt_phone) {
      delete payload.alt_phone;
    }

    if (Object.values(payload).length === 0) return;

    updatePersonalClientInfo(formData.user_profile.id, payload)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Contacts updated successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setIsLoading(false);
      reset({
        phone: checkPerimissionForShowData('admin.client.view-phone', ['Super Admin'])
          ? formData.phone
          : '********',
        alt_email: checkPerimissionForShowData('admin.client.view-email', ['Super Admin'])
          ? formData.user_profile.alt_email
          : '********',
        alt_phone: checkPerimissionForShowData('admin.client.view-phone', ['Super Admin'])
          ? formData.user_profile.alt_phone
          : '********',
        email: checkPerimissionForShowData('admin.client.view-email', ['Super Admin'])
          ? formData.email
          : '********',
      });
    }
  }, [formData]);

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <div className={styles.tabTitle}>
          Contacts
          {isDirty && <Button buttonText="Save changes" />}
        </div>
        <Row>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <FormInput
              rules={{
                validate: VALID_EMAIL,
              }}
              errors={errors.email}
              name="email"
              type="email"
              label="E-mail"
              control={control}
              disabled={
                checkPerimissionForShowData('admin.client.view-email', ['Super Admin'])
                  ? false
                  : true
              }
              hideValue={
                checkPerimissionForShowData('admin.client.view-email', ['Super Admin'])
                  ? true
                  : false
              }
            />
          </Col>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <FormInput
              rules={{
                validate: {
                  correctEmail: (value) =>
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                      value,
                    ) ||
                    !value ||
                    value.trim().length === 0 ||
                    'Incorrect E-mail address',
                },
              }}
              errors={errors.alt_email}
              name="alt_email"
              type="email"
              label="Alternative E-mail"
              control={control}
              disabled={
                checkPerimissionForShowData('admin.client.view-email', ['Super Admin'])
                  ? false
                  : true
              }
              hideValue={
                checkPerimissionForShowData('admin.client.view-email', ['Super Admin'])
                  ? true
                  : false
              }
            />
          </Col>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <FormInput
              rules={{
                validate: {
                  correctPhone: (value) =>
                    /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,12}$/.test(
                      value,
                    ) ||
                    !value ||
                    value.trim().length === 0 ||
                    'Enter a different phone format',
                },
              }}
              errors={errors.phone}
              name="phone"
              type="text"
              label="Phone"
              maxLength="20"
              control={control}
              disabled={
                checkPerimissionForShowData('admin.client.view-phone', ['Super Admin'])
                  ? false
                  : true
              }
              hideValue={
                checkPerimissionForShowData('admin.client.view-phone', ['Super Admin'])
                  ? true
                  : false
              }
            />
          </Col>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <FormInput
              rules={{
                validate: {
                  correctPhone: (value) =>
                    /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,12}$/.test(
                      value,
                    ) ||
                    !value ||
                    value.trim().length === 0 ||
                    'Enter a different phone format',
                },
              }}
              errors={errors.alt_phone}
              name="alt_phone"
              maxLength="20"
              type="text"
              label="Alternative Phone"
              control={control}
              disabled={
                checkPerimissionForShowData('admin.client.view-phone', ['Super Admin'])
                  ? false
                  : true
              }
              hideValue={
                checkPerimissionForShowData('admin.client.view-phone', ['Super Admin'])
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default ContactForm;
