import { usePermission } from 'utils/usePermission';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import Button from 'components/ui/Button';
import CreateTeamsPopup from './TeamsPopups/CreateTeamsPopup';
import styles from './teams-page.module.scss';

type Props = {
  total?: number;
  updateComponentCallback: () => void;
};

export const TeamsPageTitle = ({ total, updateComponentCallback }: Props) => {
  const { permissionGiven: PermissionCreate } = usePermission('admin.team.create');

  return (
    <div className={styles.pageTitle}>
      <div>{total || 0} Teams</div>
      {PermissionCreate && (
        <CreateTeamsPopup
          updateComponent={updateComponentCallback}
          actionType={SALES_RULES_ACTION_TYPE.CREATE}
          triggerBtn={
            <Button buttonText="+ Add team" onClick={() => null} buttonType={'outline'} />
          }
        />
      )}
    </div>
  );
};
