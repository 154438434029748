import React from 'react';
import classNames from 'classnames/bind';
import styles from './table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import moment from 'moment';

const cx = classNames.bind(styles);

const OpenedPositionsTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  manualSortHandler,
  colSortCheck,
  onSort,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      current_price,
      open_price,
      opened_at,
      opened_at_dt,
      position,
      profit,
      symbol,
      volume,
      account,
      side,
      demo,
    } = item;

    return {
      account: (
        <div className={cx('payments-table_td')}>
          {demo === 1 && (
            <>
              <span style={{ color: 'var(--blue)' }}>DEMO</span>{' '}
            </>
          )}
          <span>{account}</span>
        </div>
      ),
      side: (
        <div className={cx('payments-table_td')}>
          <span>{side}</span>
        </div>
      ),
      position: (
        <div className={cx('payments-table_td')}>
          <span>{position}</span>
        </div>
      ),
      symbol: (
        <div className={cx('payments-table_td')}>
          <span>{symbol}</span>
        </div>
      ),
      open_price: (
        <div className={cx('payments-table_td')}>
          <span>{open_price}</span>
        </div>
      ),
      current_price: (
        <div className={cx('payments-table_td')}>
          <span>{current_price}</span>
        </div>
      ),
      volume: (
        <div className={cx('payments-table_td')}>
          <span>{volume}</span>
        </div>
      ),
      profit: (
        <div className={cx('payments-table_td')}>
          <span>{profit}</span>
        </div>
      ),
      open_time: (
        <div className={cx('payments-table_td', 'date-cell')}>
          <span>{moment(opened_at_dt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
          <span>{moment(opened_at_dt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  const checkSortEnable = (colId) => {
    switch (colId) {
      case 'original_agent':
      case 'country':
      case 'amount':
      case 'trading_acc':
      case 'date':
        return true;
      default:
        return false;
    }
  };

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('payments-table')}
      manualSortHandler={manualSortHandler}
      colSortCheck={colSortCheck || checkSortEnable}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default OpenedPositionsTable;
