import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './styles-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  
  data: { id: string | number }
  currentMethod: (id: string | number) => void;
  
  headerTitle: string;
  textQuery: string;
  btnText: string;
}

const ApproveRequestPopup = (props: Props) => {
  const {
    triggerBtn,
    data,
    headerTitle,
    textQuery,
    btnText,
  
    currentMethod,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (closeModal) => {
    closeModal();
  };

  const onSubmit = (close) => {
    setIsLoading(true);
    currentMethod(data.id);
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={headerTitle}
            rightContent={
              <div className={cx('content-form', 'popupForm')}>
                <div className={cx('content-controls')}>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <span>{textQuery}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                          <Button
                            isLoading={isLoading}
                            buttonText={btnText}
                            onClick={(data) => onSubmit(close)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default ApproveRequestPopup;
