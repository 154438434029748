import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from '../clients-table.module.scss';
import moment from 'moment';

const cx = classNames.bind(styles);

interface IRegistration {
  registration: number;
}
const Registration: FC<IRegistration> = ({ registration }) => {
  return (
    <div className={cx('client__registration')}>
      <span>
        <b>{moment.unix(registration).format('DD.MM.YYYY')}</b>
      </span>
      <span>{moment.unix(registration).format('HH:mm')}</span>
    </div>
  );
};

export default memo(Registration);
