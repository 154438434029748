import { useAppDispatch, useAppSelector } from 'store';
import {
  closeJokerModal,
  selectModalIsConstructiveMode,
  selectModalProps,
} from 'store/slice/modalSlice';
import { SystemMessageForm } from '../SystemMessageForm/SystemMessageForm';
import { AlertModalMode, SystemMessageFormSubmitData } from 'models/Ticker';
import { createAlert, updateAlert } from 'store/slice/tickerSlice';
import { useState } from 'react';
import Popup from 'reactjs-popup';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import { Container } from 'react-bootstrap';

export const SYSTEM_MESSAGE_MODAL_KEY = 'SYSTEM_MESSAGE_MODAL_KEY';

export const SystemMessageModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const isConstructiveMode = useAppSelector(selectModalIsConstructiveMode);
  const modalProps = useAppSelector(selectModalProps);

  const isEdit = modalProps?.mode === AlertModalMode.EDIT;
  const isRepublish = modalProps?.mode === AlertModalMode.REPUBLISH;

  const handleCloseModal = (close) => {
    close?.();
    dispatch(closeJokerModal());
  };

  const onSubmitHandler = async (close, data: SystemMessageFormSubmitData) => {
    setIsLoading(true);

    if (isEdit || isRepublish) {
      await dispatch(updateAlert({ message: data, id: modalProps?.message?.id, isRepublish }))
        .then((result) => {
          if (result.type === 'ticker/update/fulfilled') {
            handleCloseModal(close);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      await dispatch(createAlert(data))
        .then((result) => {
          if (result.type === 'ticker/create/fulfilled') {
            handleCloseModal(close);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Popup
      modal
      closeOnEscape
      repositionOnResize
      lockScroll
      closeOnDocumentClick
      open={isConstructiveMode}
      onClose={handleCloseModal}
    >
      {(close) => (
        <PopupTemplate
          isBigPopup={false}
          trigger={<button> Trigger</button>}
          dismissModal={close}
          headerTitle={modalProps?.title}
          rightContent={
            <Container>
              <SystemMessageForm
                onSubmit={onSubmitHandler.bind(undefined, close)}
                onCancel={() => handleCloseModal(close)}
                formValues={modalProps?.message}
                edit={isEdit}
                loading={isLoading}
                submitTitle={modalProps?.submitTitle}
              />
            </Container>
          }
        />
      )}
    </Popup>
  );
};
