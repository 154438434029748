import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';

import { notify } from 'utils/notify.tsx';

import styles from '../crypto-payments-page.module.scss';
import PaginateSelect from 'components/ui/PaginateSelect';
import { fetchClientsPaginate } from 'utils/managment/fetchData.js';
import getUTCtime from 'utils/getUTCTime.js';
import { copyToBuffer } from 'utils/copyToBuffer.js';
import PopupTemplate from 'components/ui/PopupTemplate';
import DatePicker from 'components/ui/DatePicker';
import CheckBox from 'components/ui/CheckBox';
import Button from 'components/ui/Button';
import { CryptoWallet } from 'models/CustomerService/CryptoWallets.js';
import { setWalletToUsers } from 'api/customer-service/crypto-payments';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  clientsList: any[];
  wallet: CryptoWallet;
};

const defaultValues = {
  user_ids: undefined,
  time_from: undefined,
  time_to: undefined,
  pinned: false,
  general: 0,
};

const PinToUserPopup = (props: Props) => {
  const { triggerBtn, updateComponent, clientsList, wallet } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, watch, control, reset, formState, unregister, register, clearErrors } =
    useForm({
      reValidateMode: 'onChange',
      defaultValues,
      shouldUnregister: true,
    });
  const { errors } = formState;

  const pinnedWatch = watch('pinned');

  useEffect(() => {
    if (pinnedWatch) {
      clearErrors();
      unregister(['time_from', 'time_to']);
    } else {
      register('time_from', { required: true });
      register('time_to', { required: true });
    }
  }, [pinnedWatch]);

  const closeModal = (closeModal) => {
    reset(defaultValues, { keepDefaultValues: true });
    closeModal();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [wallet]);

  const onSubmit = (close, data) => {
    let sendData;

    const users_id = data?.user_ids.map((item) => item.value);

    if (data && !data.pinned) {
      const form = getUTCtime(null, data.time_from);
      const to = getUTCtime(null, data.time_to);
      sendData = {
        wallet_id: wallet.id,
        time_from: Math.round(form / 1000),
        time_to: Math.round(to / 1000),
        pinned: data.pinned,
        user_ids: users_id,
        general: Number(data.general),
      };
    } else {
      sendData = {
        wallet_id: wallet.id,
        pinned: data.pinned,
        user_ids: users_id,
        general: Number(data.general),
      };
    }
    setIsLoading(true);
    setWalletToUsers(sendData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          notify({
            type: 'success',
            message: 'Wallet attached successful',
            timeOut: 3000,
          });
          setIsLoading(false);
          closeModal(close);
        }
      })
      .then(() => {
        updateComponent();
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response || 'Unable to set Wallet',
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Set wallet to client'}
            rightContent={
              <div className={cx('content wallet__pin')}>
                <div className={cx('content-form', 'popupForm', 'pin-to-user')}>
                  <input type="text" autoFocus={true} style={{ display: 'none' }} />
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <span className={cx('wallet__pin-title')}>
                      Wallet{' '}
                      <span
                        className={cx('wallet__number')}
                        onClick={() => copyToBuffer(wallet.wallet)}
                      >
                        {wallet.wallet}
                      </span>
                    </span>
                    <Row>
                      <Col md={12} className={cx('pin-to-user__col')}>
                        {clientsList ? (
                          <PaginateSelect
                            isRequired={true}
                            isMulti={true}
                            control={control}
                            label="Client"
                            id="user_ids"
                            placeholder={'--Select--'}
                            name="user_ids"
                            onChange={(page, search) => fetchClientsPaginate(page, search)}
                            rules={{ required: ' Field is required' }}
                            errors={errors.user_ids}
                          />
                        ) : null}
                      </Col>
                      {!pinnedWatch ? (
                        <>
                          <Col md={6} className={cx('pin-to-user__col')}>
                            <DatePicker
                              isRequired={false}
                              name="time_from"
                              control={control}
                              label="Wallet available date"
                              dateFormat="MMMM d, yyyy HH:mm"
                              showTimeInput
                              placeholderText={'Date from'}
                              rules={{ required: ' Field is required' }}
                              errors={errors.time_from}
                            />
                          </Col>
                          <Col md={6} className={cx('pin-to-user__col')}>
                            <DatePicker
                              isRequired={false}
                              name="time_to"
                              control={control}
                              label="Wallet available date"
                              dateFormat="MMMM d, yyyy HH:mm"
                              showTimeInput
                              placeholderText={'Date to'}
                              rules={{ required: ' Field is required' }}
                              errors={errors.time_to}
                            />
                          </Col>
                        </>
                      ) : null}
                      <Col md={12} className={cx('pin-to-user__col')}>
                        <CheckBox
                          control={control}
                          name="pinned"
                          type="checkbox"
                          label={'Pin to user'}
                          id="pinned"
                        />
                      </Col>
                    </Row>
                    <div className={cx('content-controls')}>
                      <Row>
                        <Col md={12}>
                          <div className={cx('controls__buttons')}>
                            <Button
                              buttonText="Cancel"
                              buttonType="outline"
                              type="button"
                              onClick={closeModal.bind(undefined, close)}
                            />
                            <Button
                              isLoading={isLoading}
                              buttonText={'OK'}
                              type="submit"
                              onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default PinToUserPopup;
