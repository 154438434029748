import React from 'react';
import classNames from 'classnames/bind';
import styles from './tableStyle.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import moment from 'moment';
import { getShortUuid } from 'utils/getShortUuid';
import { copyToBuffer } from 'utils/copyToBuffer';

const cx = classNames.bind(styles);

const TradingAccTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      ta_login,
      trading_account,
      client,
      name,
      date,
      credit,
      leverage,
      balance,
      amount_type,
      created_at,
      user,
      status,
      type,
      demo,
    } = item;

    return {
      trading_account: (
        <div className={cx('tradingAcc-table_td')}>
          <span className={cx('ta')}>
            {name ? (
              <span>
                {demo === 1 ? (
                  <>
                    <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}>DEMO</span>{' '}
                  </>
                ) : null}
                {name}
                <span className={cx(status)}>{status}</span>
              </span>
            ) : (
              '---'
            )}
          </span>
        </div>
      ),
      trading_login: (
        <div className={cx('tradingAcc-table_td')}>
          <span className={cx('ta')} onClick={() => copyToBuffer(ta_login, true)}>
            {ta_login ? <span>{ta_login}</span> : '---'}
          </span>
        </div>
      ),
      user: (
        <div className={cx('tradingAcc-table_td')}>
          <span className={cx('user')}>
            {user?.user_profile?.first_name} {user?.user_profile?.last_name}
          </span>
          <span className={cx('user_uuid')} onClick={() => copyToBuffer(user?.uuid, true)}>
            {getShortUuid(user?.uuid)}
          </span>
        </div>
      ),
      // name: (
      //   <div className={cx('tradingAcc-table_td')}>
      //     <>
      //       <span>{name}</span>
      //     </>
      //   </div>
      // ),
      created_at: (
        <div className={cx('tradingAcc-table_td', 'date')}>
          <span>{created_at && moment(created_at * 1000).format('DD.MM.YYYY')}</span>
          <span>{created_at && moment(created_at * 1000).format('HH:mm')}</span>
        </div>
      ),
      credit: (
        <div className={cx('tradingAcc-table_td ', 'amount')}>
          <span>
            {amount_type} {credit}
          </span>
        </div>
      ),
      leverage: (
        <div className={cx('tradingAcc-table_td ')}>
          <span>{leverage}</span>
        </div>
      ),
      balance: (
        <div className={cx('tradingAcc-table_td ', 'amount')}>
          <span>
            {amount_type} {balance}
          </span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('tradingAcc-table')}
      isSelected={false}
      getCellProps={(cellInfo) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default TradingAccTable;
