import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import DatePickerRange from 'components/ui/DatePickerRange';
import { getDepartamentList } from 'api/departament';
import { Col, Row } from 'react-bootstrap';
import { searchNotesListAll } from 'api/notes';
import moment from 'moment';
import NotesItem from './NotesItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isFormChange } from '../../../../../utils/isFormChange';
import { TableLinks, TableMeta } from 'models/Table';
import { LeadNote, LeadNotesFilter } from 'models/Leads/LeadNotes';
import { ReloadButton } from 'components/ui/ReloadButton';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { ListValue } from 'models/ListValue';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { Spinner } from 'components/ui/Spinner';
import cn from 'classnames';
import styles from '../../lead-page.module.scss';

const NotesTabs = () => {
  const [departamentList, setDepartamentList] = useState<ListValue[]>([]);
  const [notesList, setNotesList] = useState<LeadNote[]>([]);

  const [isListLoading, setIsListLoading] = useState(true);
  const [savedFilters, setSavedFilters] = useState<LeadNotesFilter>();

  const [meta, setMeta] = useState<TableMeta>();
  const [links, setLinks] = useState<TableLinks>();

  const { id } = useParams();

  const componentMounted = useRef(true);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      department_id: null,
      created_at: null,
    },
  });

  const onSubmit = (data) => {
    setSavedFilters(data);
    fetchNotesList(data);
  };

  const fetchNotesList = (data?, isNextCall?) => {
    // updateHeadNotes();
    setIsListLoading(true);

    const postData = {
      filter: {
        id,
        type: 'lead',
      },
    };

    if (data && data['department_id']) {
      postData.filter['department_ids'] = [data['department_id'].value];
    }

    if (data && data['created_at']) {
      postData.filter['created_at'] = {
        from: moment(data.created_at.from).format('YYYY-MM-DD'),
        to: moment(data.created_at.to).format('YYYY-MM-DD'),
      };
    }

    let searchParams = '';
    if (isNextCall) {
      searchParams = links && links.next ? `?${links.next.split('?').reverse()[0]}` : '';
    }

    searchNotesListAll(searchParams, postData)
      .then((res) => {
        if (res.status === 200) {
          if (isNextCall) {
            setNotesList((prev) => prev.concat(res.data.data));
          } else {
            setNotesList(res.data.data);
          }
          setMeta(res.data.meta);
          setLinks(res.data.links);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => setIsListLoading(false));
  };

  const fetchDepartamentList = () => {
    setIsListLoading(true);
    getDepartamentList()
      .then((res) => {
        if (res.status === 200) {
          setDepartamentList(
            res.data.data.map((el) => ({
              value: el.id,
              label: el.name,
            })),
          );
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    fetchDepartamentList();
    fetchNotesList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabTitle}>Notes</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4} lg={4} className="mb-3">
            <JokerSelect
              id="department_id"
              name="department_id"
              label="Department"
              control={control}
              options={departamentList}
            />
          </Col>
          <Col md={4} lg={4}>
            <DatePickerRange
              name="created_at"
              control={control}
              label="Creation date range"
              placeholder="Start date - End date"
            />
          </Col>
        </Row>
      </form>
      <TableLayout
        header={
          <div className={styles.notesButtons}>
            <ReloadButton isLoading={isListLoading} onClick={() => onSubmit(getValues())} />
            <Button
              buttonType="outline"
              isLoading={isListLoading}
              type="button"
              buttonText="Reset"
              disabled={!isFormChange(dirtyFields)}
              onClick={() => {
                reset({
                  department_id: null,
                  created_at: null,
                });
                fetchNotesList();
              }}
            />
            <Button
              buttonText="Apply"
              isLoading={isListLoading}
              onClick={handleSubmit(onSubmit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        }
      >
        <div className="position-relative">
          {isListLoading ? <Spinner /> : null}
          <div className={cn(isListLoading && 'p-0 blur')}>
            {notesList.length > 0 && meta && links ? (
              <InfiniteScroll
                dataLength={notesList.length}
                next={() => fetchNotesList(savedFilters, true)}
                hasMore={!!links.next}
                height={600}
                scrollableTarget="scrollableDiv"
                loader={<></>}
              >
                {notesList.map((el) => (
                  <NotesItem
                    key={el.id}
                    noteItem={el}
                    updateNotesList={() => {
                      fetchNotesList(savedFilters);
                    }}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <span className={styles.noItems}>No items</span>
            )}
          </div>
        </div>
      </TableLayout>
    </div>
  );
};

export default NotesTabs;
