import { ManagementOperator } from 'models/Management/Operators/Operator';
import styles from './operator-additional-info.module.scss';

type Props = {
  operator: ManagementOperator;
};

export const OperatorAdditionalInfo = ({ operator }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Departments</div>
      {operator.departments.length > 0 &&
        operator.roles_with_department.map((item) => {
          return (
            <div key={item.id} className={styles.row}>
              <div className={styles.department}>{item.department?.name}</div>
              <div className={styles.role}>{item.name}</div>
            </div>
          );
        })}
    </div>
  );
};
