import classNames from 'classnames/bind';

import Button from 'components/ui/Button';
import TablePagination from 'components/ui/TablePagination';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getTemplatesListMethod } from '../../api/templateRoles/index';
import TemplateTable from '../../components/ui/Table/TemplateTable/TemplateTable';
import { usePermission } from '../../utils/usePermission.tsx';
import Page from '../Page';
import { DashboardLayout } from '../UserView/DashboardLayout';
import RolesTab from './RolesTab/RolesTab';

import styles from './templates-page.module.scss';
import CreateTemplatesPopup from './TemplatesPopups/CreateTemplatesPopup';
import { getTablePageParams } from '../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { TemplateListEntry } from 'models/RolesAndPermissions/Templates/Template.js';
import { getDataAutoRefresh } from '../../api/update-page';
import { Row } from 'react-bootstrap';
import AutoRefresh from './AutoRefresh.tsx';
import { TableLayout } from 'components/ui/Table/TableLayout/TableLayout.tsx';
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import { TABLE_TEMPLATE_ROLES } from 'constants/table.tsx';

const cx = classNames.bind(styles);

const TemplateRolePage = () => {
  const today = new Date();
  const { permissionGiven: PermissionCreate } = usePermission('admin.role.create');
  const { permissionGiven: PermissionShowList } = usePermission('admin.role.index');
  const { permissionGiven: PermissionEdit } = usePermission('admin.role.edit');
  const { permissionGiven: PermissionDelete } = usePermission('admin.role.delete');

  const [templateList, setTemplateList] = useState<TemplateListEntry[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const [dataAutoRefresh, setDataAutoRefresh] = useState([]);
  const [columnsName, setColumnsName] = useState([]);
  const role = JSON.parse(localStorage.getItem('role'))[0];

  const componentMounted = useRef(true);
  document.title = 'Template roles';

  useEffect(() => {
    getDataAutoRefresh()
      .then((res) => {
        setColumnsName(Object.keys(res.data));
        setDataAutoRefresh(res.data);
      })
      .catch(console.log);
  }, [role]);

  useEffect(() => {
    getTemplateList();
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const getTemplateList = (options?, data?) => {
    setIsListLoading(true);

    getTemplatesListMethod(getTablePageParams(options, perPageCount, tableLinks))
      .then((res) => {
        if (componentMounted.current) {
          setTemplateList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const templateTable = useTableColumnOrder('TABLE_TEMPLATE_ROLES', TABLE_TEMPLATE_ROLES);

  return (
    <DashboardLayout>
      <Page>
        <div className={styles.tabs}>
          <Tabs>
            <TabList>
              <Tab>Templates</Tab>
              <Tab>Roles</Tab>
              {role === 'Super Admin' ? <Tab>Check Auto Refresh</Tab> : null}
            </TabList>
            <TabPanel>
              <div className={styles.tabContainer}>
                <div className={styles.tabTitle}>
                  {PermissionShowList ? tableMeta?.total || 0 : null} Templates
                  {PermissionCreate ? (
                    <CreateTemplatesPopup
                      updateComponent={() => getTemplateList()}
                      actionType={SALES_RULES_ACTION_TYPE.CREATE}
                      triggerBtn={
                        <Button
                          buttonText={'+ Add template'}
                          onClick={() => null}
                          buttonType={'outline'}
                        />
                      }
                    />
                  ) : null}
                </div>
                <TableLayout
                  className={styles.tableLayout}
                  header={
                    <Button
                      buttonStyle={{ marginBottom: 26 }}
                      buttonText="Reset column order"
                      onClick={templateTable.setDefaultColumnsOrder}
                    />
                  }
                >
                  <TemplateTable
                    PermissionEdit={PermissionEdit}
                    PermissionDelete={PermissionDelete}
                    data={PermissionShowList ? templateList : []}
                    perPage={templateList?.length}
                    showLoader={isListLoading}
                    columns={templateTable.columns}
                    saveColumnOrder={templateTable.saveColumnOrder}
                    modifyDataHandler={(callback) => getTemplateList()}
                  />
                </TableLayout>
              </div>
              {PermissionShowList ? (
                templateList?.length ? (
                  <TablePagination
                    currentPage={tableMeta?.current_page}
                    pagesLength={tableMeta?.last_page}
                    perPageChange={(value) => {
                      setPerPageCount(value);
                      getTemplateList({ perPage: value });
                    }}
                    goToSelectedPage={(page) => {
                      getTemplateList({ page });
                    }}
                    hidePageSelect={true}
                  />
                ) : null
              ) : null}
            </TabPanel>
            <TabPanel>
              <RolesTab
                PermissionShowList={PermissionShowList}
                templateList={templateList}
                PermissionEdit={PermissionEdit}
                PermissionCreate={PermissionCreate}
              />
            </TabPanel>
            {role === 'Super Admin' ? (
              <TabPanel>
                <div className={styles.tabContainer}>
                  <Row>
                    <div className={styles.tabTitle}>
                      {`${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`}
                    </div>
                  </Row>
                  {columnsName?.map((item, index) => {
                    return (
                      <Row key={index} className={cx('auto_refresh')}>
                        <AutoRefresh data={dataAutoRefresh} action={item} />;
                      </Row>
                    );
                  })}
                </div>
              </TabPanel>
            ) : null}
          </Tabs>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default TemplateRolePage;
