import { getTradingAccList } from 'api/tradingAcc';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import FormInput from 'components/ui/FormInput';
import { MultiSelect } from 'components/ui/MultiSelect';
import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

export const TradingActivityFields = ({ userId, tickerOpt, typeOpt }) => {
  const [accountsOpt, setAccountsOpt] = useState([]);

  const { control } = useFormContext();

  const getTradingAccOpt = () => {
    getTradingAccList(null, userId).then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.trading_account,
          label: opt.name,
        }));
        setAccountsOpt(opt);
      }
    });
  };

  useEffect(() => {
    getTradingAccOpt();
  }, []);

  return (
    <Row>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Accounts"
          control={control}
          id="trading_account"
          name="trading_account"
          options={accountsOpt}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Type"
          control={control}
          id="trade_side"
          name="trade_side"
          options={typeOpt}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Symbol"
          control={control}
          id="ticker"
          name="ticker"
          options={tickerOpt}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <Row>
          <Col md={6}>
            <FormInput
              name="volume.from"
              type="number"
              placeholder="0.00"
              min={0}
              label="Volume"
              control={control}
            />
          </Col>
          <Col md={6}>
            <FormInput
              name="volume.to"
              min={0}
              type="number"
              label="&nbsp;"
              placeholder="0.00"
              control={control}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <DatePickerRange
          name="open_at"
          control={control}
          label="Open time range"
          placeholder="From - To"
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <DatePickerRange
          name="close_at"
          control={control}
          label="Close time range"
          placeholder="From - To"
        />
      </Col>
    </Row>
  );
};
