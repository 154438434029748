import { Accordion, Col, Row } from 'react-bootstrap';
import FormInput from 'components/ui/FormInput';
import classNames from 'classnames/bind';
import { defaultDateTimeFormat, ModernDateRangeInput } from 'components/ui/ModernDateRangeInput';
import styles from '../client-filter.module.scss';
import JokerSelect from 'components/ui/JokerSelect';
import {
  ACQUISITION_SELECT_OPTIONS,
  ACTIVITY_SELECT_OPTIONS,
  FIRST_TIME_DEPOS_SELECT_OPTIONS,
  NOTES_SELECT_OPTIONS,
  PROFILE_STATUS_SELECT_OPTIONS,
} from 'constants/clients/filters.const';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { getEnableMT } from 'constants/selectors';
import { CountryOpt, DynamicFilterRequiredType } from 'models/Clients/ClientFilter';
import { ListValue } from 'models/ListValue';
import { Control, UseFormHandleSubmit, useWatch } from 'react-hook-form';
import { ModernDateTimeInputHeader } from 'components/ui/ModernDateRangeInput/ModernDateTimeInputHeader';
import PaginateSelect from 'components/ui/PaginateSelect';
import Button from 'components/ui/Button';
import { MultiSelect } from 'components/ui/MultiSelect';
import PaginateAllSelect from 'components/ui/PaginateAllSelect';

const cx = classNames.bind(styles);

type Props = {
  control: Control<any>;
  handleSubmit: UseFormHandleSubmit<any>;

  submit: (data?: any) => void;

  getDirtyFieldsLengthBalance: (arr: any[]) => boolean;
  getDirtyFieldsLength: () => number | any;
  resetBalance: () => void;
  resetActivity: () => void;
  resetTeam: () => void;
  resetStatuses: () => void;
  resetDates: () => any;
  resetForm: () => void;

  countryOpt: CountryOpt[];
  languageOpt: ListValue[];
  // sources: ListValue[];
  // referrals: ListValue[] | any;
  affiliate: ListValue[];
  assignStatusList: ListValue[] | any;
  dynamicFilterList: DynamicFilterRequiredType[];

  setSelectedDynamicFilter: (val: any[]) => void;
  desks: ListValue[];
  teams: ListValue[];
  operators: ListValue[];

  selectedDesks: any;
  setSelectedDesks: any;
  selectedTeams: any;
  setSelectedTeams: any;
  fetchTeamsList?: () => void;
  fetchReferralsList?: (
    page?: number,
    query?: string,
  ) => Promise<void | {
    data: any;
    total: number;
  }>;
  fetchSourceList: (
    page?: number,
    query?: string,
  ) => Promise<void | {
    data: any;
    total: number;
  }>;
  reloadFilters: boolean;
};

const SearchSectionAllFilters = (props: Props) => {
  const {
    control,
    handleSubmit,
    submit,
    getDirtyFieldsLengthBalance,
    resetBalance,
    countryOpt,
    resetActivity,
    // sources,
    // referrals,
    resetTeam,
    affiliate,
    assignStatusList,
    dynamicFilterList,
    resetStatuses,
    resetDates,
    resetForm,
    setSelectedDynamicFilter,
    getDirtyFieldsLength,
    languageOpt,
    desks,
    teams,
    operators,
    // selectedDesks,
    setSelectedDesks,
    // selectedTeams,
    setSelectedTeams,
    fetchReferralsList,
    fetchSourceList,
    reloadFilters,
  } = props;

  const ENABLE_MT = useSelector(getEnableMT);

  const sourcesWatch = useWatch({ name: 'sources', control });
  const referralsWatch = useWatch({ name: 'referrals', control });

  const hasAllSources = (sourcesWatch || [])[0]?.value === 'all';
  const hasAllReferrals = (referralsWatch || [])[0]?.value === 'all';

  const dirtyFields = {
    balance: getDirtyFieldsLengthBalance(['number_deposits', 'balance']),
    statuses: getDirtyFieldsLengthBalance([
      'assign_status',
      'desk_type',
      'profile_status',
      'kyc',
      'retention',
      'sales',
    ]),
    general: getDirtyFieldsLengthBalance(['countryIds', 'languageIds', 'activity']),
    belonging: getDirtyFieldsLengthBalance([
      'first_time_deposit',
      'affiliateIds',
      'operatorIds',
      'teamIds',
      'deskIds',
      'notes',
      'sources',
      'referrals',
    ]),
    dates: getDirtyFieldsLengthBalance([
      'created_at',
      'ftd_range',
      'note_first',
      'last_trade',
      'note_last',
      'last_login',
      'updated_at',
      // 'limit',
    ]),
  };

  return (
    <div>
      <Row className="mb-4">
        <Accordion alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Balance & Number of deposits{' '}
              <span
                className={cx('dirtyIndicator', dirtyFields.balance && 'dirtyIndicator__active')}
              />
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md={2} className="position-relative">
                  <FormInput
                    name="balance.from"
                    type="text"
                    inputmode="numeric"
                    placeholder="0"
                    min={0}
                    label="Balance"
                    pattern="[0-9]*"
                    control={control}
                  />

                  <span className={cx('range-divider')}>-</span>
                </Col>
                <Col md={2}>
                  <FormInput
                    name="balance.to"
                    min={0}
                    type="text"
                    inputmode="numeric"
                    label="&nbsp;"
                    placeholder="0"
                    pattern="[0-9]*"
                    control={control}
                  />
                </Col>
                <Col md={2} className="position-relative">
                  <FormInput
                    name="number_deposits.from"
                    type="text"
                    inputmode="numeric"
                    //labelStyle={{ width: isMobile() ? '200%' : '300%' }}
                    placeholder="0"
                    min={0}
                    label="Number of deposits"
                    control={control}
                  />
                  <span className={cx('range-divider')}>-</span>
                </Col>
                <Col md={2}>
                  <FormInput
                    name="number_deposits.to"
                    min={0}
                    type="text"
                    inputmode="numeric"
                    label="&nbsp;"
                    placeholder="0"
                    control={control}
                  />
                </Col>
                <Col className="d-flex gap-4 pt-3">
                  <Button
                    buttonText="Reset"
                    type="submit"
                    isLoading={reloadFilters}
                    buttonType="outline"
                    onClick={resetBalance}
                    //disabled={!getDirtyFieldsLength()}
                  />
                  <Button
                    buttonText="Apply"
                    type="submit"
                    isLoading={reloadFilters}
                    onClick={handleSubmit(submit)}
                    disabled={!dirtyFields.balance}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              General information{' '}
              <span
                className={cx('dirtyIndicator', dirtyFields.general && 'dirtyIndicator__active')}
              />
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <JokerSelect
                    label="Activity"
                    control={control}
                    id="Activity"
                    name="activity"
                    options={ACTIVITY_SELECT_OPTIONS}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <MultiSelect
                    label="Languages"
                    control={control}
                    id="languages"
                    name="languageIds"
                    options={languageOpt}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <MultiSelect
                    label="Country"
                    control={control}
                    id="country"
                    name="countryIds"
                    options={countryOpt}
                  />
                </Col>
                <Col className="d-flex gap-4 py-3">
                  <Button
                    buttonType="outline"
                    buttonText="Reset"
                    type="submit"
                    isLoading={reloadFilters}
                    onClick={resetActivity}
                    //disabled={!getDirtyFieldsLength()}
                  />
                  <Button
                    buttonText="Apply"
                    type="submit"
                    isLoading={reloadFilters}
                    onClick={handleSubmit(submit)}
                    disabled={!dirtyFields.general}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Belonging{' '}
              <span
                className={cx('dirtyIndicator', dirtyFields.belonging && 'dirtyIndicator__active')}
              />
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <MultiSelect
                    label="Desks"
                    control={control}
                    id="desks"
                    name="deskIds"
                    options={desks}
                    onChange={(value) => {
                      setSelectedDesks(value);
                    }}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <MultiSelect
                    label="Teams"
                    control={control}
                    id="teams"
                    name="teamIds"
                    options={teams}
                    onChange={(value) => {
                      setSelectedTeams(value);
                    }}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <MultiSelect
                    label="Operators"
                    control={control}
                    id="operators"
                    name="operatorIds"
                    options={operators}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <MultiSelect
                    label="Affiliates"
                    control={control}
                    id="affiliate"
                    name="affiliateIds"
                    options={affiliate}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <JokerSelect
                    label="First time deposit"
                    control={control}
                    id="first-time-deposit"
                    name="first_time_deposit"
                    options={FIRST_TIME_DEPOS_SELECT_OPTIONS}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <JokerSelect
                    label="Notes"
                    control={control}
                    id="notes"
                    name="notes"
                    options={NOTES_SELECT_OPTIONS}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <PaginateAllSelect
                    isMulti
                    label="Source"
                    control={control}
                    id="sources"
                    name="sources"
                    onChange={fetchSourceList}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <PaginateSelect
                    isMulti
                    isDisabled={!hasAllSources}
                    label="Exclude sources"
                    control={control}
                    id="sources_exclude"
                    name="sources_exclude"
                    onChange={fetchSourceList}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <PaginateAllSelect
                    isMulti
                    label="Referral"
                    control={control}
                    id="referrals"
                    name="referrals"
                    onChange={fetchReferralsList}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <PaginateSelect
                    isMulti
                    isDisabled={!hasAllReferrals}
                    label="Exclude referrals"
                    control={control}
                    id="referrals_exclude"
                    name="referrals_exclude"
                    onChange={fetchSourceList}
                  />
                </Col>
                <Col className="d-flex gap-4 py-3">
                  <Button
                    buttonText="Reset"
                    type="submit"
                    isLoading={reloadFilters}
                    buttonType="outline"
                    onClick={() => {
                      resetTeam();
                      setSelectedDesks([]);
                      setSelectedTeams([]);
                    }}
                    //disabled={!getDirtyFieldsLength()}
                  />
                  <Button
                    buttonText="Apply"
                    type="submit"
                    isLoading={reloadFilters}
                    onClick={handleSubmit(submit)}
                    disabled={!dirtyFields.belonging}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Statuses{' '}
              <span
                className={cx('dirtyIndicator', dirtyFields.statuses && 'dirtyIndicator__active')}
              />
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <JokerSelect
                    label="Profile status"
                    control={control}
                    id="profile_status"
                    name="profile_status"
                    options={PROFILE_STATUS_SELECT_OPTIONS}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <JokerSelect
                    label="Acquisition status"
                    control={control}
                    id="desk_type"
                    name="desk_type"
                    options={ACQUISITION_SELECT_OPTIONS}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="mb-3">
                  <JokerSelect
                    label="Assign status"
                    control={control}
                    id="assign_status"
                    name="assign_status"
                    options={assignStatusList}
                  />
                </Col>
                {dynamicFilterList.map((item, index) => {
                  return (
                    <Col key={index} xl={3} lg={4} md={6} className="mb-3">
                      <MultiSelect
                        label={item.label}
                        control={control}
                        id={item.value}
                        name={`${item.value}`}
                        options={item.options}
                      />
                    </Col>
                  );
                })}
                <Col className="d-flex gap-4 py-3">
                  <Button
                    buttonText="Reset"
                    type="submit"
                    isLoading={reloadFilters}
                    buttonType="outline"
                    onClick={resetStatuses}
                    //disabled={!getDirtyFieldsLength()}
                  />
                  <Button
                    buttonText="Apply"
                    type="submit"
                    isLoading={reloadFilters}
                    onClick={handleSubmit(submit)}
                    disabled={!dirtyFields.statuses}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Filters by date{' '}
              <span
                className={cx('dirtyIndicator', dirtyFields.dates && 'dirtyIndicator__active')}
              />
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Row>
                  <Col xl={4} lg={6} md={6} className="mb-3">
                    <ModernDateRangeInput
                      name="created_at"
                      control={control}
                      label="Registration date range"
                      placeholder="From - To"
                      dateFormat={defaultDateTimeFormat}
                      allowToChangeViaInput={true}
                      renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                        <ModernDateTimeInputHeader
                          {...props}
                          from={props.from}
                          to={props.to}
                          onTimeChangeHandler={onTimeChangeHandler}
                          showRange
                        />
                      )}
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} className="mb-3">
                    <ModernDateRangeInput
                      name="ftd_range"
                      control={control}
                      label="FTD date range"
                      placeholder="From - To"
                      dateFormat={defaultDateTimeFormat}
                      allowToChangeViaInput={true}
                      renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                        <ModernDateTimeInputHeader
                          {...props}
                          from={props.from}
                          to={props.to}
                          onTimeChangeHandler={onTimeChangeHandler}
                          showRange
                        />
                      )}
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} className="mb-3">
                    <ModernDateRangeInput
                      name="note_first"
                      control={control}
                      label="First note date"
                      placeholder="From - To"
                      dateFormat={defaultDateTimeFormat}
                      allowToChangeViaInput={true}
                      renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                        <ModernDateTimeInputHeader
                          {...props}
                          from={props.from}
                          to={props.to}
                          onTimeChangeHandler={onTimeChangeHandler}
                          showRange
                        />
                      )}
                    />
                  </Col>
                  {ENABLE_MT ? (
                    <Col xl={4} lg={6} md={6} className="mb-3">
                      <ModernDateRangeInput
                        name="last_trade"
                        control={control}
                        label="Last trade date"
                        placeholder="From - To"
                        dateFormat={defaultDateTimeFormat}
                        allowToChangeViaInput={true}
                        renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                          <ModernDateTimeInputHeader
                            {...props}
                            from={props.from}
                            to={props.to}
                            onTimeChangeHandler={onTimeChangeHandler}
                            showRange
                          />
                        )}
                      />
                    </Col>
                  ) : null}
                  <Col xl={4} lg={6} md={6} className="mb-3">
                    <ModernDateRangeInput
                      name="note_last"
                      control={control}
                      label="Last note date"
                      placeholder="From - To"
                      dateFormat={defaultDateTimeFormat}
                      allowToChangeViaInput={true}
                      renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                        <ModernDateTimeInputHeader
                          {...props}
                          from={props.from}
                          to={props.to}
                          onTimeChangeHandler={onTimeChangeHandler}
                          showRange
                        />
                      )}
                    />
                  </Col>
                  <Col xl={4} lg={6} md={6} className="mb-3">
                    <ModernDateRangeInput
                      name="last_login"
                      control={control}
                      label="Last login date"
                      placeholder="From - To"
                      dateFormat={defaultDateTimeFormat}
                      allowToChangeViaInput={true}
                      renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                        <ModernDateTimeInputHeader
                          {...props}
                          from={props.from}
                          to={props.to}
                          onTimeChangeHandler={onTimeChangeHandler}
                          showRange
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={6} md={6} className="mb-3">
                    <ModernDateRangeInput
                      name="updated_at"
                      control={control}
                      label="Last modification"
                      placeholder="From - To"
                      dateFormat={defaultDateTimeFormat}
                      allowToChangeViaInput={true}
                      renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                        <ModernDateTimeInputHeader
                          {...props}
                          from={props.from}
                          to={props.to}
                          onTimeChangeHandler={onTimeChangeHandler}
                          showRange
                        />
                      )}
                    />
                  </Col>
                  <Col className="d-flex gap-4 py-3">
                    <Button
                      buttonType="outline"
                      buttonText="Reset"
                      type="submit"
                      isLoading={reloadFilters}
                      onClick={resetDates}
                      //disabled={!getDirtyFieldsLength()}
                    />
                    <Button
                      buttonText="Apply"
                      type="submit"
                      isLoading={reloadFilters}
                      onClick={handleSubmit(submit)}
                      disabled={!dirtyFields.dates}
                    />
                  </Col>
                </Row>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>

      <Row>
        <Col className="d-flex gap-4 justify-content-start">
          <Button
            buttonType="outline"
            buttonText="Reset all filters"
            type="reset"
            isLoading={reloadFilters}
            onClick={() => {
              resetForm();
              setSelectedDesks([]);
              setSelectedTeams([]);
              setSelectedDynamicFilter([]);
            }}
            disabled={!getDirtyFieldsLength()}
          />
          <Button
            buttonText="Apply all filters"
            type="submit"
            isLoading={reloadFilters}
            onClick={handleSubmit(submit)}
            disabled={!getDirtyFieldsLength()}
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(SearchSectionAllFilters);
