import React from 'react';
import classNames from 'classnames/bind';
import styles from './feedTable.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';

import { TABLE_FEED_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';
import SubRowComponent from './SubRowComponent';
import useTableColumnOrder from 'utils/useTableColumnOrder';

const cx = classNames.bind(styles);

const FeedTable = ({ data, perPage, showLoader, modifyDataHandler, colSortCheck, userId }) => {
  const { columns, saveColumnOrder } = useTableColumnOrder(
    'TABLE_FEED_COLUMNS_NAMES',
    TABLE_FEED_COLUMNS_NAMES,
  );

  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item) => {
    const { create_at, description, event, id } = item;

    return {
      created_at: (
        <div key={id} className={cx('feed-table_th')}>
          <div className={cx('event')}>
            <div className={cx('event-icon', event)}>
              {item?.causer?.first_name?.slice(0, 1)}
              {item?.causer?.last_name ? item?.causer?.last_name?.slice(0, 1) : ''}
            </div>
            <div className={cx('event-descr')}>
              <div className={cx('description')}>{description}</div>
              <div className={cx('causer')}>
                {item?.causer?.first_name} {item?.causer?.last_name ? item?.causer?.last_name : ''}
              </div>
              <div className={cx('time')}>
                {moment(create_at * 1000).format('DD.MM.YYYY HH:mm')}
              </div>
              {(item?.event === 'log_in' || item?.event === 'created') && item?.ip && (
                <div className={cx('time')}>{item.ip}</div>
              )}
            </div>
          </div>

          <SubRowComponent row={item} />
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      colSortCheck={colSortCheck}
      className={cx('feed-table')}
      isSelected={false}
      isExpanded={true}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default FeedTable;
