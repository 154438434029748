import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './clientsPopups.mudule.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import PasswordInput from 'components/ui/PasswordInput/PasswordInput';
import { PASSWORD_INPUT_VALIDATION } from 'constants/validators';
import { changePasswordForClient } from 'api/clients';

const cx = classNames.bind(styles);

const ClientChangePasswordPopup = ({ triggerBtn, updateComponent, userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };
  const new_password = watch('password');

  const onSubmit = (close, data) => {
    setIsLoading(true);

    changePasswordForClient(userId, data)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Password changed successfully',
            timeOut: 3000,
          });
          closeModal(close);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Change password'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <PasswordInput
                            name="password"
                            type="password"
                            label="New password"
                            control={control}
                            rules={{
                              required: 'Field is required',
                              validate: PASSWORD_INPUT_VALIDATION,
                            }}
                            errors={errors.password}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-3">
                          <PasswordInput
                            name="password_confirmation"
                            type="password"
                            label="Confirm password"
                            control={control}
                            rules={{
                              required: 'Field is required',
                              validate: {
                                ...PASSWORD_INPUT_VALIDATION,
                                compare: (value) =>
                                  value === new_password ||
                                  'The password confirmation does not match.',
                              },
                            }}
                            errors={errors.password_confirmation}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          type="button"
                          disabled={isLoading}
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          buttonText={'Change password'}
                          type="submit"
                          onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default ClientChangePasswordPopup;
