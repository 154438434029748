import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { getEnableMT, getEnablePayments, getEnableCCWithdraw } from 'constants/selectors';
import { getPaymentsColumns } from 'constants/table';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const paymentsDefaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  payment_method: [],
  payment_type: [],
  transaction_type: [],
  transaction_status: [],
  status_updated_at: '',
  deskIds: [],
  teamIds: [],
  operatorIds: [],
  affiliateIds: [],
  currencyIds: [],
  accountType: { value: 'any', label: 'Any' },
  first_time_deposit: { value: 'any', label: 'Any' },
  amount: {
    from: '',
    to: '',
  },
  created_at: '',
  custom_filters: '',
};

export const usePaymentsPage = () => {
  const ENABLE_MT = useAppSelector(getEnableMT);
  const ENABLE_PAYMENT = useAppSelector(getEnablePayments);
  const ENABLE_CC_WITHDRAW = useAppSelector(getEnableCCWithdraw);

  const tableHeader = useMemo(
    () => getPaymentsColumns(ENABLE_MT, ENABLE_PAYMENT, ENABLE_CC_WITHDRAW),
    [ENABLE_MT, ENABLE_PAYMENT, ENABLE_CC_WITHDRAW],
  );

  const table = useTableColumnOrder('PAYMENT_TABLE', tableHeader);

  const form = useForm({ reValidateMode: 'onChange', defaultValues: paymentsDefaultValues });

  return { form, table, tableHeader };
};
