import { TABLE_SALES_RULES_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const managementSalesDefaultValues = {
  language_ids: [],
  country_ids: [],
  operator_ids: [],
  partner_ids: [],
  query: '',
};

export const useSalesRulesPage = () => {
  const table = useTableColumnOrder(
    'TABLE_SALES_RULES_COLUMNS_NAMES',
    TABLE_SALES_RULES_COLUMNS_NAMES,
  );

  const form = useForm({ reValidateMode: 'onChange', defaultValues: managementSalesDefaultValues });

  return { table, form };
};
