import { useEffect, useState } from 'react';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import FormInput from 'components/ui/FormInput';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { MultiSelect } from 'components/ui/MultiSelect';
import { getEnableMT } from 'constants/selectors';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'store';
import { CURRENCY_OPTIONS } from 'constants/clients/filters.const';
import { getTransactionsTypeMethods } from 'api/payments';

import styles from './payments-fields.module.scss';

export const PaymentsFields = ({ transactionTypeOpt }) => {
  const [paymentMethodOpt, setPaymentMethodOpt] = useState([]);

  const ENABLE_MT = useAppSelector(getEnableMT);

  const { control } = useFormContext();

  const fetchPaymentsMethodList = () => {
    getTransactionsTypeMethods().then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.payment_filter.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setPaymentMethodOpt(opt);
      }
    });
  };

  useEffect(() => {
    fetchPaymentsMethodList();
  }, []);

  return (
    <Row>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Payment method"
          control={control}
          id="payment_method"
          name="payment_method"
          options={paymentMethodOpt}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Transaction type"
          control={control}
          id="transaction_type"
          name="transaction_type"
          options={transactionTypeOpt}
        />
      </Col>
      {ENABLE_MT ? (
        <>
          <Col xl={3} lg={4} md={6} className="mb-3">
            <DatePickerRange
              name="status_updated_at"
              control={control}
              label="Status modification date range"
              placeholder="From - To"
            />
          </Col>
          <Col xl={3} lg={4} md={6} className="mb-3">
            <MultiSelect
              label="Currency"
              control={control}
              id="currencyIds"
              name="currencyIds"
              options={CURRENCY_OPTIONS}
            />
          </Col>
          <Col xl={3} lg={4} md={6} className="mb-3">
            <MultiSelect
              label="Transaction status"
              control={control}
              id="transaction_status"
              name="transaction_status"
              options={[
                {
                  value: 'pending',
                  label: 'Pending',
                },
                {
                  value: 'completed',
                  label: 'Completed',
                },
                {
                  value: 'canceled',
                  label: 'Canceled',
                },
              ]}
            />
          </Col>
        </>
      ) : null}
      <Col xl={3} lg={4} md={6} className="mb-3">
        <JokerSelect
          label="First time deposit"
          control={control}
          id="first_time_deposit"
          name="first_time_deposit"
          options={[
            { value: 'any', label: 'Any' },
            {
              label: 'No',
              value: 'no',
            },
            {
              label: 'Yes',
              value: 'yes',
            },
          ]}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <Row>
          <Col md={6}>
            <FormInput
              name="amount.from"
              type="number"
              placeholder="0.00"
              min={0}
              label="Amount"
              control={control}
            />
          </Col>
          <Col md={6}>
            <FormInput
              name="amount.to"
              min={0}
              type="number"
              label="&nbsp;"
              placeholder="0.00"
              control={control}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <DatePickerRange
          name="created_at"
          control={control}
          label="Creation date range"
          placeholder="From - To"
        />
      </Col>
    </Row>
  );
};
