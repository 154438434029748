import { PlusCircle } from "phosphor-react";
import { useAppDispatch } from "../../../../store";
import { openJokerModal } from "../../../../store/slice/modalSlice";
import { CREATE_CHAT_MODAL_KEY, CreateChatModal } from "../modals/CreateChatModal/CreateChatModal";
import { ThreadTypes } from "../../../../models/Chat";

import './ChatCreateButton.scss';

type ChatCreateButtonProps = {type: ThreadTypes};

export const ChatCreateButton = ({ type }: ChatCreateButtonProps) => {
    const dispatch = useAppDispatch();
    const openModal = () => dispatch(openJokerModal({ key: CREATE_CHAT_MODAL_KEY, data: { type } }))

    return (
        <>
            <div className="new-chat-button" onClick={openModal}><PlusCircle size={20} color={"var(--white)"}/></div>
            <CreateChatModal />
        </>
    )
}