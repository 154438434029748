import { TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export type TeamsFilterType = {
  name: string;
  query: string;
  office_ids: any[];
  desk_ids: any[] | string;
};

export const teamsFilterDefaultValues = {
  name: '',
  query: '',
  office_ids: [],
  desk_ids: [],
};

export const useTeamsPage = () => {
  const table = useTableColumnOrder(
    'TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES',
    TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES,
    true,
  );

  const form = useForm<TeamsFilterType>({
    reValidateMode: 'onChange',
    defaultValues: teamsFilterDefaultValues,
  });

  return { table, form };
};
