import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import Button from 'components/ui/Button';
import { useFormContext } from 'react-hook-form';
import { PaymentFilter } from 'models/Payments/Filter';
import { clearEmptyFilter } from 'utils/clearEmptyFilter.ts';
import { ReloadButton } from 'components/ui/ReloadButton/ReloadButton.tsx';
import { preparePaymentsFiltersData } from '../utils.ts';
import { SearchBar } from 'components/ui/SearchBar/index.ts';
import styles from './filter.module.scss';

type Props = {
  onSubmit: (data: PaymentFilter) => void;
  reloadFilters: boolean;
  queryMessage: string;
  setDefaultColumnsOrder: () => void;
};

const PaymentsFilter = (props: Props) => {
  const { onSubmit, reloadFilters, queryMessage, setDefaultColumnsOrder } = props;

  const paymentsSearchOptions = useSearchCategoriesOptions('depositTransaction');

  const { handleSubmit, reset, watch, getValues, control, formState } = useFormContext();
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    paymentsSearchOptions,
  );

  const submit = (data) => {
    const prepeared = preparePaymentsFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };

    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset({ custom_filters: '' });
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={styles.form}
    >
      <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />

      <SearchBar
        className={styles.searchBar}
        queryMessage={queryMessage}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: dynamicSearchPlaceholder,
          onSearch: () => submit(getValues()),
          onChange: (v) => !v?.length && submit(getValues()),
        }}
        selectProps={{
          id: 'query_category',
          name: 'query_category',
          control,
          options: paymentsSearchOptions,
          onSelectChange: () => {
            const { query } = getValues();
            if (query) submit(getValues());
          },
        }}
      />
      <div className={styles.buttons}>
        <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
        <Button
          buttonText="Reset"
          buttonType="outline"
          isLoading={reloadFilters}
          type="reset"
          onClick={() => resetForm()}
          // disabled={!getDirtyFieldsLength()}
        />
        <Button
          buttonText="Apply"
          isLoading={reloadFilters}
          type="submit"
          onClick={handleSubmit(submit)}
          disabled={!getDirtyFieldsLength()}
        />
      </div>
    </form>
  );
};

export default PaymentsFilter;
