import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import Popup from 'reactjs-popup';
import styles from './lead-upload-log-popup.module.scss';
import Button from 'components/ui/Button/Button';
import PopupTemplate from 'components/ui/PopupTemplate';
import { Container, Row } from 'react-bootstrap';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  onCloseModal?: () => void;
  logsData: any[];
};

const LeadUploadLogPopup = (props: Props) => {
  const { triggerBtn, onCloseModal, logsData } = props;

  const closeModal = (close): void => {
    close();
    onCloseModal();
  };

  const renderError = () => {
    return (
      <ul className={cx('errorsList')}>
        {logsData &&
          logsData.map((el) => (
            <li key={el.id} className={cx('errorsList__item')}>
              <div>Row number: {el.row_number}</div>
              <div>
                {el && el.import_data ? el.import_data.name : ''}{' '}
                {el && el.import_data ? el.import_data.surname : ''}
              </div>
              <div>{el && el.import_data ? el.import_data.email : ''}</div>
              <ul className={cx('errorsList__item-list')}>
                <li>
                  {el && el.error_data
                    ? Object.keys(el.error_data).map((key: string) => (
                        <>
                          <span className={cx('fieldName')}>{key} : </span>{' '}
                          <span className={cx('fieldValue')}>
                            {el.error_data[key].map((el) => el)}
                          </span>
                          <br />
                        </>
                      ))
                    : null}
                </li>
              </ul>
            </li>
          ))}
      </ul>
    );
  };

  useEffect(() => {
    renderError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsData]);

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Leads upload errors log'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form')}>
                  <Container>
                    <Row>{renderError()}</Row>
                  </Container>
                  <div className={cx('content-controls')}>
                    <div className={cx('controls__buttons')}>
                      <Button
                        buttonText="Close"
                        buttonType="outline"
                        type="button"
                        onClick={closeModal.bind(undefined, close)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default LeadUploadLogPopup;
