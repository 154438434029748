import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import moment from 'moment';
import DeleteDynamicStatusesPopup from 'pages/SettingsLk/dynamicStatuses/popups/DeleteDynamicStatusesPopup';
import EditStatusPopup from 'pages/SettingsLk/dynamicStatuses/popups/EditStatusPopup';
import ChangeColorPopup from 'pages/SettingsLk/dynamicStatuses/popups/ChangeColorPopup.tsx';
import { DynamicStatus } from 'models/SettingsLK/DynamicStatuses/DynamicStatusesPage.ts';
import { Icon } from 'components/ui/Icon';
import { ITable } from 'models/components/Table/Table.ts';

const cx = classNames.bind(styles);

interface IDynamicStatusesTableProps {
  permission: boolean;
  permissionChangeColor?: boolean;
  modifyDataHandler: (v?: string) => void;
  data: DynamicStatus[];
  type: string;
  columns: ITable['columns'];
  saveColumnOrder: (newColumnOrder: any) => void;
}

const DynamicStatusesTable: FC<IDynamicStatusesTableProps> = ({
  data,
  modifyDataHandler,
  type,
  permission,
  permissionChangeColor,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, permissionChangeColor],
  );
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, key, translate, created_at, color_status, updated_at, active } = item;

    return {
      key: (
        <div className={cx('rule__name')}>
          <span>{key}</span>
        </div>
      ),
      translate: (
        <div className={cx('rule__name')}>
          <span>{translate}</span>
        </div>
      ),
      type: (
        <div className={cx('rule__name')}>
          <span>{type}</span>
        </div>
      ),
      active: (
        <div className={cx('rule__name')}>
          <span>{active ? 'true' : 'false'}</span>
        </div>
      ),
      created_at: (
        <div className={cx('rule__name')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      updated_at: (
        <div className={cx('rule__name')}>
          <span>{moment.unix(updated_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(updated_at).format('HH:mm')}</span>
        </div>
      ),
      change_color: (
        <div className={cx('rule__color')}>
          <div
            style={{ backgroundColor: color_status || 'var(--main-color)' }}
            className={cx('rule__color-circle')}
          ></div>
          {permissionChangeColor ? (
            <ChangeColorPopup
              rule={item}
              triggerBtn={<Icon name="pipette" size={20} color="var(--grey-blue)" />}
              updateComponent={modifyDataHandler}
            />
          ) : null}
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          {permission ? (
            <EditStatusPopup
              dataItem={item}
              triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
              updateComponent={modifyDataHandler}
            />
          ) : null}
          <DeleteDynamicStatusesPopup
            rule={item}
            triggerBtn={<Icon name="trash" size={20} color="var(--red)" />}
            updateComponent={modifyDataHandler}
          />
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={1000000}
      // showLoader={showLoader}
      // onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo) => {
        const { row } = cellInfo;
        const odd = row.index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default DynamicStatusesTable;
