import React from 'react';
import cn from 'classnames';

import styles from './page.module.scss';

type Props = {
  title?: string | React.ReactNode;
  styles?: React.CSSProperties;
  children?: React.ReactElement;
  isStickyTitle?: boolean;
};

const getTitle = (title: Props['title'], isSticky: Props['isStickyTitle']) => {
  if (typeof title === 'string') {
    return <h2 className={cn(styles.title, isSticky && styles.sticky)}>{title}</h2>;
  }

  return title ? <div className={cn(styles.title, isSticky && styles.sticky)}>{title}</div> : null;
};

const Page = ({ children, title, isStickyTitle }: Props) => {
  return (
    <div className={styles.page}>
      {getTitle(title, isStickyTitle)}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Page;
