import { useEffect } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';

type Props = {
  location: Location;
  navigate: NavigateFunction;
};

export const useOperatorAutologin = ({ location, navigate }: Props) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      localStorage.setItem('token', token);
      navigate('/search-clients');
    }
  }, [location]);
};
