import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { findClients } from 'api/clients';
import moment from 'moment';
import { fullDateTimeFormat } from 'components/ui/ModernDateRangeInput';
import ClientsTable from 'components/ui/Table/ClientsTable';
import TablePagination from 'components/ui/TablePagination';
import { usePermission } from 'utils/usePermission';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import ClientFilter from './ClientFilter/ClientFilter';
import styles from './search-clients.module.scss';
import { notify } from 'utils/notify';
import { useSelector } from 'react-redux';
import { getStatusProgress } from 'constants/selectors';
import { ProductionClientFilters } from 'models/Clients/ClientSearch';
import { Client } from 'models/Clients/Clients';
import { Sort } from 'models/Sort';
import { TableLinks, TableMeta } from 'models/Table';
import ProgressBarSection from './ProgressBarSection.tsx';
import { useAppDispatch } from 'store';
import { useCheckAutoRefresh } from 'utils/useCheckAutoRefresh.tsx';
import { clearEmptyFilter } from 'utils/clearEmptyFilter';
import { DynamicFilterRequiredType } from 'models/Clients/ClientFilter';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { toggleScrollToTop } from 'store/slice/tableSlice.ts';
import { SearchClientsPageTitle } from './Title/SearchClientsPageTitle';
import { useSearchClientsPage } from './useSearchClientsPage.ts';
import { TableLayout } from 'components/ui/Table/TableLayout/TableLayout.tsx';
import { SearchClientsTableLayoutHeader } from './SearchClientsTableLayoutHeader.tsx';
import { FormProvider } from 'react-hook-form';

const cx = classNames.bind(styles);

const SORTED_FIELD_NAME = {
  client: 'name',
  // last_activity: 'last_login', TODO: NO BACKEND REALIZATION;
  country: 'country',
  balance: 'balance',
  deposit: 'number_deposits',
  registration: 'created_at',
  last_note: 'note_last',
};

const title = 'Search Clients';

const SearchClients = () => {
  const { permissionGiven: PermissionListClient } = usePermission('admin.client.list');

  const status = useSelector(getStatusProgress);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(false);
  const [clientsList, setClientsList] = useState<Client[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedClientsType, setSelectedClientsType] = useState<string[]>([]);
  const [filters, setFilters] = useState<ProductionClientFilters | null>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta | null>(null);
  const [tableLinks, setTableLinks] = useState<TableLinks | null>(null);
  const [perPageCount, setPerPageCount] = useState(200);
  const [sort, setSort] = useState<Sort>(undefined);
  const checkAutoRefresh = useCheckAutoRefresh('Page Search Clients');
  const [dynamicFilterList, setDynamicFilterList] = useState<DynamicFilterRequiredType[]>([]);
  const [queryMessage, setQueryMessage] = useState('');

  const componentMounted = useRef(false);

  usePageTitle(title);

  useEffect(() => {
    if (PermissionListClient) {
      componentMounted.current = true;
      getClientsList();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionListClient]);

  const changeLimits = (val) => {
    setPerPageCount(val);
  };

  const getClientsList = (pageParams?, filtersData?) => {
    setIsListLoading(true);
    findClients(getTablePageParams(pageParams), filtersData)
      .then((res) => {
        if (componentMounted.current) {
          setClientsList(res?.data?.data);
          setTableMeta(res?.data?.meta);
          setTableLinks(res?.data?.links);
          setPerPageCount(res?.data?.meta?.per_page);
          setQueryMessage(res.data.message);
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const prepareFiltersData = (data) => {
    const dynamicStatuses = {};
    for (const item of data['dynamic_statuses']) {
      if (item.type === 'select') {
        dynamicStatuses[item.value] = getFilterValue(item[item.value]);
      }
      if (item.type === 'user') {
        dynamicStatuses[item.value] = getFilterValue(item[item.value]);
      }
    }
    let dynamic_default = { ...getDynamicStatusKey(data) };

    const getNumberRange = (data) => {
      if (!data) {
        return;
      }

      if (!data.from && !data.to) {
        return;
      }

      const res = { from: data.from !== '' ? Number(data.from) : 0 };

      if (data.to) {
        res['to'] = +data.to;
      }
      return res;
    };

    const getPaginateSelectValue = (data, key) => {
      if (data[key]) {
        const all = data[key][0]?.value === 'all';

        return {
          all,
          includes: !all ? data[key]?.map((o) => o.value) : [],
          excludes: all ? data[`${key}_exclude`]?.map((o) => o.value) : [],
        };
      }
    };

    const postData = {
      filter: {
        languageIds: getFilterValue(data['languageIds']),
        countryIds: getFilterValue(data['countryIds']),
        deskIds: getFilterValue(data['deskIds']),
        teamIds: getFilterValue(data['teamIds']),
        first_time_deposit: getFilterValue(data['first_time_deposit']),
        assign_status: getFilterValue(data['assign_status']),
        profile_status: getFilterValue(data['profile_status']) && [
          getFilterValue(data['profile_status']),
        ],
        desk_type: getFilterValue(data['desk_type']) && [getFilterValue(data['desk_type'])],
        activity: getFilterValue(data['activity']),
        operatorIds: getFilterValue(data['operatorIds']),
        affiliateIds: getFilterValue(data['affiliateIds']),
        notes: getFilterValue(data['notes']),
        referrals: getPaginateSelectValue(data, 'referrals'),
        sources: getPaginateSelectValue(data, 'sources'),
        // referrals: getFilterValue(data['referrals']),
        // sources: getFilterValue(data['sources']),
        balance: getNumberRange(data['balance']),
        number_deposits: getNumberRange(data['number_deposits']),
        last_trade: formatFiltersByDateTimeRange(data['last_trade'], 'yyyy-MM-DD HH:mm:ss'),
        note_last: formatFiltersByDateTimeRange(data['note_last']),
        note_first: formatFiltersByDateTimeRange(data['note_first']),
        updated_at: formatFiltersByDateTimeRange(data['updated_at']),
        last_login: formatFiltersByDateTimeRange(data['last_login']),
        ftd_range: formatFiltersByDateTimeRange(data['ftd_range']),
        created_at: formatFiltersByDateTimeRange(data['created_at']),
        // limit: formatFiltersByDateTimeRange(data['limit']),
        ...dynamic_default,
        ...dynamicStatuses,
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const getFilterValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data)) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const getDynamicStatusKey = (data?) => {
    let dynamicStatuses = {};
    if (data) {
      let dynamic_field = dynamicFilterList?.map((item) => {
        return { [item.value]: getFilterValue(data[item.value]) };
      });
      for (const item of dynamic_field) {
        dynamicStatuses = { ...dynamicStatuses, ...item };
      }
    } else {
      let dynamic_field = dynamicFilterList?.map((item) => {
        return { [item.value]: [] };
      });
      for (const item of dynamic_field) {
        dynamicStatuses = { ...dynamicStatuses, ...item };
      }
    }

    return dynamicStatuses;
  };

  const { table, form, defaultValues } = useSearchClientsPage({
    dynamicStatuses: getDynamicStatusKey(),
  });

  const formatFiltersByDateTimeRange = (
    dateTimeRange: {
      from: string;
      to: string;
    },
    dateFormat = fullDateTimeFormat,
  ): {
    from: string;
    to: string;
  } | null => {
    return dateTimeRange && dateTimeRange.from && dateTimeRange.to
      ? {
          from: moment(dateTimeRange.from).format(dateFormat),
          to: moment(dateTimeRange.to).format(dateFormat),
        }
      : null;
  };

  const submitWithFilters = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData: any = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };

    if (postData?.filter?.retention?.includes('new_retention')) {
      postData?.filter?.retention.push('new');
    }
    if (postData?.filter?.sales?.includes('new_sales')) {
      postData?.filter?.sales.push('new');
    }

    if (!filters && !postData) {
      return;
    }
    if (filters && !postData) {
      clearFiltersSortList();
    } else {
      setFilters(postData);
      getClientsList(
        { navigate: 'first', perPage: postData?.filter?.limit || perPageCount },
        { ...postData, sort },
      );
    }
    // if (data.limit) changeLimits(data.limit);
  };

  const getClientToBulkActions = (rows) => {
    const clients = [];
    const clientsWithType = [];
    rows?.forEach((item) => {
      clientsWithType.push(clientsList[item['index']]['type']);
      clients.push(clientsList[item['index']]['id']);
    });
    setSelectedRows(clients);
    setSelectedClientsType(clientsWithType);
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return `per_page=${perPageCount}&page=1`;
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splintedLink = tableLinks[navigate].split('?').reverse()[0];
      const splintedParams = splintedLink.split('&');
      toPage = splintedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (sort && filters) {
      return {
        sort,
        ...filters,
      };
    }

    if (sort && !filters) {
      return {
        sort,
      };
    }

    if (!sort && filters) {
      return filters;
    }
  };

  const clearFiltersSortList = () => {
    setPerPageCount(200);
    getClientsList({ navigate: 'first', perPage: 200 });
    setFilters(null);
  };

  const sortColumn = (col) => {
    const sorted = col.isSorted;
    const desc = col.isSortedDesc;
    const sortedCountryAndClient =
      SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.country ||
      SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.client;
    if (!sorted) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedCountryAndClient ? 'asc' : 'desc',
      };
      setSort(sort);
      getClientsList(
        { navigate: 'first' },
        {
          ...filters,
          sort,
        },
      );
      col.toggleSortBy(true);
    }

    if (sorted && desc) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedCountryAndClient ? 'desc' : 'asc',
      };
      setSort(sort);
      getClientsList(
        { navigate: 'first' },
        {
          ...filters,
          sort,
        },
      );
      col.toggleSortBy(false);
    }

    if (sorted && !desc) {
      const data = {
        ...filters,
      };
      setSort(undefined);
      getClientsList({ navigate: 'first' }, data);
      col.clearSortBy();
    }
  };

  useEffect(() => {
    if (status !== null) {
      getClientsList(
        { navigate: 'first' },
        {
          ...filters,
          sort,
        },
      );
    }
  }, [status]);

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <SearchClientsPageTitle
            total={tableMeta?.total}
            isExpanded={expanded}
            isLoading={isListLoading}
            selectedRows={selectedRows}
            selectedClients={selectedClientsType}
            onToggleFilters={() => setExpanded((p) => !p)}
          />
        }
      >
        <>
          <FormProvider {...form}>
            <div className={cx(!expanded && 'hide')}>
              <ClientFilter
                reloadFilters={isListLoading}
                dynamicFilterList={dynamicFilterList}
                setDynamicFilterList={setDynamicFilterList}
                defaultValues={defaultValues}
                submit={submitWithFilters}
                expanded={expanded}
              />
            </div>
            <ProgressBarSection />
            <TableLayout
              className={cx(!expanded && 'mt-2')}
              header={
                <SearchClientsTableLayoutHeader
                  isLoading={isListLoading}
                  queryMessage={queryMessage}
                  onSubmit={submitWithFilters}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                  onReload={() => {
                    getClientsList(
                      {
                        navigate: 'first',
                        perPage: filters?.filter?.limit || perPageCount,
                      },
                      { ...filters, sort },
                    );
                  }}
                />
              }
            >
              {PermissionListClient ? (
                <ClientsTable
                  perPage={perPageCount}
                  data={clientsList}
                  showLoader={isListLoading}
                  onRowSelect={getClientToBulkActions}
                  manualSortHandler={sortColumn}
                  modifyDataHandler={(callback) => console.log('a')}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              ) : null}
            </TableLayout>
          </FormProvider>

          {clientsList?.length ? (
            <TablePagination
              perPage={perPageCount}
              isRareCase={true}
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getClientsList({ perPage: value }, getSortFiltersForPageNavigation());
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getClientsList({ page }, getSortFiltersForPageNavigation());
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default SearchClients;
