import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './feedTable.module.scss';
import { Icon } from 'components/ui/Icon';

const cx = classNames.bind(styles);

const SubRowComponent = ({ row }) => {
  const [show, setShow] = useState(false);
  const { changes } = row;

  const attributes = changes?.attributes || [];
  const additionalAttributes = changes?.additionalAttributes || [];

  const data = attributes.concat(additionalAttributes);

  return (
    <div className={cx('subRow', show ? 'active' : '')}>
      {data.length > 0 ? (
        <div className={cx('showBtn')} onClick={() => setShow(!show)}>
          {`${show ? 'Hide' : 'Show'} details`}{' '}
          <Icon name={show ? 'caretUp' : 'caretDown'} size={16} />
        </div>
      ) : null}
      <div className={cx('subRow__content', show ? 'active' : '')}>
        <ul className={cx('change-list')}>
          {data
            ? data.map((el, index) => (
                <li key={index} className={cx('change-list__item')}>
                  <span className={cx('change-list__item-title')}>{el.field_name}:</span>
                  <span className={cx('change-list__item-value')}>{el.field_value}</span>
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  );
};

export default SubRowComponent;
