import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React from 'react';
import { copyToBuffer } from 'utils/copyToBuffer';
import { makeTableData } from 'helpers/table.helper';
import CreateTeamsPopup from 'pages/Management/Teams/TeamsPopups/CreateTeamsPopup';
import DeleteTeamsPopup from 'pages/Management/Teams/TeamsPopups/DeleteTeamsPopup';
import { getShortUuid } from 'utils/getShortUuid';
import { usePermission } from 'utils/usePermission.tsx';
import Table from '../Table';
import { ManagementTeamPopup } from './Popup.tsx';
import { Icon } from 'components/ui/Icon';
import styles from './offices-table.module.scss';

const cx = classNames.bind(styles);

const TeamsTable = ({
  data,
  officeList,
  deskList,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  onSort,
  columns,
  saveColumnOrder,
}) => {
  const { permissionGiven: PermissionDelete } = usePermission('admin.team.delete');
  const { permissionGiven: PermissionUpdate } = usePermission('admin.team.update');

  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, deskList, officeList],
  );
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name, uuid, desk } = item;
    return {
      team: (
        <div className={cx('rule__name')}>
          <ManagementTeamPopup
            triggerElement={<span className={cx('rule__id')}>{name}</span>}
            teamID={id}
            teamName={name}
          />
          <span className={cx('rule__id')} onClick={() => copyToBuffer(uuid, true)}>
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      office: (
        <div className={cx('rule__name')}>
          {desk ? (
            <>
              <span>{desk?.office?.name}</span>
              <span className={cx('rule__id')} onClick={() => copyToBuffer(desk?.office?.uuid, true)}>
                {getShortUuid(desk?.office?.uuid)}
              </span>
            </>
          ) : (
            <span className={cx('rule__name-empty')}>&mdash;</span>
          )}
        </div>
      ),
      desk: (
        <div className={cx('rule__name')}>
          {desk ? (
            <>
              <span>{desk?.name}</span>
              <span className={cx('rule__id')} onClick={() => copyToBuffer(desk?.uuid, true)}>
                {getShortUuid(desk?.uuid)}
              </span>
            </>
          ) : (
            <span className={cx('rule__name-empty')}>&mdash;</span>
          )}
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {PermissionUpdate ? (
              <CreateTeamsPopup
                deskList={deskList}
                officeList={officeList}
                ruleData={item}
                actionType={SALES_RULES_ACTION_TYPE.EDIT}
                triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
                // onCloseModal={() => console.log('a')}
                updateComponent={modifyDataHandler}
              />
            ) : null}
          </div>
          <div>
            {PermissionDelete ? (
              <DeleteTeamsPopup
                rule={item}
                triggerBtn={<Icon name="trash" size={20} color="var(--red)" />}
                updateComponent={modifyDataHandler}
              />
            ) : null}
          </div>
        </div>
      ),
    };
  });
  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default TeamsTable;
