import React, { useEffect, useRef, useState } from 'react';
import { getRequestListMethod } from 'api/customer-service/request/index';
import CryptoPaymentsFilters from './CryptoPaymentsFilters/CryptoPaymentsFilters';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import CryptoRequestTable from 'components/ui/Table/CryptoRequestTable';
import TablePagination from 'components/ui/TablePagination';
import { CryptoWithdrawFilter } from 'models/CustomerService/CryptoWithdrawFilter';
import { TableLinks, TableMeta } from 'models/Table';
import { CryptoWithdrawRequest } from 'models/CustomerService/CryptoWithdraw';
import { useCheckAutoRefresh } from 'utils/useCheckAutoRefresh.tsx';
import { usePageTitle } from 'utils/usePageTitle';
import Page from 'pages/Page';
import { useCryptoOutputPage } from './useCryptoOutputPage';
import { FormProvider } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { TableLayout } from 'components/ui/Table/TableLayout';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import styles from './crypto-payments-page.module.scss';

const title = 'Crypto Withdraw';

const StatusList = [
  { value: 'new', label: 'New' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
];

const CryptoOutputPage = () => {
  const [tableData, setTableData] = useState<CryptoWithdrawRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState<CryptoWithdrawFilter>();

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(15);
  const checkAutoRefresh = useCheckAutoRefresh('Page Crypto Withdraw');

  const componentMounted = useRef(true);

  usePageTitle(title);

  useEffect(() => {
    getRequests();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getRequests = (options?, filter?) => {
    setIsLoading(true);

    getRequestListMethod(getTablePageParams(options, filter))
      .then((res) => {
        if (componentMounted.current) {
          setTableData(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  const getTablePageParams = (pageParams, filter) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    const params = `${toPage}&${perPageParams}`;

    if (filter) {
      let withFilters = `?${params}`;
      if (filter['status']) {
        withFilters = `${withFilters}&status=${filter['status']}`;
      }
      return withFilters;
    }

    return `?${params}`;
  };

  const { table, form } = useCryptoOutputPage();

  const onSubmit = (data) => {
    const status = data?.status?.value;

    if (!filters && !status) {
      return;
    }

    if (filters && !status) {
      getRequests({ navigate: 'first' });
      setFilters(null);
    } else {
      setFilters({ status });
      getRequests({ navigate: 'first' }, { status });
    }
  };

  return (
    <DashboardLayout>
      <Page isStickyTitle title={`${tableMeta?.total || 0} Crypto Withdraw`}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={styles.filters}>
            <Row>
              <Col md={3}>
                <JokerSelect
                  label="Status"
                  control={form.control}
                  id="status"
                  name="status"
                  options={StatusList}
                />
              </Col>
            </Row>
            <TableLayout
              header={
                <CryptoPaymentsFilters
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  onResetColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              <CryptoRequestTable
                data={tableData}
                perPage={perPageCount}
                showLoader={isLoading}
                columns={table.columns}
                onSaveColumnOrder={table.saveColumnOrder}
                clientsList={[]}
                modifyDataHandler={() => getRequests()}
              />
            </TableLayout>
          </form>
          {tableData?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getRequests({ perPage: value }, filters);
              }}
              goToSelectedPage={(page) => {
                getRequests({ page }, filters);
              }}
              hidePageSelect={false}
            />
          ) : null}
        </FormProvider>
      </Page>
    </DashboardLayout>
  );
};

export default CryptoOutputPage;
