import { GroupBase, StylesConfig } from 'react-select';

export const getMultiSelectStyles = (
  hasOptions = true,
): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  menu: (s) => ({}),
  menuList: (s) => ({ ...s, padding: 0, maxHeight: '150px' }),
  control: (s) => ({
    ...s,
    fontWeight: 700,
    fontSize: 'var(--font-size-12)',
    lineHeight: '20px',
    minHeight: 32,
    flex: 1,
    color: 'var(--grey)',
    backgroundColor: 'var(--white)',
    borderRadius: 8,
    border: '1px solid var(--cyan-100)',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'var(--cyan-100)',
    },
    margin: 4,
    marginLeft: hasOptions ? 36 : 4,
  }),
  container: (provided) => ({
    ...provided,
    backgroundColor: 'var(--white)',

    '@media (max-width: 625px)': {
      width: '100%',
    },
  }),
  input: (s) => ({ ...s, color: 'var(--primary-text-color)' }),
  option: (s) => ({
    ...s,
    fontWeight: 500,
    fontSize: 'var(--font-size-13)',
    lineHeight: '34px',
    letterSpacing: '0.36px',
    padding: '0 8px',
    paddingLeft: 0,
    color: 'var(--primary-text-color)',
    backgroundColor: 'var(--white)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'var(--main-light-color)',
    },
  }),
});
