import { getNumberRange } from 'utils/getNumberRange';

const getValue = (data) => {
  if (!data || (Array.isArray(data) && !data.length)) {
    return;
  }

  if (Array.isArray(data) && data.length) {
    const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
    return temp.filter((item) => item || typeof item === 'number');
  }

  return data.value !== 'any' ? data.value : null;
};

export const preparePaymentsFiltersData = (data) => {
  const postData = {
    filter: {
      payment_method: getValue(data['payment_method']),
      payment_type: getValue(data['payment_type']),
      transaction_type: getValue(data['transaction_type']),
      transaction_status: getValue(data['transaction_status']),
      status_updated_at: data['status_updated_at'],
      deskIds: getValue(data['deskIds']),
      teamIds: getValue(data['teamIds']),
      operatorIds: getValue(data['operatorIds']),
      affiliateIds: getValue(data['affiliateIds']),
      currencyIds: getValue(data['currencyIds']),
      accountType: getValue(data['accountType']) ? [getValue(data['accountType'])] : null,
      first_time_deposit: getValue(data['first_time_deposit']),
      amount: getNumberRange(data['amount']),
      created_at: data['created_at'],
    },
  };

  if (data['query']) {
    postData['query'] = data['query'];
  }

  if (data['query_category']) {
    postData['searchCategory'] = data['query_category']?.value;
  }

  return postData;
};
