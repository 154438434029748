import { PermissionList } from 'models/RolesAndPermissions/Permissions';
import { Accordion } from 'react-bootstrap';
import { PermissionItem } from './PermissionItem';

import styles from './permissions.module.scss';

type Props = {
  permissions: PermissionList;
  onUpdatePermission: (action: string, show: boolean, id: number) => void;
};

export const Permissions = ({ permissions, onUpdatePermission }: Props) => {
  return (
    <>
      {Object.keys(permissions || {}).map((key) => {
        const perm = permissions[key];

        return (
          <Accordion key={key} bsPrefix={styles.accordion}>
            <Accordion.Item eventKey={key} key={key} bsPrefix={styles.accordionItem}>
              <Accordion.Header bsPrefix={styles.accordionHeader}>{key}</Accordion.Header>
              <Accordion.Body bsPrefix={styles.accordionBody}>
                <ul className={styles.list}>
                  {perm.map((p) => (
                    <PermissionItem
                      key={p.id}
                      permission={p}
                      onUpdatePermission={onUpdatePermission}
                    />
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
    </>
  );
};
