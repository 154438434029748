import { TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES } from 'constants/table';
import { CryptoWithdrawFilter } from 'models/CustomerService/CryptoWithdrawFilter';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const useCryptoOutputPage = () => {
  const table = useTableColumnOrder(
    'TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES',
    TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES,
  );

  const form = useForm<CryptoWithdrawFilter>({
    reValidateMode: 'onChange',
    defaultValues: { status: null },
  });

  return {
    table,
    form,
  };
};
