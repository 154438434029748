import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { getEnableCCWithdraw, getEnableMT, getEnablePayments } from 'constants/selectors';
import { getClientPaymentsColumns } from 'constants/table';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const getClientPaymentsDefaultValues = (userId) => {
  return {
    query: '',
    query_category: defaultQueryCategoryOption,
    payment_method: [],
    payment_type: [],
    transaction_type: [],
    transaction_status: [],
    status_updated_at: '',
    currencyIds: [],
    accountType: { value: 'any', label: 'Any' },
    first_time_deposit: { value: 'any', label: 'Any' },
    amount: {
      from: '',
      to: '',
    },
    created_at: '',
    custom_filters: '',
    user_id: [+userId],
  };
};

export const usePaymentTab = ({ userId }) => {
  const ENABLE_MT = useAppSelector(getEnableMT);
  const ENABLE_PAYMENT = useAppSelector(getEnablePayments);
  const ENABLE_CC_WITHDRAW = useAppSelector(getEnableCCWithdraw);

  const tableHeader = useMemo(
    () => getClientPaymentsColumns(ENABLE_MT, ENABLE_PAYMENT, ENABLE_CC_WITHDRAW),
    [ENABLE_MT, ENABLE_PAYMENT, ENABLE_CC_WITHDRAW],
  );

  const table = useTableColumnOrder('PAYMENT_TABLE', tableHeader);

  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: getClientPaymentsDefaultValues(userId),
  });

  return { form, tableHeader, table };
};
