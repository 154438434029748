import classNames from 'classnames/bind';
import { usePermission } from 'utils/usePermission.tsx';
import styles from './add-branch.module.scss';
import { getColorfulLabel } from './utils.js';
import { HierarchyBranch } from 'models/Hierarchy/HierarchyData.js';
import { Icon } from 'components/ui/Icon/Icon.tsx';

type Props = {
  item: HierarchyBranch;
  list: HierarchyBranch[];

  openChangeForm: boolean;

  fetchBranchTypeList: () => void;

  setChangeOpenForm: (val: boolean) => void;
  setOldBranchId: (val: number) => void;
  onDeleteBranch: (branch: HierarchyBranch) => void;
};

const BranchItem = (props: Props) => {
  const {
    item,
    onDeleteBranch,
    list,
    setChangeOpenForm,
    fetchBranchTypeList,
    setOldBranchId,
    openChangeForm,
  } = props;

  const { permissionGiven: PermissionDeleteBranch } = usePermission('admin.branch.delete');
  const { permissionGiven: PermissionChangeBranch } = usePermission('admin.branch.change-team');

  const cx = classNames.bind(styles);
  return (
    <div className={cx('departmentItem')}>
      <span className={cx('title_item')}>{item.branch_type} </span>
      <span className={cx('mr2')}>: </span>
      <span className={cx('title_item')}> {getColorfulLabel(item)}</span>
      {PermissionDeleteBranch ? (
        <>
          {item.branch_type === 'Team' && PermissionChangeBranch && (
            <button
              className={cx('changeBtn')}
              onClick={() => {
                setChangeOpenForm(!openChangeForm);
                setOldBranchId(item.id);
                fetchBranchTypeList();
              }}
            >
              <Icon name="notePencil" size={20} color="var(--main-color)" />
            </button>
          )}
          {list?.length > 1 && (
            <Icon name="trash" size={20} color="var(--red)" onClick={() => onDeleteBranch(item)} />
          )}
        </>
      ) : null}
    </div>
  );
};
export default BranchItem;
