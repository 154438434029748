import { getLeadFeedList } from 'api/feed';
import Button from 'components/ui/Button';
import DatePickerRange from 'components/ui/DatePickerRange';
import FeedTable from 'components/ui/Table/FeedTable';
import TablePagination from 'components/ui/TablePagination';
import React, { useEffect, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import { getLeadActionTypeFeed } from 'api/leads';
import JokerSelect from 'components/ui/JokerSelect';
import styles from './../../lead-page.module.scss';
import { isFormChange } from 'utils/isFormChange';
import { TableLinks, TableMeta } from 'models/Table';
import { ListValue } from 'models/ListValue';
import { LeadFeedEntry, LeadFeedFilter } from 'models/Leads/LeadFeed';
import { ReloadButton } from 'components/ui/ReloadButton';
import { Col, Row } from 'react-bootstrap';
import { TableLayout } from 'components/ui/Table/TableLayout';

const Feed = () => {
  const [tradingAccList, setTradingAccList] = useState<LeadFeedEntry[]>([]);

  const [isListLoading, setIsListLoading] = useState(false);
  const [actionTypeList, setActionTypeList] = useState<ListValue[]>([]);

  const [filterData, setFilterData] = useState<LeadFeedFilter>(null);

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();

  const componentMounted = useRef(true);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    const created_at_from = new Date(data.created_at?.from).getTime();
    const created_at_to = new Date(data.created_at?.to).getTime();
    const sendData = {
      ...data,
      from: created_at_from / 1000,
      to: created_at_to / 1000,
      query: data.query,
      action_type: data.action_type,
    };
    setFilterData(sendData);
  };

  useEffect(() => {
    filterData && fetchFeedList(filterData);
  }, [filterData]);

  const fetchActionType = () => {
    getLeadActionTypeFeed().then((res) => {
      if (res && res.status === 200) {
        var result = Object.keys(res.data)
          .map((key) => res.data[key])
          .map((opt) => ({
            value: opt,
            label: opt,
          }));
        setActionTypeList(result);
      }
    });
  };

  const fetchFeedList = (options?) => {
    setIsListLoading(true);
    getLeadFeedList(getTablePageParams(options), id)
      .then((res) => {
        if (res.status === 200) {
          setTradingAccList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => setIsListLoading(false));
  };

  useEffect(() => {
    fetchFeedList();
    fetchActionType();
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }
    let str = `${toPage}&${perPageParams}`;
    if (filterData?.from) {
      str = str + `&from=${filterData.from}`;
    }
    if (filterData?.to) {
      str = str + `&to=${filterData.to}`;
    }
    if (filterData?.action_type) {
      str = str + `&action_type=${filterData.action_type.value}`;
    }
    if (filterData?.query) {
      str = str + `&query=${filterData.query}`;
    }
    return str;
  };

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabTitle}>Feed</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit);
        }}
      >
        <Row>
          <Col xl={3} lg={4} md={6} className="mb-3">
            <JokerSelect
              name="action_type"
              label="Action type"
              control={control}
              options={actionTypeList}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="created_at"
              control={control}
              label="Date & time range"
              placeholder="From - To"
            />
          </Col>
        </Row>
      </form>
      <TableLayout
        header={
          <div className={styles.notesButtons}>
            <ReloadButton isLoading={isListLoading} onClick={() => onSubmit(getValues())} />
            <Button
              buttonType="outline"
              isLoading={isListLoading}
              type="button"
              buttonText="Reset"
              disabled={!isFormChange(dirtyFields)}
              onClick={() => {
                reset({
                  action_type: null,
                  created_at: null,
                });
              }}
            />
            <Button
              buttonText="Apply"
              isLoading={isListLoading}
              onClick={handleSubmit(onSubmit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        }
      >
        <FeedTable
          data={tradingAccList}
          perPage={perPageCount}
          showLoader={isListLoading}
          userId={id}
        />
      </TableLayout>
      {tradingAccList?.length ? (
        <TablePagination
          currentPage={tableMeta?.current_page}
          pagesLength={tableMeta?.last_page}
          perPageChange={(value) => {
            setPerPageCount(value);
            fetchFeedList({ perPage: value });
          }}
          goToSelectedPage={(page) => {
            fetchFeedList({ page });
          }}
        />
      ) : null}
    </div>
  );
};

export default Feed;
