import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './variables.css';
import './globalStyle.scss';
import App from './App';
import SvgSprite from './SvgSprite';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';
import { store } from './store';
import { ThemeProvider } from 'components/theme';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider>
        <App />
        <SvgSprite />
        <NotificationContainer />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
