import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import styles from './save-filter-popup.module.scss';
import Button from 'components/ui/Button/Button';
import { useForm } from 'react-hook-form';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import PopupTemplate from 'components/ui/PopupTemplate';
import { Col, Container, Row } from 'react-bootstrap';
import FormInput from 'components/ui/FormInput';
import CheckBox from 'components/ui/CheckBox/CheckBox';
import { postFilterSet, putFilterSet } from 'api/filters';
import { notify } from '../../../../utils/notify.tsx';
import { IFilter, ISavedFilterData } from '../../../../models/Payments/SaveFilterPopup.ts';

const cx = classNames.bind(styles);

const SaveFilterPopup = ({
  triggerBtn,
  onCloseModal,
  prepareFiltersData,
  type,
  isUpdate,
  filterSet,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      name: isUpdate ? prepareFiltersData()?.custom_filters?.label : '',
      favorite: isUpdate ? prepareFiltersData()?.custom_filters?.is_favorite === 1 : false,
    },
  });
  const nameField = watch('name');

  useEffect(() => {
    reset({
      name: isUpdate ? prepareFiltersData()?.custom_filters?.label : '',
      favorite: isUpdate ? prepareFiltersData()?.custom_filters?.is_favorite === 1 : false,
    });
  }, [onCloseModal]);

  const closeModal = (closeModal): void => {
    reset({
      name: isUpdate ? prepareFiltersData()?.custom_filters?.label : '',
      favorite: isUpdate ? prepareFiltersData()?.custom_filters?.is_favorite === 1 : false,
    });
    closeModal();
    onCloseModal();
  };

  const checkFilterName = (filters: IFilter[], name: string): boolean => {
    return filters.some((item: IFilter) => item.name.toLowerCase() === name.toLowerCase());
  };

  const checkFilters = (
    customFilters: IFilter[],
    saveFilters: IFilter,
    savedFilterData: ISavedFilterData,
  ) => {
    const newCustomFilters = JSON.parse(JSON.stringify(customFilters));
    const newSaveFilters = JSON.parse(JSON.stringify(saveFilters));

    if (newSaveFilters.custom_filters) {
      delete newSaveFilters.custom_filters;
    }

    return newCustomFilters.some((item: IFilter) => {
      if (item.filter.custom_filters) {
        delete item.filter.custom_filters;
      }

      if (JSON.stringify(item.filter) === JSON.stringify(newSaveFilters)) {
        savedFilterData.name = item.name;
        return true;
      }
    });
  };

  const onSubmit = async (close, data, e) => {
    e.preventDefault();

    const savedFilterData: ISavedFilterData = {};

    if (checkFilters(filterSet, prepareFiltersData(), savedFilterData)) {
      return notify({
        type: 'error',
        message: `Filter set with name ${savedFilterData.name} already exists`,
        timeOut: 3000,
      });
    }

    if (!isUpdate && checkFilterName(filterSet, data.name)) {
      return notify({
        type: 'error',
        message: `Filter name already exists`,
        timeOut: 3000,
      });
    }

    if (isUpdate) {
      const filterWithoutSavedName = filterSet.filter(
        (item: IFilter) => item.name !== prepareFiltersData()?.custom_filters?.label,
      );
      if (checkFilterName(filterWithoutSavedName, data.name)) {
        return notify({
          type: 'error',
          message: `Filter name already exists`,
          timeOut: 3000,
        });
      }
    }

    setIsLoading(true);

    try {
      const dataToSave = prepareFiltersData();
      const postData = {
        filter: dataToSave,
        type: type,
        name: data.name,
        is_favorite: data.favorite,
      };

      if (dataToSave['query']) {
        postData['query'] = dataToSave['query'];
      }

      if (isUpdate) {
        const res = await putFilterSet(
          postData,
          prepareFiltersData()?.custom_filters?.value ?? null,
        );

        NotificationManager.success(`Filters set updated`, 'Success', 5000);
      } else {
        const res = await postFilterSet(postData);

        NotificationManager.success(`Filters set created`, 'Success', 5000);
      }

      closeModal(close);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Save new filter set'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <FormInput
                            name="name"
                            placeholder="Filter name"
                            label="Filter name"
                            maxlength="250"
                            control={control}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-3">
                          <CheckBox
                            control={control}
                            name="favorite"
                            id="favorite"
                            type="checkbox"
                            label={'Is favourite?'}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          type="button"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          disabled={!nameField}
                          buttonText={'Submit'}
                          type="submit"
                          onClick={handleSubmit(onSubmit.bind(undefined, close))}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default SaveFilterPopup;
