import React, { useEffect, useState } from 'react';
import { getDynamicStatusesList, getDynamicStatusesTypeModelList } from 'api/settingsLK/index';
import Button from 'components/ui/Button';
import DynamicStatusesTable from 'components/ui/Table/DynamicStatuseTable/DynamicStatusesTable';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const.js';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../../Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import CreateStatusPopup from './popups/CreateStatusPopup';
import cn from 'classnames';
import styles from './rolesPermissions-page.module.scss';
import { DynamicStatusesActiveRole } from 'models/SettingsLK/DynamicStatuses/DynamicStatusesPage.ts';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import { TABLE_DYNAMIC_STATUS_TYPES } from 'constants/table';
import { TableLayout } from 'components/ui/Table/TableLayout';

const title = 'Dynamic statuses';

const DynamicStatusesPage = () => {
  const { permissionGiven: PermissionCreateAndEditDynamicStatuses } =
    usePermission('admin.statuses.create');

  const { permissionGiven: PermissionChangeColor } = usePermission('admin.statuses.color.update');

  const [activeRole, setActiveRole] = useState<DynamicStatusesActiveRole>(null);

  const [tabs, setTabs] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [roles, setRoles] = useState<DynamicStatusesActiveRole[]>([]);

  const fetchDynamicStatuses = (type: string, active = 0) => {
    getDynamicStatusesList(type).then((response) => {
      const statuses = response.data.data;

      setRoles(statuses);
      setActiveRole(statuses[active]);
    });
  };

  const fetchDynamicStatusesType = async () => {
    try {
      const { data: types } = await getDynamicStatusesTypeModelList();
      setTabs(types);

      if (!roles.length) {
        const [initialType] = types;

        fetchDynamicStatuses(initialType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (tabs[activeTab]) {
      fetchDynamicStatuses(tabs[activeTab]);
    }
  }, [activeTab]);

  useEffect(() => {
    fetchDynamicStatusesType();
  }, []);

  usePageTitle(title);

  const table = useTableColumnOrder('TABLE_DYNAMIC_STATUS_TYPES', TABLE_DYNAMIC_STATUS_TYPES);

  return (
    <DashboardLayout>
      <Page
        title={
          <div className={styles.pageTitle}>
            Dynamic statuses
            {PermissionCreateAndEditDynamicStatuses ? (
              <CreateStatusPopup
                updateComponent={(v) => fetchDynamicStatuses(v || tabs[activeTab])}
                actionType={SALES_RULES_ACTION_TYPE.CREATE}
                triggerBtn={
                  <Button buttonText={'+ Add status'} onClick={() => null} buttonType={'outline'} />
                }
              />
            ) : null}
          </div>
        }
      >
        <Tabs onSelect={setActiveTab}>
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab}>{tab}</Tab>
            ))}
          </TabList>
          {tabs.map((tab) => (
            <TabPanel key={tab}>
              <div className={styles.tabContainer}>
                <div className={styles.buttons}>
                  {roles.map((r) => (
                    <button
                      key={r.id}
                      className={cn(styles.button, activeRole?.id === r.id && styles.active)}
                      onClick={() => setActiveRole(r)}
                    >
                      {r.key}
                    </button>
                  ))}
                </div>
                <TableLayout
                  className={styles.tableLayout}
                  header={
                    <Button
                      className={styles.columnsButton}
                      buttonText="Reset column order"
                      onClick={table.setDefaultColumnsOrder}
                    />
                  }
                >
                  <DynamicStatusesTable
                    permissionChangeColor={PermissionChangeColor}
                    modifyDataHandler={(v) => fetchDynamicStatuses(v || tabs[activeTab])}
                    data={activeRole?.statuses || []}
                    type={activeRole?.type}
                    permission={PermissionCreateAndEditDynamicStatuses}
                    columns={table.columns}
                    saveColumnOrder={table.saveColumnOrder}
                  />
                </TableLayout>
              </div>
            </TabPanel>
          ))}
        </Tabs>
      </Page>
    </DashboardLayout>
  );
};

export default DynamicStatusesPage;
