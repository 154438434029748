import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import styles from '../operator-page.module.scss';
import { notify } from 'utils/notify';
import { updateOperatorProfileMethod } from 'api/operators';
import PaginateSelect from 'components/ui/PaginateSelect/PaginateSelect';
import { fetchUserTypes } from 'utils/managment/fetchData';
import { ManagementOperator } from 'models/Management/Operators/Operator';

const cx = classNames.bind(styles);

type Props = {
  formData: ManagementOperator;
  updateComponent: () => void;
};

const HierarchyForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      type_user_id: data.type_user_id.value,
    };
    updateOperatorProfileMethod(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'User Type changed success',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setIsLoading(false);
      reset({
        type_user_id: { value: formData?.type_user?.id, label: formData?.type_user?.name },
      });
    }
  }, [formData]);

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row>
          <Col md={12}>
            <div className={styles.tabTitle}>
              Hierarchy
              {isDirty && <Button buttonText="Save changes" />}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <PaginateSelect
              isRequired={false}
              isMulti={false}
              isSearchable={false}
              menuPlacement="auto"
              label={'User Type'}
              control={control}
              id="type_user_id"
              name="type_user_id"
              onChange={fetchUserTypes}
              errors={errors.type_user_id}
              rules={{
                required: 'User Type field is required',
              }}
            />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default HierarchyForm;
