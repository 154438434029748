import moment from 'moment';
import { compact } from 'lodash';
import { CryptoWithdrawWalletData } from 'models/CustomerService/CryptoWithdraw';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { IconName } from 'components/ui/Icon';

type InfoProps = {
  wallet: CryptoWithdrawWalletData;
  status: 'rejected' | 'accepted' | 'new';
};

type UserInfoProps = {
  transaction: CryptoWithdrawWalletData['deposit_transaction'];
};

type ReturnType = Array<{
  title: string;
  value: string;
  icon?: IconName;
  onClick?: () => void;
}>;

export const getCryptoRequestInfoTableData = ({ wallet, status }: InfoProps): ReturnType =>
  compact([
    wallet?.wallet_address && {
      title: 'Wallet address:',
      value: wallet.wallet_address,
      icon: 'wallet',
      onClick: () => copyToBuffer(wallet.wallet_address, true),
    },
    status && {
      title: 'Status:',
      value: status.toUpperCase(),
    },
    wallet?.crypto_type && {
      title: 'Crypto type:',
      value: wallet.crypto_type,
    },
    wallet?.amount_usd && {
      title: 'Amount USD:',
      value: `${wallet.amount_usd} $`,
    },
    wallet?.accept_amount && {
      title: 'Accept amount:',
      value: wallet.accept_amount,
    },
    wallet?.accept_exchange_rate && {
      title: 'Accept exchange rate:',
      value: wallet.accept_exchange_rate,
    },
    wallet?.created_at && {
      title: 'Created at:',
      value: moment.unix(wallet.created_at).format('DD.MM.YYYY HH:mm'),
    },
    wallet?.accept_time && {
      title: 'Accept at:',
      value: moment.unix(wallet.accept_time).format('DD.MM.YYYY HH:mm'),
    },
    wallet?.deleted_at && {
      title: 'Delete at:',
      value: moment.unix(wallet.deleted_at).format('DD.MM.YYYY HH:mm'),
    },
    wallet?.deposit_transaction?.id && {
      title: 'Transaction ID:',
      value: wallet.deposit_transaction.id,
    },
    wallet?.deposit_transaction?.type && {
      title: 'Type transaction:',
      value: wallet.deposit_transaction.type,
    },
    wallet?.deposit_transaction?.method && {
      title: 'Method:',
      value: wallet.deposit_transaction.method,
    },
    wallet?.deposit_transaction?.trading_account_id && {
      title: 'Trading Account ID:',
      value: wallet.deposit_transaction.trading_account_id,
    },
  ]);

export const getCryptoRequestUserInfoTableData = ({ transaction }: UserInfoProps): ReturnType => {
  const {
    trading_account,
    user: { user_profile, uuid },
  } = transaction;

  return compact([
    user_profile?.first_name && {
      title: 'First name:',
      value: user_profile.first_name,
    },
    user_profile?.last_name && {
      title: 'Last name:',
      value: user_profile.last_name,
    },
    uuid && {
      title: 'UUID:',
      value: getShortUuid(uuid),
      onClick: () => copyToBuffer(uuid, true),
    },
    trading_account?.balance && {
      title: 'Balance:',
      value: trading_account.balance,
    },
    trading_account?.credit && {
      title: 'Credit:',
      value: trading_account.credit,
    },
  ]);
};
