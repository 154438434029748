import { TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES } from 'constants/table';
import { ManagementOfficesFilter } from 'models/Management/Offices/Filter';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

const defaultValues = {
  country_ids: [],
  query: '',
};

export const useOfficesPage = () => {
  const table = useTableColumnOrder(
    'TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES',
    TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES,
    true,
  );

  const form = useForm<ManagementOfficesFilter>({ reValidateMode: 'onChange', defaultValues });

  return { table, form };
};
