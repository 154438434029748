import React, { useEffect } from 'react';
import Button from 'components/ui/Button';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { usePageTitle } from 'utils/usePageTitle';
import { useAppDispatch, useAppSelector } from 'store';
import { getAlerts, selectTickerMessages } from 'store/slice/tickerSlice';
import { SystemMessagesTable } from './components/SystemMessagesTable/SystemMessagesTable';
import {
  SYSTEM_MESSAGE_MODAL_KEY,
  SystemMessageModal,
} from './components/SystemMessageModal/SystemMessageModal';
import { openJokerModal } from 'store/slice/modalSlice';
import { DeleteMessageModal } from './components/DeleteMessageModal/DeleteMessageModal';
import { AlertModalMode } from 'models/Ticker';
import { usePermission } from 'utils/usePermission';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import Page from 'pages/Page';
import styles from './system-messages.module.scss';

const SystemMessagesPage = () => {
  const dispatch = useAppDispatch();
  const { active, scheduled, past } = useAppSelector(selectTickerMessages);

  usePermission('admin.menu-system-messages.view', true);

  usePageTitle('System Messages');

  useEffect(() => {
    dispatch(getAlerts());
  }, []);

  const addMessageHandler = () => {
    dispatch(
      openJokerModal({
        key: SYSTEM_MESSAGE_MODAL_KEY,
        data: { title: 'New message', submitTitle: 'Create', mode: AlertModalMode.CREATE },
      }),
    );
  };

  return (
    <DashboardLayout>
      <Page
        title={
          <div className={styles.header}>
            Sysem Messages{' '}
            <Button buttonText="+ Add message" buttonType="outline" onClick={addMessageHandler} />
          </div>
        }
      >
        <>
          <Tabs>
            <TabList>
              <Tab>Active</Tab>
              <Tab>Scheduled</Tab>
              <Tab>Past</Tab>
            </TabList>
            <TabPanel>
              <div className={styles.tabContainer}>
                <div className={styles.tableLayout}>
                  <SystemMessagesTable data={active} type="active" />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className={styles.tabContainer}>
                <div className={styles.tableLayout}>
                  <SystemMessagesTable data={scheduled} type="scheduled" />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className={styles.tabContainer}>
                <div className={styles.tableLayout}>
                  <SystemMessagesTable data={past} type="past" />
                </div>
              </div>
            </TabPanel>
          </Tabs>
          <SystemMessageModal />
          <DeleteMessageModal />
        </>
      </Page>
    </DashboardLayout>
  );
};

export default SystemMessagesPage;
