import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';
import { Link } from 'react-router-dom';
import { getShortUuid } from 'utils/getShortUuid';
import moment from 'moment';
import EditPinToUserPopup from 'pages/CustomerService/CryptoPayments/CryptoPaymentsPopups/EditPinToUserPopup';
import { ICryptoWalletBindingsTableProps } from 'models/components/Table/CryptoWalletBindingsTable/CryptoWalletBindingsTable.ts';
import { Icon } from 'components/ui/Icon';
import styles from './crypto-wallet-table.module.scss';

const cx = classNames.bind(styles);

const CryptoWalletBindingsTable: FC<ICryptoWalletBindingsTableProps> = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  permission,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, user, pinned, available_from, available_to, created_at } = item;
    return {
      // id: <span>{user.id}</span>,
      user: (
        <div className={cx('wallet__user')}>
          <Link to={`/client/${user?.id}`} className={styles.userLink}>
            <span>{user?.user_profile?.first_name + ' ' + user?.user_profile?.last_name}</span>
          </Link>
          <div className={cx('wallet__user-id')}>
            <span onClick={() => copyToBuffer(user?.uuid, true)}>{getShortUuid(user?.uuid)}</span>
          </div>
          <span>{user?.email}</span>
        </div>
      ),
      bind: (
        <div className={cx('wallet__desc')}>
          <div className={cx('wallet__desc__col-content')}>
            {pinned ? (
              <span className={cx('pinned', 'strong')}>Pinned</span>
            ) : (
              <>
                <div className={cx('available', 'strong')}>AVAILABLE</div>
                <div>from: {moment.unix(available_from).format('DD.MM.YYYY HH:mm')}</div>
                <div>to: {moment.unix(available_to).format('DD.MM.YYYY HH:mm')}</div>
              </>
            )}
          </div>
        </div>
      ),
      action: (
        <div className={cx('wallet__actions')}>
          {item && permission ? (
            <EditPinToUserPopup
              bindInfo={item}
              updateComponent={() => modifyDataHandler?.()}
              triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
              onCloseModal={() => console.log('a')}
            />
          ) : null}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('wallet-table')}
      isSelected={false}
      getCellProps={(cellInfo) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;

        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
      saveColumnOrder={saveColumnOrder}
    />
  );
};

export default CryptoWalletBindingsTable;
