import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { AlertModalMode } from 'models/Ticker';

type InitialState = {
  modal: string | null;
  data: any;
};

const initialState: InitialState = {
  modal: null,
  data: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openJokerModal(state, action) {
      state.modal = action.payload.key;
      if (action.payload.data) {
        state.data = action.payload.data;
      }
    },
    closeJokerModal(state) {
      state.modal = null;
      state.data = null;
    },
  },
});

export const modalReducer = modalSlice.reducer;
export const { openJokerModal, closeJokerModal } = modalSlice.actions;

export const selectModalOpened = (state: RootState) => state.modal.modal;
export const selectModalProps = (state: RootState) => state.modal.data;

export const selectModalIsConstructiveMode = createSelector(selectModalProps, (props) =>
  [AlertModalMode.CREATE, AlertModalMode.EDIT, AlertModalMode.REPUBLISH].some(
    (m) => m === props?.mode,
  ),
);
