import styles from './client-acquisition-status-old.module.scss';
import { Operator } from 'models/Clients/Clients';
import { HierarchyTeam } from 'models/Hierarchy/HierarchyData';

type Props = {
  oldValues: { team: HierarchyTeam; operator: Operator };
  type: string;
  status: { status_name: string };
};

export const ClientAcquisitionStatusOld = (props: Props) => {
  const { oldValues, type, status } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles.type}>{type}: </span>
        <span className={styles.status}>{status?.status_name || 'None'}</span>
      </div>
      <div className={styles.info}>
        <div>
          <span>{oldValues?.operator?.full_name ? oldValues.operator.full_name : '---'}</span>
        </div>
        <div>
          <span>Desk:</span> {oldValues?.team?.desk ? oldValues.team.desk.name : '---'}
        </div>
        <div>
          <span>Team:</span> {oldValues?.team ? oldValues.team.name : '---'}
        </div>
      </div>
    </div>
  );
};
