export const parseTemplate = (list) => {
  const newList = list.reduce((acc, curr) => {
    const name = curr.action;
    if (acc[name]) {
      return { ...acc, [name]: acc[name].concat(curr) };
    }
    return { ...acc, [name]: [curr] };
  }, {});

  return newList;
};
