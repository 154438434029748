import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import { MultiSelect } from 'components/ui/MultiSelect';
import { ListValue } from 'models/ListValue';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

type Props = {
  categoryOptions: ListValue[];
  typesOptions: ListValue[];
  changeDocumentCategory: (val: ListValue[]) => void;
};

export const KucDocumentsFields = ({
  categoryOptions,
  typesOptions,
  changeDocumentCategory,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Row>
      <Col xs={12} md={12} lg={6} xl={3} className="mb-3">
        <MultiSelect
          label={'Category'}
          control={control}
          id="kyc_category"
          name="kyc_category"
          options={categoryOptions}
          onChange={changeDocumentCategory}
        />
      </Col>
      <Col xs={12} md={12} lg={6} xl={3} className="mb-3">
        <MultiSelect
          label={'Document type'}
          control={control}
          id="kyc_type"
          name="kyc_type"
          options={typesOptions}
        />
      </Col>
      <Col xs={12} md={12} lg={6} xl={3} className="mb-3">
        <DatePickerRange
          name="created_at"
          control={control}
          label={'Upload data range'}
          placeholder="From - To"
        />
      </Col>
    </Row>
  );
};
