import { createContext, useState, useMemo, useEffect, ReactNode } from 'react';

export const LOCAL_STORAGE_THEME_KEY = 'theme';

export type Theme = 'light' | 'dark';

type ThemeContextProps = {
  theme?: Theme;
  setTheme?: (theme: Theme) => void;
};

type Props = {
  children: ReactNode;
  initTheme?: Theme;
};

export const ThemeContext = createContext<ThemeContextProps>({});

const defaultTheme = (localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as Theme) || 'light';

export const ThemeProvider = ({ children, initTheme }: Props) => {
  const [theme, setTheme] = useState<Theme>(initTheme ?? defaultTheme);

  const defaultValues = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme],
  );

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return <ThemeContext.Provider value={defaultValues}>{children}</ThemeContext.Provider>;
};
