import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './style.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { createRoleMethod } from 'api/templateRoles';
import PaginateSelect from 'components/ui/PaginateSelect';
import { fetchTemplates } from 'utils/managment/fetchData';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  actionType: string;
};

const CreateRolePopup = (props: Props) => {
  const { triggerBtn, updateComponent, actionType } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);

  const contentToScrol = useRef(null);
  const setDefaultValues = () => {
    return {
      name: undefined,
      template_ids: undefined,
    };
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  useEffect(() => {
    reset(setDefaultValues());
  }, [templateList]);

  const onSubmit = (close, data) => {
    const sendData = {
      name: data.name,
      role_template_id: getValue(data['template_ids']),
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addRoleHandler(sendData, close);
    }
  };

  const addRoleHandler = (sendData, close) => {
    setIsLoading(true);
    createRoleMethod(sendData)
      .then((res) => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Template created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  const onOpen = () => {};

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'New role'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')} ref={contentToScrol}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <FormInput
                            rules={{
                              required: 'Role name field is required',
                            }}
                            label={'Role name'}
                            id="role_name"
                            control={control}
                            name="name"
                            placeholder={'Role name'}
                            errors={errors.name}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-3">
                          <PaginateSelect
                            isSearchable={false}
                            isMulti={false}
                            label="Template"
                            control={control}
                            id="template"
                            name="template_ids"
                            onChange={(page) => fetchTemplates(page)}
                            isRequired
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                buttonText={'Create role'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateRolePopup;
