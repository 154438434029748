import { ChangeEvent } from 'react';
import { Icon, IconName } from '../Icon';

export type CheckboxStatus = 'checked' | 'unchecked' | 'indeterminate';

type Props = {
  status: 'checked' | 'unchecked' | 'indeterminate';
  size?: number;
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const iconNames: Record<CheckboxStatus, IconName> = {
  checked: 'checkedIcon',
  indeterminate: 'indeterminateIcon',
  unchecked: 'uncheckedIcon',
};

/** Checkbox без control */
export const SimpleCheckbox = ({ status, size, className, onChange }: Props) => {
  return (
    <label className={className}>
      <input
        type="checkbox"
        onChange={onChange}
        style={{ display: 'none' }} // Скрываем стандартный чекбокс
      />
      <Icon name={iconNames[status]} size={size} color="var(--main-color)" />
    </label>
  );
};
