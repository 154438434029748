import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import { useFormContext } from 'react-hook-form';

import Button from 'components/ui/Button/Button';
import { isFormChange } from 'utils/isFormChange';
import { getValue } from 'utils/getValue';
import { clearEmptyFilter } from 'utils/clearEmptyFilter';
import { ManagementDesksFilter } from 'models/Management/Desks/Filter';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import styles from './desks-filter.module.scss';

type Props = {
  onSubmit: (data: ManagementDesksFilter) => void;
  isLoading: boolean;
  queryMessage: string;
  setDefaultColumnsOrder: () => void;
};

const DesksFilter = (props: Props) => {
  const { onSubmit, isLoading, queryMessage, setDefaultColumnsOrder } = props;
  const desksSearchOptions = useSearchCategoriesOptions('desk');

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { dirtyFields },
    watch,
  } = useFormContext<ManagementDesksFilter>();

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    desksSearchOptions,
  );

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        office_ids: getValue(data['office_ids']) ? [getValue(data['office_ids'])] : '',
        types: getValue(data['types']) ? [getValue(data['types'])] : '',
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset();
    onSubmit({ filter: {} });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={styles.form}
    >
      <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
      <SearchBar
        className={styles.searchBar}
        queryMessage={queryMessage}
        selectProps={{
          id: 'query_category',
          name: 'query_category',
          control,
          options: desksSearchOptions,
          onSelectChange: () => {
            const { query } = getValues();
            if (query) submit(getValues());
          },
        }}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: dynamicSearchPlaceholder,
          onSearch: () => submit(getValues()),
          onChange: (v) => v?.length === 0 && submit(getValues()),
        }}
      />
      <div className={styles.buttons}>
        <ReloadButton isLoading={isLoading} onClick={() => submit(getValues())} />
        <Button
          buttonText="Reset"
          buttonType="outline"
          isLoading={isLoading}
          type="reset"
          onClick={() => resetForm()}
          disabled={!isFormChange(dirtyFields)}
        />
        <Button
          buttonText="Apply"
          isLoading={isLoading}
          type="submit"
          onClick={handleSubmit(submit)}
          disabled={!isFormChange(dirtyFields)}
        />
      </div>
    </form>
  );
};

export default DesksFilter;
