import { getFilterList } from 'api/filters';
import { getPartnersList } from 'api/partners';
import { getTransactionsTypeMethods, getTransactionsTypesList } from 'api/payments';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import FormInput from 'components/ui/FormInput';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { MultiSelect } from 'components/ui/MultiSelect';
import PaginateSelect from 'components/ui/PaginateSelect/PaginateSelect';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { fetchDesks, fetchOperators, fetchTeams } from 'utils/managment/fetchData';
import { paymentsDefaultValues } from '../usePaymentsPage';
import {
  optionsFirstTimeDeposit,
  optionsTransactionStatus,
} from 'constants/payments/payments.const';
import { CURRENCY_OPTIONS } from 'constants/clients/filters.const';
import { preparePaymentsFiltersData } from '../utils';
import { clearEmptyFilter } from 'utils/clearEmptyFilter';
import { PaymentFilter } from 'models/Payments/Filter';
import DeleteFilterPopup from 'pages/Clients/SearchClients/SearchClientsPopups/DeleteFilterPopup/DeleteFilterPopup';
import SaveFilterPopup from '../PaymentsPopups/SaveFilterPopup/SaveFilterPopup';
import Button from 'components/ui/Button';
import { isEmpty } from 'lodash';
import styles from './payments-fields.module.scss';
import FilterSetSelect from 'components/ui/FilterSetSelect';

type Props = {
  onSubmit: (data: PaymentFilter) => void;
};

export const PaymentsFields = ({ onSubmit }: Props) => {
  const {
    control,
    formState: { dirtyFields },
    getValues,
    reset,
  } = useFormContext();

  const [filterSet, setFilterSet] = useState([]);
  const [paymentMethodOpt, setPaymentMethodOpt] = useState([]);
  const [transactionTypeOpt, setTransactionTypeOpt] = useState([]);
  const [affiliate, setAffiliate] = useState([]);

  const componentMounted = useRef(false);

  const fetchPaymentsMethodList = () => {
    getTransactionsTypeMethods().then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.payment_filter.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setPaymentMethodOpt(opt);
      }
    });
  };
  const fetchTransactionsTypesList = () => {
    getTransactionsTypesList().then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setTransactionTypeOpt(opt);
      }
    });
  };

  const fetchAffiliateList = () => {
    getPartnersList('per_page=100')
      .then((res) => {
        if (componentMounted.current) {
          const opt = res.data.data.map((opt) => ({
            value: opt.id,
            label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
          }));
          setAffiliate([{ value: 0, label: 'No affiliate' }, ...opt]);
        }
      })
      .catch(console.log);
  };

  const fetchFilterSet = () => {
    getFilterList('type=payment&per_page=3000')
      .then((res) => {
        if (componentMounted.current) {
          setFilterSet(res?.data?.data);
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    componentMounted.current = true;

    Promise.all([
      fetchFilterSet(),
      fetchTransactionsTypesList(),
      fetchPaymentsMethodList(),
      fetchAffiliateList(),
    ]);

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const submit = (data) => {
    const prepeared = preparePaymentsFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };

    onSubmit(postData);
  };

  const handleChange = (data) => {
    const vals = paymentsDefaultValues;

    if (!data) {
      return;
    }

    if (data.filter) {
      for (const key of Object.keys(data.filter)) {
        vals[key] = data.filter[key];
      }
    }
    vals['custom_filters'] = getValues('custom_filters');
    reset(vals, { keepDirty: true });
    submit(vals);
  };

  const resetForm = () => {
    onSubmit(null);
    reset({ custom_filters: '' });
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <div className={styles.container}>
      <div className={styles.filterSets}>
        <FilterSetSelect
          label="Customize filters"
          control={control}
          id="custom_filters"
          name="custom_filters"
          placeholder={'--Select--'}
          options={filterSet}
          updateList={fetchFilterSet}
          onSelectChange={handleChange}
        />
      </div>
      <Row>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Payment method"
            control={control}
            id="payment_method"
            name="payment_method"
            options={paymentMethodOpt}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Transaction type"
            control={control}
            id="transaction_type"
            name="transaction_type"
            options={transactionTypeOpt}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Transaction status"
            control={control}
            id="transaction_status"
            name="transaction_status"
            options={optionsTransactionStatus}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <DatePickerRange
            name="status_updated_at"
            control={control}
            label="Status modification date range"
            placeholder="From - To"
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <PaginateSelect
            isMulti={true}
            label="Desks"
            placeholder={'Any'}
            control={control}
            id="desks"
            name="deskIds"
            onChange={(page, search) => fetchDesks(page, search)}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <PaginateSelect
            isMulti={true}
            label="Teams"
            control={control}
            placeholder={'Any'}
            id="teams"
            name="teamIds"
            onChange={(page, search) => fetchTeams(page, search)}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <PaginateSelect
            isMulti={true}
            placeholder={'Any'}
            label="Operator"
            control={control}
            id="operatorIds"
            name="operatorIds"
            onChange={(page, search) => fetchOperators(page, search)}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Affiliates"
            control={control}
            id="affiliate"
            name="affiliateIds"
            options={affiliate}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Currency"
            control={control}
            id="currencyIds"
            name="currencyIds"
            options={CURRENCY_OPTIONS}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <JokerSelect
            label="First time deposit"
            control={control}
            id="first_time_deposit"
            name="first_time_deposit"
            options={optionsFirstTimeDeposit}
          />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <Row className={styles.rangeWrapper}>
            <Col md={6}>
              <FormInput
                name="amount.from"
                type="number"
                placeholder="0.00"
                min={0}
                label="Amount"
                control={control}
              />

              <span className={styles.rangeDivider}>-</span>
            </Col>
            <Col md={6}>
              <FormInput
                name="amount.to"
                min={0}
                type="number"
                label="&nbsp;"
                placeholder="0.00"
                control={control}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <DatePickerRange
            name="created_at"
            control={control}
            label="Creation date range"
            placeholder="From - To"
          />
        </Col>
      </Row>
      <div className={styles.buttons}>
        {!isEmpty(getValues('custom_filters')) ? (
          <DeleteFilterPopup
            triggerBtn={<Button buttonText="Delete" buttonType="delete" onClick={null} />}
            prepareFiltersData={() => {
              return { ...clearEmptyFilter(getValues()) };
            }}
            onCloseModal={() => {
              fetchFilterSet();
              resetForm();
            }}
          />
        ) : null}
        {!isEmpty(getValues('custom_filters')) || getDirtyFieldsLength() ? (
          <SaveFilterPopup
            triggerBtn={
              <Button
                buttonText={!isEmpty(getValues('custom_filters')) ? 'Update' : 'Save'}
                onClick={null}
              />
            }
            filterSet={filterSet}
            isUpdate={!isEmpty(getValues('custom_filters'))}
            onCloseModal={() => {
              fetchFilterSet();
              submit(getValues());
            }}
            prepareFiltersData={() => {
              return { ...clearEmptyFilter(getValues()) };
            }}
            type="payment"
          />
        ) : null}
      </div>
    </div>
  );
};
