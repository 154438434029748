import React, { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './tradingAccounts.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import { createTradingAcc } from 'api/tradingAcc';
import { CURRENCY_OPTIONS } from 'constants/clients/filters.const';
import { checkMtStatus } from 'utils/checkMtStatusInfo';
import CheckBox from 'components/ui/CheckBox';
import { useAppSelector } from 'store';
import { getEnableWebTrader } from 'constants/selectors.ts';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  userId: string | number;
};

const AddTradingAccountPopup: FC<Props> = (props) => {
  const { triggerBtn, updateComponent, userId } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ENABLE_WT = useAppSelector(getEnableWebTrader);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal): void => {
    reset();
    closeModal();
  };

  const onSubmit = (close, data): void => {
    setIsLoading(true);

    const sendData = {
      account_name: data.account_name,
      amount_type: data && data.amount_type ? data.amount_type.value : null,
      account_type: data && 'gross',
      user_id: userId,
    };

    if (ENABLE_WT) {
      sendData['demo'] = data.demo ? 1 : 0;
    }

    createTradingAcc(sendData)
      .then((res): void => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: 'Trading account created successfully',
            timeOut: 3000,
          });
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error): void => {
        checkMtStatus();
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Add trading account'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'addNewTradingAccForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <FormInput
                            name="account_name"
                            placeholder="Account name"
                            label="Account name"
                            control={control}
                            rules={{ required: ' Field is required' }}
                            errors={errors.account_name}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="mb-3">
                          <JokerSelect
                            isRequired
                            id="amount_type"
                            name="amount_type"
                            placeholder="Currency"
                            label="Currency"
                            control={control}
                            options={CURRENCY_OPTIONS}
                            rules={{ required: ' Field is required' }}
                            errors={errors.amount_type}
                          />
                        </Col>
                      </Row>
                      {ENABLE_WT ? (
                        <Row>
                          <Col md={12} className="mb-3">
                            <CheckBox
                              zIndex={1}
                              control={control}
                              name="demo"
                              type="checkbox"
                              label="Demo account"
                              id="demo"
                            />
                          </Col>
                        </Row>
                      ) : null}
                      {/*<Row>*/}
                      {/*  <Col md={12}>*/}
                      {/*    <SelectComponent*/}
                      {/*      name="account_type"*/}
                      {/*      placeholder="Account type"*/}
                      {/*      label="Account type"*/}
                      {/*      control={control}*/}
                      {/*      options={[*/}
                      {/*        { value: 'gross', label: 'Gross' },*/}
                      {/*        { value: 'net', label: 'Net' },*/}
                      {/*        { value: 'cash', label: 'Cash' },*/}
                      {/*      ]}*/}
                      {/*      rules={{ required: ' Field is required' }}*/}
                      {/*      errors={errors.account_type}*/}
                      {/*    />*/}
                      {/*  </Col>*/}
                      {/*</Row>*/}
                      <Row>
                        <Col md={12}>
                          <div className={cx('content-controls')}>
                            <div className={cx('controls__buttons', 'mt-3')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                buttonText={'Create'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default AddTradingAccountPopup;
