import React from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';

const cx = classNames.bind(styles);

const SalesRulesOperatorTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name, uuid, type, source, priority } = item;
    const getSourceArray = () => {
      const parsed = JSON.parse(source);
      const keys: string[] = Object.keys(parsed);
      const array = [];

      for (const key of keys) {
        array.push(parsed[key]);
      }

      return array;
    };

    const sourceArray = source ? getSourceArray() : [];
    const sourceStr = sourceArray.length > 1 ? sourceArray.join(', ') : sourceArray;
    return {
      name: (
        <div className={cx('callback-table_th')}>
          <span className={styles.name}>{name}</span>
          <span className={styles.rule_id} onClick={() => copyToBuffer(uuid, true)}>
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      priority: (
        <div className={cx('callback-table_th')}>
          <span>{priority}</span>
        </div>
      ),
      source: (
        <div className={cx('callback-table_th')}>
          {sourceArray.length ? <b>{sourceArray.length} Sources </b> : ''}
          {sourceArray.length ? (
            <span className={styles.rule_source}>{sourceStr}</span>
          ) : (
            <span className={cx('rule__source-empty')}>&mdash;</span>
          )}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('callback-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default SalesRulesOperatorTable;
