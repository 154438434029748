import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import styles from './filter.module.scss';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import Button from 'components/ui/Button/Button';
import { isFormChange } from 'utils/isFormChange';
import { clearEmptyFilter } from 'utils/clearEmptyFilter.ts';
import { ReloadButton } from 'components/ui/ReloadButton';
import { cllientCallbacksDefaultValues } from '../useCallbacks';
import { SearchBar } from 'components/ui/SearchBar';

const CallbacksFilter = ({ onSubmit, reloadFilters, queryMessage, setDefaultColumnsOrder }) => {
  const callbackSearchOptions = useSearchCategoriesOptions('callbackByClient');

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { dirtyFields },
    watch,
  } = useFormContext();

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    callbackSearchOptions,
  );

  const prepareFiltersData = (data) => {
    const d_from = moment(data.created_at.from).startOf('day').toString();
    const d_to = moment(data.created_at.to).startOf('day').toString();
    const postData = data.created_at
      ? {
          filter: {
            time: { from: new Date(d_from).getTime() / 1000, to: new Date(d_to).getTime() / 1000 },
            statuses: getValue(data['statuses']) && getValue(data['statuses']),
          },
        }
      : {
          filter: {
            statuses: getValue(data['statuses']) && getValue(data['statuses']),
          },
        };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);

    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(cllientCallbacksDefaultValues);
    onSubmit(null);
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={styles.form}
      >
        <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
        <SearchBar
          className={styles.searchBar}
          queryMessage={queryMessage}
          inputProps={{
            id: 'query',
            name: 'query',
            control,
            placeholder: dynamicSearchPlaceholder,
            onSearch: () => submit(getValues()),
            onChange: (v) => !v?.length && submit(getValues()),
          }}
          selectProps={{
            id: 'query_category',
            name: 'query_category',
            control,
            options: callbackSearchOptions,
            onSelectChange: () => {
              const { query } = getValues();
              if (query) submit(getValues());
            },
          }}
        />
        <div className={styles.buttons}>
          <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
          <Button
            buttonText="Reset"
            buttonType="outline"
            isLoading={reloadFilters}
            type="button"
            onClick={resetForm}
            disabled={!isFormChange(dirtyFields)}
          />
          <Button
            buttonText="Apply"
            isLoading={reloadFilters}
            type="submit"
            onClick={handleSubmit(submit)}
            disabled={!isFormChange(dirtyFields)}
          />
        </div>
      </form>
    </>
  );
};

export default CallbacksFilter;
