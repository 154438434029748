import { TABLE_DEALS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const dealsClientDefaultValues = {
  trading_account: [],
  ticker: [],
  close_at: '',
};

export const useDeals = () => {
  const form = useForm({ reValidateMode: 'onChange', defaultValues: dealsClientDefaultValues });

  const table = useTableColumnOrder('TABLE_DEALS_COLUMNS_NAMES', TABLE_DEALS_COLUMNS_NAMES);

  return { form, table };
};
