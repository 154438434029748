import React, { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './formInput.module.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import { Icon } from '../Icon';

const randomize = require('randomatic');
const cx = classNames.bind(styles);

const FormInputAutoPassword = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  const generate = (): void => {
    let Low = randomize('a', 1);
    let Upp = randomize('A', 1);
    let Num = randomize('0', 1);
    let Spec = randomize('!', 1);
    let rand = randomize('*', 4);
    let pass = Upp + Low + Num + Spec + rand;
    props.generatePassword(pass);
    field.onChange(pass);
  };

  const { field } = useController(props);
  return (
    <>
      {props.label && (
        <div className={cx('label-wrap')} style={props.labelStyle}>
          <label htmlFor={props.id} className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : null}
          </label>
          {props.tooltip && (
            <div className={cx('icon-container')}>
              {props.tooltip.show ? <Tooltip params={props.tooltip} /> : null}
            </div>
          )}
        </div>
      )}
      <div
        className={cx('input-wrap', props.errors && 'error', isFocused && 'focused')}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <button onClick={generate} type={'button'} className={cx('generate-btn')}>
          <Icon name="shuffle" />
        </button>
        <input
          {...field}
          {...props}
          className={props.errors ? cx('input', 'error') : cx('input')}
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value || (field.value === null ? '' : field.value)}
        />
      </div>
      {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
    </>
  );
};

export default FormInputAutoPassword;
