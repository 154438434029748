import React from 'react';
import classNames from 'classnames/bind';
import styles from './tableStyle.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';
import { mapNotificationType } from 'constants/notifications';
import { upperFirst } from 'lodash';
import { Icon } from 'components/ui/Icon';

const cx = classNames.bind(styles);

const NotificationsPopupTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  markAsReadDisableBtn,
  markAsReadHandle,
  colSortCheck,
  markAsRead,
}) => {
  const { columns, saveColumnOrder, setDefaultColumnsOrder } = useTableColumnOrder(
    'TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES',
    TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES,
  );

  const tableData = React.useMemo(
    () =>
      makeTableData(data, data.length).map((item, pos) => {
        const { id, type, details, client, created_at, priority, is_read } = item;

        return {
          type: (
            <div className={cx('notifications-table_td')}>
              <span>{mapNotificationType[type] || type}</span>
            </div>
          ),
          details: (
            <div className={cx('notifications-table_td')}>
              <span className={cx('notification-details')} title={details}>
                {details}
              </span>
            </div>
          ),
          client: (
            <div className={cx('notifications-table_td')}>
              <span>
                <Link
                  target={'_blank'}
                  to={`/client/${client?.id ?? 0}`}
                  className={cx('notifications-table_td-link')}
                >
                  {client?.user_profile?.first_name + ' ' + client?.user_profile?.last_name}
                </Link>
              </span>
            </div>
          ),
          created_at: (
            <div className={cx('notifications-table_td')}>
              <span>{created_at && moment(created_at * 1000).format('DD.MM.YYYY HH:mm:ss')}</span>
            </div>
          ),
          priority: (
            <div className={cx('notifications-table_td ', priority)}>
              <span className={cx(priority)}>{upperFirst(priority)}&nbsp;</span>
            </div>
          ),
          action: (
            <div className={cx('notifications-table_td')}>
              <Icon
                name={is_read ? 'emailRead' : 'emailUnread'}
                size={20}
                onClick={!is_read ? () => markAsRead(id) : undefined}
              />
            </div>
          ),
        };
      }),
    [data],
  );

  return (
    <div>
      <div className="d-flex gap-4 mb-3">
        <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
        <Button
          buttonText="Мark as read"
          disabled={markAsReadDisableBtn}
          onClick={markAsReadHandle}
        />
      </div>
      <Table
        saveColumnOrder={saveColumnOrder}
        columns={columns}
        data={tableData}
        perPage={perPage}
        showLoader={showLoader}
        onRowSelect={onRowSelect}
        colSortCheck={colSortCheck}
        className={cx('notifications-table', 'fixedHeader')}
        isSelected={true}
        getCellProps={(cellInfo, cx) => {
          const { row } = cellInfo;
          const isRead = data[row.index]?.is_read;

          return {
            style: {
              backgroundColor: `${isRead ? 'var(--white)' : 'var(--grey-600)'}`,
            },
          };
        }}
      />
    </div>
  );
};

export default NotificationsPopupTable;
