import { ChangeEvent, KeyboardEvent } from 'react';
import { Control, useController } from 'react-hook-form';
import { Icon } from 'components/ui/Icon';

import styles from './search-bar-input.module.scss';

export type SearchBarInputProps = {
  id: string;
  name: string;
  control: Control<any>;
  placeholder: string;
  onSearch?: () => void;
  onChange?: (v: string) => void;
};

export const SearchBarInput = ({
  id,
  name,
  control,
  placeholder,
  onSearch,
  onChange,
}: SearchBarInputProps) => {
  const { field } = useController({ name, control });

  return (
    <div className={styles.field}>
      <input
        id={id}
        type="text"
        name={name}
        {...field}
        className={styles.input}
        placeholder={placeholder}
        value={field.value === null ? '' : field.value}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            onSearch?.();
          }
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          field.onChange(e);
          onChange?.(e.target.value);
        }}
      />
      <Icon name="search" className={styles.icon} onClick={() => field.value && onSearch?.()} />
    </div>
  );
};
