import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';
import styles from './clientsPopups.mudule.scss';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import { verifyAcc } from 'api/clients';

const cx = classNames.bind(styles);
const VerifyClientPopup = ({ triggerBtn, updateComponent, userId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal): void => {
    reset();
    closeModal();
  };

  const onSubmit = (close): void => {
    setIsLoading(true);

    verifyAcc(userId)
      .then((res): void => {
        if (res.status === 200) {
          updateComponent();
          closeModal(close);
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error): void => {
        notify({
          type: 'error',
          message: error.response || 'Error verify account',
          timeOut: 3000,
        });
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Verify account'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          <span>Are you sure you want to verify this account?</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}></Col>
                      </Row>
                      <div className={cx('content-controls')}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                          <Button
                            isLoading={isLoading}
                            buttonText="Verify"
                            type="submit"
                            onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                          />
                        </div>
                      </div>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default VerifyClientPopup;
