import { useMemo } from "react";
import { useGetSearchCategoriesQuery } from "../store/slice/searchCategoriesQuery";

interface IOption {
  label: string;
  value: string | number;
}

export const useSearchCategoriesOptions = (category: string): IOption[] => {
  const { data } = useGetSearchCategoriesQuery();

  return useMemo(() => {
    if (!data) return [];

    const transformedObject = {};

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const value = data[key];

        transformedObject[key] = Object.entries(value).map(([value, label]) => ({ label, value }));
      }
    }

    return transformedObject[category] ?? [];
  }, [category, data]);
};