import { NestedCryptoTransaction } from 'models/CustomerService/CryptoTransaction';
import { getTransactionPageAcceptedCryptoTableData } from './utils';
import cn from 'classnames';

import styles from './transaction-page-crypto-info.module.scss';

type Props = {
  cryptoTransaction: NestedCryptoTransaction;
  currencyType: string;
};

export const TransactionPageAcceptedCrypto = ({ cryptoTransaction, currencyType }: Props) => {
  const tableData = getTransactionPageAcceptedCryptoTableData({ cryptoTransaction, currencyType });

  return (
    <div>
      <div className={styles.title}>Accepted crypto transaction</div>
      <div className={styles.info}>
        <table>
          <tbody>
            {tableData.map(
              (item, index) =>
                !!item.value && (
                  <tr key={index}>
                    <td className={styles.tableTitle}>{item.title}</td>
                    <td
                      className={cn(
                        styles.tableValue,
                        item?.onClick && styles.tableValue__clickable,
                      )}
                      onClick={item?.onClick}
                    >
                      {item.value}
                    </td>
                  </tr>
                ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
