import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PartnerSchedulesTable from 'components/ui/Table/PartnerSchedulesTable';
import styles from './style.module.scss';
import { ManagementPartnerSchedule } from 'models/Management/Partners/Schedule';
import { CountryListValue } from 'models/Countries';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import { TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES } from 'constants/table';
import { TableLayout } from 'components/ui/Table/TableLayout';
import Button from 'components/ui/Button';

type Props = {
  list: ManagementPartnerSchedule;
  modifyDataHandler: () => void;
  countryOpt: CountryListValue[];
};

const PartnerSchedules = (props: Props) => {
  const { list, modifyDataHandler, countryOpt } = props;

  const { id } = useParams();
  const [currList, setCurrList] = useState([]);

  useEffect(() => {
    const parseData = (list) => {
      const val = Object.values(list);
      setCurrList(val);
    };

    parseData(list);
  }, [list]);

  const table = useTableColumnOrder(
    'TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES',
    TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES,
  );

  return (
    <>
      <div className={styles.tabTitle}>Api sending Schedule</div>
      <TableLayout
        header={
          <Button
            className={styles.button}
            buttonText="Reset column order"
            onClick={table.setDefaultColumnsOrder}
          />
        }
      >
        <PartnerSchedulesTable
          modifyDataHandler={modifyDataHandler}
          data={currList}
          showLoader={false}
          userId={id}
          countryList={countryOpt}
          columns={table.columns}
          saveColumnOrder={table.saveColumnOrder}
        />
      </TableLayout>
    </>
  );
};

export default PartnerSchedules;
