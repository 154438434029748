import moment from 'moment';
import { CryptoTransaction } from 'models/CustomerService/CryptoTransaction';
import { copyToBuffer } from 'utils/copyToBuffer';

type Props = {
  transaction: CryptoTransaction;
};

export const getTransactionPageInfoTableData = ({ transaction }: Props) => [
  {
    title: 'Wallet:',
    value: transaction.wallet_number,
    onClick: () => copyToBuffer(transaction.wallet_number, true),
  },
  {
    title: 'Hash:',
    value: transaction.crypto_hash,
    onClick: () => copyToBuffer(transaction.crypto_hash, true),
  },
  {
    title: 'Amount:',
    value: `${transaction.amount} ${transaction.crypto_type}`,
  },
  {
    title: 'Created at:',
    value: moment.unix(transaction.created_at).format('DD.MM.YYYY HH:mm'),
  },
];
