import classNames from 'classnames/bind';
import React, { FC, memo } from 'react';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import styles from './clients-table.module.scss';
import Client from './cells/Client';
import LastActivity from './cells/LastActivity';
import Country from './cells/Country';
import Balance from './cells/Balance';
import Deposit from './cells/Deposit';
import Affiliate from './cells/Affiliate';
import Type from './cells/Type';
import Sales from './cells/Sales';
import Retention from './cells/Retention';
import Registration from './cells/Registration';
import LastNote from './cells/LastNote';
import Status from './cells/Status';
import { IClientsTableProps } from 'models/components/Table/ClientsTable/ClientsTable.ts';

const cx = classNames.bind(styles);

const ClientsTable: FC<IClientsTableProps> = ({
  data,
  perPage,
  showLoader,
  columns,
  saveColumnOrder,
  modifyDataHandler,
  onRowSelect,
  manualSortHandler,
  colSortCheck,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = React.useMemo(
    () =>
      tableData.map((item, pos) => {
        const {
          id,
          name,
          uuid,
          warning,
          activity,
          last_activity,
          country,
          balance,
          deposits,
          deposits_last,
          referral,
          source,
          affiliate,
          sales,
          retention,
          registration,
          note_last,
          deposits_first,
          status,
          language,
          type,
          updated_at,
          color_status,
          user_profile,
        } = item;

        return {
          client: <Client user_profile={user_profile} uuid={uuid} id={id} />,
          last_activity: <LastActivity activity={activity} last_activity={last_activity} />,
          country: <Country country={country} language={language} />,
          balance: <Balance balance={balance} />,
          deposit: (
            <Deposit
              deposits={deposits}
              deposits_first={deposits_first}
              deposits_last={deposits_last}
            />
          ),
          affiliate_refferer: (
            <Affiliate affiliate={affiliate} referral={referral} source={source} />
          ),
          type: <Type type={type} />,
          sales: <Sales color_status={color_status} sales={sales} status={status} type={type} />,
          retention: (
            <Retention
              color_status={color_status}
              retention={retention}
              status={status}
              type={type}
            />
          ),
          registration: <Registration registration={registration} />,
          last_note: <LastNote user_profile={user_profile} note_last={note_last} />,
          status: <Status user_profile={user_profile} />,
        };
      }),
    [data],
  );

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [data]);

  const checkSortEnable = (colId): boolean => {
    switch (colId) {
      case 'client':
      // case 'last_activity': TODO: NO BACKEND REALIZATION;
      case 'country':
      case 'balance':
      case 'deposit':
      case 'registration':
      case 'last_note':
        return true;
      default:
        return false;
    }
  };

  return (
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      style={{ height: `calc(100vh - 250px)` }}
      className={cx('clients-table')}
      manualSortHandler={manualSortHandler}
      colSortCheck={colSortCheck || checkSortEnable}
      saveColumnOrder={saveColumnOrder}
      isSelected
      getCellProps={(cellInfo) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--main-light-color)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default memo(ClientsTable);
