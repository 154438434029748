import { getTickerMessageColors, getTickerMessageSpeed } from '../../../../api/tiker';

export const fetchColors = async () => {
  const colorsData = await getTickerMessageColors();

  const options = Object.keys(colorsData.data).map((key) => ({
    value: key,
    label: colorsData.data[key],
  }));

  return { data: options };
};

export const fetchSpeed = async () => {
  const speedData = await getTickerMessageSpeed();
  return {
    data: Object.keys(speedData.data).map((key) => ({ value: speedData.data[key], label: key })),
  };
};
