import { updateLeadAddress } from 'api/leads';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { notify } from 'utils/notify';
import { usePermission } from '../../../../utils/usePermission.tsx';
import styles from './../lead-page.module.scss';
import { Lead } from 'models/Leads/Lead';
import { ICountry } from 'models/Countries';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect.tsx';

const cx = classNames.bind(styles);

type Props = {
  formData: Lead;
  countryList: ICountry[];
  updateComponent: () => void;
};

const LeadAdressForm = (props: Props) => {
  const { formData, countryList, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(true);

  const { permissionGiven: PermissionUpdateAddress } = usePermission('admin.lead.update-address');

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      ...data,
      country_id: data.country.id,
    };
    updateLeadAddress(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Address updated successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      const country = countryList && countryList.find((item) => item.id === formData?.country?.id);

      setIsLoading(false);
      reset({
        country: country?.id,
        city: formData.city,
      });
    }
  }, [countryList, formData]);

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row className={cx('form__line')}>
          <Col md={12}>
            <div className={styles.tabTitle}>
              Address
              {PermissionUpdateAddress ? (
                <>{isDirty && <Button buttonText="Save changes" buttonType="outline" />}</>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className={cx('form__line')}>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <JokerSelect
              id="country"
              name="country"
              label="Country"
              control={control}
              options={countryList.map((c) => ({ label: c.name, value: c.id }))}
            />
          </Col>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <FormInput name="city" type="text" label="City" placeholder="City" control={control} />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default LeadAdressForm;
