import { ChangeEvent } from 'react';
import cn from 'classnames';
import styles from './simple-input.module.scss';

type Props = {
  id?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string | number;
  type?: string;
  label?: string;
  placeholder?: string;
  max?: number;
  min?: number;
  inputClassName?: string;
  labelClassName?: string;
  fieldClassName?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SimpleInput = ({
  id,
  label,
  inputClassName,
  labelClassName,
  fieldClassName,
  ...props
}: Props) => {
  return (
    <div className={cn(styles.input, inputClassName)}>
      {label && (
        <label className={cn(styles.label, labelClassName)} htmlFor={id}>
          {label}
        </label>
      )}
      <input className={cn(styles.field, fieldClassName)} {...props} />
    </div>
  );
};
