import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { getEnableWebTrader } from 'constants/selectors';
import { TABLE_TRADING_ACCOUNT_NAMES, TABLE_TRADING_ACCOUNT_NAMES_WITH_WT } from 'constants/table';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const tradingAccountsDefaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  type: [],
  status: [],
};

export const useTradingAccounts = () => {
  const form = useForm({ reValidateMode: 'onChange', defaultValues: {} });

  const ENABLE_WT = useAppSelector(getEnableWebTrader);

  const CURRENT_TABLE = ENABLE_WT
    ? TABLE_TRADING_ACCOUNT_NAMES_WITH_WT
    : TABLE_TRADING_ACCOUNT_NAMES;

  const table = useTableColumnOrder(
    ENABLE_WT ? 'TABLE_TRADING_ACCOUNT_NAMES_WITH_WT' : 'TABLE_TRADING_ACCOUNT_NAMES',
    CURRENT_TABLE,
  );

  return { form, table };
};
