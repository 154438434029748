import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './table-layout.module.scss';

type Props = {
  children: ReactNode;
  header: ReactNode;
  className?: string;
};

export const TableLayout = ({ children, header, className }: Props) => {
  return (
    <div id="table-layout" className={cn(styles.layout, className)}>
      {header}
      <div className={styles.table}>{children}</div>
    </div>
  );
};
