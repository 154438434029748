import { useState } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Icon } from 'components/ui/Icon';
import { getPasswordInputType } from './utils';
import cn from 'classnames';

import styles from './auth-form-input.module.scss';

type Props<T extends FieldValues> = {
  id: string;
  name: 'email' | 'password';
  type: 'email' | 'password';
  label: string;
  control: Control<T>;
  wrapperClassName?: string;
  className?: string;
  rules?: any;
  errorMessage?: string;
};

export function AuthFormInput<T extends FieldValues>({
  id,
  name,
  type,
  label,
  control,
  wrapperClassName,
  className,
  rules,
  errorMessage,
}: Props<T>) {
  const { field } = useController({ name, control, rules });

  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={cn(styles.wrapper, wrapperClassName)}>
      <div
        className={cn(
          styles.input,
          isFocused && styles.input__focus,
          errorMessage && styles.input__error,
          className,
        )}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        <div className={styles.divider} />
        <input
          type={getPasswordInputType(type, showPassword)}
          name={name}
          {...field}
          className={styles.field}
        />
        {type === 'password' && (
          <Icon
            name={showPassword ? 'eyeClose' : 'eye'}
            className={styles.passwordIcon}
            onClick={() => setShowPassword((prev) => !prev)}
          />
        )}
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  );
}
