import { useParams } from 'react-router-dom';
import SalesRulesOperatorTable from 'components/ui/Table/SalesRulesOperatorTable';
import { SalesRuleShort } from 'models/Management/SalesRules/Rule';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import { TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES } from 'constants/table';
import Button from 'components/ui/Button';
import { TableLayout } from 'components/ui/Table/TableLayout';
import styles from './style.module.scss';

type Props = { salesRulesList: SalesRuleShort[] };

const SalesRules = (props: Props) => {
  const { salesRulesList } = props;

  const { id } = useParams();

  const table = useTableColumnOrder(
    'TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES',
    TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES,
  );

  return (
    <>
      <div className={styles.tabTitle}>Sales Rules</div>
      <TableLayout
        header={
          <Button
            className={styles.button}
            buttonText="Reset column order"
            onClick={table.setDefaultColumnsOrder}
          />
        }
      >
        <SalesRulesOperatorTable
          data={salesRulesList}
          showLoader={false}
          userId={id}
          columns={table.columns}
          saveColumnOrder={table.saveColumnOrder}
        />
      </TableLayout>
    </>
  );
};

export default SalesRules;
