import { usePermission } from 'utils/usePermission';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { CountryListValue } from 'models/Countries';
import CreatePartnersPopup from './PartnersPopups/CreatePartnersPopup';
import Button from 'components/ui/Button';
import styles from './partners-page.module.scss';

type Props = {
  total?: number;
  countries: CountryListValue[];
  updateComponentCallback: () => void;
};

export const PartnersPageTitle = ({ total, countries, updateComponentCallback }: Props) => {
  const { permissionGiven: PermissionCreate } = usePermission('admin.office.create');

  return (
    <div className={styles.pageTitle}>
      <div>{total || 0} Affiliates found</div>
      {PermissionCreate ? (
        <CreatePartnersPopup
          countryList={countries}
          updateComponent={updateComponentCallback}
          actionType={SALES_RULES_ACTION_TYPE.CREATE}
          triggerBtn={
            <Button buttonText={'+ New affiliate'} onClick={() => null} buttonType={'outline'} />
          }
        />
      ) : null}
    </div>
  );
};
