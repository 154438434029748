import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ConnectWSS } from 'utils/ConnectWSS';
import LangWrapper from './components/LangWrapper';
import { PRIVAT_ROUTES, ROUTES } from './routes/routes';
import { cleanInfoMethod, getUserInfoMethod } from './store/slice/userInfoSlice';
import emptyCache from './utils/emptyCache.js';
import { getAuthorized } from './constants/selectors';
import { useAppDispatch, useAppSelector } from './store';
import { useClearCache } from 'react-clear-cache';
import { getStyles } from './store/slice/visualSlice';
import { useInstanceSettings } from './utils/useInstanceSettings.ts';
import { useFavicon } from './utils/useFavicon';
import ChatPage from './pages/Chat';
import { ChatDialog } from './pages/Chat/pages/ChatDialog/ChatDialog';
import { ChatNoDialog } from './pages/Chat/pages/ChatNoDialog/ChatNoDialog';
import { ChatRecipient } from './pages/Chat/pages/ChatRecipient/ChatRecipient';
import { AppComponents } from 'components/AppComponents.tsx';
import { isChatEnabled } from './pages/Chat/helpers/isChatEnabled';
import { SENTRY_INIT_CONFIG } from './sentry-init-config';
import * as Sentry from '@sentry/react';
import { getPermissionsTempleRole } from 'api/rolesAndPermissions/index.ts';
import { useOperatorAutologin } from 'utils/useOperatorAutologin.ts';

Sentry.init(SENTRY_INIT_CONFIG);

const App = () => {
  const auth = useAppSelector(getAuthorized);
  useInstanceSettings(auth);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const token: string = localStorage.getItem('token');
  const user_id: string = localStorage.getItem('user_id');
  const chatEnabled = isChatEnabled();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const checkRoute = () => {
    if (auth === false) {
      let check = PRIVAT_ROUTES.some((el): boolean => el.path === location.pathname);
      if (check) {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    emptyCache();
    dispatch(getStyles());
  }, []);

  useEffect(() => {
    if (auth === true) {
      dispatch(getUserInfoMethod());
    }
  }, []);

  useEffect(() => {
    checkRoute();
    auth && ConnectWSS({ token, user_id, dispatch });
    !auth && dispatch(cleanInfoMethod());
  }, [auth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);

  useOperatorAutologin({ location, navigate });

  useFavicon();

  return (
    <LangWrapper>
      {auth && <AppComponents />}
      <Routes>
        {auth
          ? [...PRIVAT_ROUTES, ...ROUTES].map((route) => (
              <Route key={route.id} path={route.path} element={route.component} />
            ))
          : ROUTES.map((route) => (
              <Route key={route.id} path={route.path} element={route.component} />
            ))}
        {auth && chatEnabled ? (
          <Route path="/chat" element={<ChatPage />}>
            <Route index element={<ChatNoDialog />} />
            <Route path="/chat/:id" element={<ChatDialog />} />
            <Route path="/chat/recipient/:id" element={<ChatRecipient />} />
          </Route>
        ) : null}
      </Routes>
    </LangWrapper>
  );
};

export default App;
