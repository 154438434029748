export const logos = [
  {
    id: 'logo_src_crm' as const,
    title: 'Upload logo (CRM)',
    description:
      'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
  },
  {
    id: 'logo_src' as const,
    title: 'Upload logo (PC)',
    description:
      'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
  },
  {
    id: 'sign_up' as const,
    title: 'Upload image for Sign Up page',
    description:
      'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
  },
  {
    id: 'sign_in' as const,
    title: 'Upload image for Sign In page',
    description:
      'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
  },
  {
    id: 'reset_password' as const,
    title: 'Upload image for Reset Password page',
    description:
      'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
  },
];
