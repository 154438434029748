import { updateFilterFavorite } from 'api/filters';
import classNames from 'classnames/bind';
import { Star } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import Select, { components } from 'react-select';

import styles from './filter-set-select.module.scss';
import {
  IPrepeareOptions,
  IValueFilterSetSelect,
} from 'models/components/FilterSetSelect/FilterSetSelect';
import { Icon } from '../Icon';
import { simpleSelectStyles } from '../SimpleSelect';

const cx = classNames.bind(styles);

const customStyles = {
  ...simpleSelectStyles({ errors: undefined, isDisabled: false }),
  groupHeading: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '8px',
      color: 'var(--main-color)',
      fontFamily: 'Inter',
      fontSize: '12px',
      paddingRight: '10px',
    };
  },
  group: (provided, state) => {
    return {
      ...provided,
    };
  },
  control: (s) => ({
    ...s,
    fontWeight: 700,
    fontSize: 'var(--font-size-12)',
    lineHeight: '20px',
    minHeight: 32,
    flex: 1,
    color: 'var(--grey)',
    backgroundColor: 'var(--white)',
    borderRadius: 8,
    border: '1px solid var(--cyan-100)',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'var(--cyan-100)',
    },
    marginTop: 8,
    marginBottom: 4,
    marginLeft: 12,
    marginRight: 16,
    // margin: '8px 16px',
  }),
  menu: () => ({}),
  menuList: (s) => ({
    ...s,
    padding: 6,
    maxHeight: '150px',
  }),
  container: (provided) => ({
    ...provided,
    border: '1px solid var(--cyan-100)',
    backgroundColor: 'var(--white)',
    borderRadius: 8,
    width: 330,

    '@media (max-width: 625px)': {
      width: '100%',
    },
  }),
  option: () => ({
    flex: 1,
  }),
};
const FilterSetSelect = (props) => {
  const { onChange, label, placeholder, ...other } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<IValueFilterSetSelect>(null);
  const { field } = useController(props);

  const prepeareOptions = () =>
    other.options.map(
      (item: IPrepeareOptions): IValueFilterSetSelect => ({
        label: item.name || 'No name',
        value: item.id,
        filter: { ...item.filter, query: item.query, sort: item.sort },
        is_favorite: item.is_favorite,
      }),
    );

  const toggleOpen = (): void => {
    setIsOpen(!isOpen);
  };

  const onSelectChange = (value): void => {
    toggleOpen();
    setValue(value);
    field.onChange(value);
    other.onSelectChange?.(value);
  };

  useEffect((): void => {
    if (!field.value) {
      setValue(null);
    }
  }, [field.value]);

  const updateFavoriteValue = (props): void => {
    const isFavorite: 0 | 1 = props.data?.is_favorite === 0 ? 1 : 0;

    updateFilterFavorite(props.value, { is_favorite: isFavorite }).then((res): void => {
      other.updateList();
    });
  };

  const SelectedValue = ({ data }) => (
    <div className={cx('selected-value')}>
      <span className={cx('selected-value__status', data.is_favorite ? 'favorite' : '')}>
        <Star size={11} />
      </span>
      <span className={cx('selected-value__label')}>{data.label}</span>
    </div>
  );

  const Option = (props) => {
    return (
      <div className={cx('option', props.isSelected && 'selected')}>
        <span className={cx('option__status', props.data?.is_favorite ? 'favorite' : '')}>
          <Star
            size={11}
            onClick={(e: React.MouseEvent<SVGSVGElement>): void => {
              e.preventDefault();
              updateFavoriteValue(props);
            }}
          />
        </span>
        <components.Option {...props}>
          <div>
            <span className={cx('account__label')} ref={props.innerRef} {...props.innerProps}>
              {props.children}
            </span>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <div onClick={toggleOpen} style={{ display: 'flex', alignItems: 'center' }}>
          <span className={cx('custome-control__label')}>{value ? value.label : label}</span>
          {/*<div className={cx('custome-control')}>*/}
          {/*  {value ? (*/}
          {/*    <SelectedValue data={value} />*/}
          {/*  ) : (*/}
          {/*    <span className={cx('placeholder')}>{placeholder}</span>*/}
          {/*  )}*/}
          {/*  {isOpen ? <CaretUp size={18} /> : <CaretDown size={18} />}*/}
          {/*</div>*/}
        </div>
      }
    >
      <Select
        {...field}
        styles={customStyles}
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator: null, GroupHeading, Option, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectChange}
        options={[
          {
            label: 'VIEW',
            options: prepeareOptions(),
          },
        ]}
        placeholder="Search..."
        tabSelectsValue={false}
        value={value}
      />
    </Dropdown>
  );
};

const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

const Menu = (props) => {
  return <div className={styles.menu} {...props} />;
};

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <>
    <div style={{ position: 'relative' }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
    </div>
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </>
);

const DropdownIndicator = () => (
  <div style={{ padding: '0 12px', display: 'flex' }}>
    <Icon name="caretDown" size={16} />
  </div>
);

const GroupHeading = (props) => (
  <div>
    <components.GroupHeading {...props}>
      <span>{props.children}</span>
      <span className={cx('favorite-title')}>
        <Star size={11} />
        Favourite
      </span>
    </components.GroupHeading>
  </div>
);

export default FilterSetSelect;
