import { Permission } from 'models/RolesAndPermissions/Permissions';
import { Switch } from 'components/ui/Switch';

import styles from './permissions.module.scss';

type Props = {
  permission: Permission;
  onUpdatePermission: (action: string, show: boolean, id: number) => void;
};

export const PermissionItem = ({ permission, onUpdatePermission }: Props) => {
  return (
    <li className={styles.item}>
      <div>{permission.description}</div>
      <Switch
        isChecked={permission.show}
        onChange={(e) => onUpdatePermission(permission.action, e.target.checked, permission.id)}
      />
    </li>
  );
};
