import { AsyncPaginate } from 'react-select-async-paginate';
import { FC, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { components } from 'react-select';
import styles from './style.module.scss';
import classNames from 'classnames/bind';
import { Tooltip } from 'bootstrap';
import {
  IPaginateSelect,
  IPaginateSelectMain,
  IPaginateSelectOptions,
  IResponsePaginateSelect,
} from '../../../models/components/PaginateSelect/PaginateSelect.ts';
import { truncate } from 'lodash';
import { Icon } from '../Icon';
import { simpleSelectStyles } from '../SimpleSelect';
import { MultiSelectOption } from '../MultiSelect/components/MultiSelectOption.tsx';
import { getMultiSelectStyles } from '../MultiSelect/styles.ts';
import { Spinner } from '../Spinner/Spinner.tsx';

const cx = classNames.bind(styles);

const PaginateSelect: FC<IPaginateSelect> = (props) => {
  const defaultValue: IPaginateSelectOptions = {
    label: props.defaultValue?.name,
    value: props.defaultValue?.id,
  };
  const [value, setValue] = useState<IPaginateSelectOptions | string>(
    props.defaultValue ? defaultValue : '',
  );
  const [main, setMain] = useState<IPaginateSelectMain | null | undefined>(null);
  const { field } = useController(props);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const Option = (props): null | JSX.Element => {
    const { data } = props;
    if (!data) {
      return null;
    }
    if (props.selectedAcc) {
      return (
        <div
          className={cx(
            'option',
            props.isSelected ? 'selected' : props.isFocused ? 'focused' : undefined,
          )}
        >
          <components.Option {...props}>
            <div>
              <span className={cx('account__label')} ref={props.innerRef} {...props.innerProps}>
                <div className={cx('acc')}>
                  <div className={cx('acc__line', 'acc__name')}>
                    {data.demo === 1 ? (
                      <>
                        <span style={{ color: 'blue', fontWeight: 'bold' }}>DEMO</span>{' '}
                      </>
                    ) : null}
                    {data.name || '[NO NAME]'}{' '}
                    <span className={cx('status', data.status)}>{data.status}</span>
                  </div>
                  <div className={cx('acc__line', 'acc__ta-name')}>
                    {data.trading_account}{' '}
                    {/*<span className={cx('ta_type', data.type.toLowerCase())}>{data.type}</span>*/}
                  </div>
                  <div className={cx('acc__line', 'acc__balance')}>
                    Balance: {data.amount_type} {data.balance || '---'}
                  </div>
                  <div className={cx('acc__line', 'acc__credit')}>
                    Credit: {data.amount_type} {data.credit}
                  </div>
                </div>
              </span>
            </div>
          </components.Option>
        </div>
      );
    }
    return (
      <div
        className={cx(
          'option',
          props.isSelected ? 'selected' : props.isFocused ? 'focused' : undefined,
        )}
      >
        <components.Option {...props}>
          <div>
            <span className={cx('account__label')} ref={props.innerRef} {...props.innerProps}>
              {props.children}
            </span>
          </div>
        </components.Option>
      </div>
    );
  };

  useEffect((): void => {
    setMain(props?.mainVal);
  }, [props?.mainVal]);

  const onSelectChange = (value): void => {
    field.onChange(value);
    setValue(value);
    props.onSelectChange?.(value);
  };

  const onFilterOption = (arr, selectedArr) => {
    const fullArr: IPaginateSelect[] = [...arr];
    const selectedCountryArr_label = selectedArr.map((item) => {
      return item?.admin_user_id?.label;
    });
    return fullArr?.filter((item: IPaginateSelect): IPaginateSelect | null => {
      if (!selectedCountryArr_label.includes(item.label)) return item;
      return null;
    });
  };

  const checkHasMore = (total, page): boolean => {
    if (props.onePage) return false;
    return total > page;
  };

  async function loadOptions(search, loadedOptions, { page }) {
    const response: IResponsePaginateSelect = await props.onChange(page, search, main);

    return {
      options: props.selectedArr ? onFilterOption(response.data, props.selectedArr) : response.data,
      hasMore: response.total ? checkHasMore(response.total, page) : false,
      additional: {
        page: page + 1,
      },
    };
  }

  useEffect((): void => {
    if (props?.def) {
      onSelectChange(props.def);
    }
  }, [props?.def]);

  const onMenuOpen = (): void => {
    setIsOpen(true);
    props.onMenuOpen && props.onMenuOpen();
  };
  const onMenuClose = (): void => {
    setIsOpen(false);
  };
  const truncateLabel = (option) => {
    return truncate(option.label, { length: props.truncateLabelSize });
  };
  const formatOptionLabel = (props): null | JSX.Element => {
    const data = props;

    if (!data) {
      return null;
    }
    return (
      <div className={cx('acc')}>
        <div className={cx('acc__line', 'acc__name')}>
          {data.demo === 1 ? (
            <>
              <span style={{ color: 'blue', fontWeight: 'bold' }}>DEMO</span>{' '}
            </>
          ) : null}
          {data?.label || '[NO NAME]'}{' '}
        </div>
        <div className={cx('acc__line', 'acc__ta-name')}>
          {data.trading_account}{' '}
          {/*<span className={cx('ta_type', data.type.toLowerCase())}>{data.type}</span>*/}
        </div>
        <div className={cx('acc__line', 'acc__balance')}>
          Balance: {data.amount_type} {data.balance || '---'}
        </div>
        <div className={cx('acc__line', 'acc__credit')}>
          Credit: {data.amount_type} {data.credit}
        </div>
      </div>
    );
  };

  return (
    <div>
      {props.label && (
        <div className={cx('label-wrap')}>
          <label htmlFor={props.id} className={cx('label')}>
            {props.label}
            {props.isRequired && <span className={cx('required')}>*</span>}
          </label>
          {props.tooltip && (
            <div className={cx('icon-container')}>
              {props.tooltip.show ? <Tooltip params={props.tooltip} /> : null}
            </div>
          )}
        </div>
      )}
      <AsyncPaginate
        key={props.trigger}
        {...field}
        isDisabled={props.isDisabled}
        isSearchable={props.isSearchable}
        styles={
          props.isMulti
            ? {
                ...simpleSelectStyles({ errors: props.errors, isDisabled: props.isDisabled }),
                option: getMultiSelectStyles().option,
              }
            : simpleSelectStyles({ errors: props.errors, isDisabled: props.isDisabled })
        }
        isMulti={!!props.isMulti}
        closeMenuOnSelect={!props.isMulti}
        hideSelectedOptions={false}
        debounceTimeout={1000}
        defaultValue={props.def}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        loadOptions={loadOptions}
        cacheUniqs={props?.cacheUniqs}
        menuPlacement={props?.menuPlacement}
        formatOptionLabel={
          props.selectedAcc ? formatOptionLabel : props.truncateLabelSize ? truncateLabel : null
        }
        onChange={onSelectChange}
        menuPosition={props?.menuPosition || 'absolute'}
        additional={{
          page: 1,
        }}
        placeholder={props.placeholder ? props.placeholder : '--Select--'}
        components={{
          DropdownIndicator,
          Option: props.isMulti ? MultiSelectOption : Option,
          MultiValue,
          LoadingIndicator,
          ...props.components,
          IndicatorSeparator: null,
        }}
      />
      {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
    </div>
  );
};

const DropdownIndicator = () => (
  <div className={styles.dropdownIndicator}>
    <Icon name="caretDown" size={16} />
  </div>
);

const MultiValue = ({ index, getValue, ...props }) => {
  if (index === 0) {
    return <div>{`${getValue().length} selected`}</div>;
  }

  return null;
};

const LoadingIndicator = () => (
  <div className={styles.loadingIndicator}>
    <Spinner size="small" />
  </div>
);

export default PaginateSelect;
