import { Spinner } from 'components/ui/Spinner';
import cn from 'classnames';

import styles from './auth-button.module.scss';

type Props = {
  text: string;
  isLoading?: boolean;
};

export const AuthButton = ({ text, isLoading = false }: Props) => {
  return (
    <button
      type="submit"
      className={cn(styles.button, isLoading && styles.loading)}
      disabled={isLoading}
    >
      {isLoading ? <Spinner /> : text}
    </button>
  );
};
