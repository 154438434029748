import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { closeJokerModal } from "../../../../../store/slice/modalSlice";
import { useForm } from "react-hook-form";
import { ModalTemplate } from "../../../../../components/ui/Modal/components/JokerModalTemplate/JokerModalTemplate";
import { selectThread } from "../../../../../store/slice/chat/chatSlice";
import FormInput from "../../../../../components/ui/FormInput";
import Button from "../../../../../components/ui/Button";
import { updateChatThreadSettings } from "../../../../../store/slice/chat/thunks";
import './RenameGroupModal.scss';

export const RENAME_GROUP_CHAT_MODAL_KEY = 'RENAME_GROUP_CHAT_MODAL_KEY';

export const RenameGroupModal = () => {
	const dispatch = useAppDispatch();
	const group = useAppSelector(selectThread);

	const [isLoading, setIsLoading] = useState(false);

	const { handleSubmit, control, reset, formState } = useForm({
		reValidateMode: 'onChange',
		values: { subject: group.name }
	});

	const closeModal = () => dispatch(closeJokerModal());

	const onCloseModal = () => {
		setIsLoading(false);
		reset();
		closeModal()
	};

	const onSubmit = async (data) => {
		setIsLoading(true);
		updateChatThreadSettings({ group, subject: data.subject }).finally(() => {
			onCloseModal();
		});
	};

	return (
		<ModalTemplate id={RENAME_GROUP_CHAT_MODAL_KEY} title='Rename group chat' onClose={onCloseModal} size='sm'>
			<div className='rename-chat-form'>
				<form onSubmit={handleSubmit((data) => onSubmit(data))}>
						<FormInput
							label='Group name'
							id='chat_name'
							control={control}
							name='subject'
							placeholder='Write name'
							maxLength={32}
							disabled={isLoading}
							rules={{ maxLength: { value: 32, message: 'Group name is too long' }}}
							errors={formState.errors.subject}
						/>
					<div className='content-controls'>
						<div className='controls__buttons'>
							<Button
								buttonText="Cancel"
								buttonType="outline"
								type="button"
								onClick={onCloseModal}
								disabled={isLoading}
							/>
							<Button
								buttonText={'Rename'}
								isLoading={isLoading}
								disabled={!formState.isDirty}
								type="submit"
								onClick={handleSubmit((data) => onSubmit(data))}
							/>
						</div>
					</div>
				</form>
			</div>
		</ModalTemplate>
	)
}