import { usePermission } from 'utils/usePermission';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { ListValue } from 'models/ListValue';
import Button from 'components/ui/Button';
import CreateSalesRulesPopup from './SalesPopups/CreateSalesRulesPopup';
import styles from './sales-rules-page.module.scss';

type Props = {
  total?: number;
  countries: ListValue[];
  languages: ListValue[];
  operators: any[];
  partners: any[];
  updateComponentCallback: () => void;
};

export const SalesRulesPageTitle = ({
  total,
  countries,
  languages,
  operators,
  partners,
  updateComponentCallback,
}: Props) => {
  const { permissionGiven: PermissionCreateRule } = usePermission('admin.rule.create');

  return (
    <div className={styles.pageTitle}>
      <div>{total || 0} Sales rules</div>
      {PermissionCreateRule ? (
        <CreateSalesRulesPopup
          updateComponent={updateComponentCallback}
          countryList={countries}
          langList={languages}
          operatorsList={operators}
          partnersList={partners}
          actionType={SALES_RULES_ACTION_TYPE.CREATE}
          triggerBtn={
            <Button buttonText="+ Add Rule" onClick={() => null} buttonType={'outline'} />
          }
        />
      ) : null}
    </div>
  );
};
