import { openJokerModal } from "../../../../store/slice/modalSlice";
import { REMOVE_MESSAGE_MODAL_KEY } from "../modals/RemoveMessageModal/RemoveMessageModal";
import { useAppDispatch } from "../../../../store";
import { MenuOption } from "./MenuOption";
import { Icon } from "components/ui/Icon";

type DeleteMessageOptionProps = {
    id: string;
    threadId: string
};

export const DeleteMessageOption = ({ id, threadId }: DeleteMessageOptionProps) => {
    const dispatch = useAppDispatch();

    const handleOpenDeleteMessageModal = () => {
        dispatch(openJokerModal({
            key: REMOVE_MESSAGE_MODAL_KEY,
            data: { title: 'Remove this message?', id, threadId }
        }));
    };

    return (
        <MenuOption title='Delete message' onClick={handleOpenDeleteMessageModal}>
            <Icon name="trash" size={16} color="var(--red-color-hover)"/>
        </MenuOption>
    )
}