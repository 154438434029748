import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import { MultiSelect } from 'components/ui/MultiSelect';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const statusOptions = [
  { value: 'success', label: 'success' },
  { value: 'pending', label: 'pending' },
  { value: 'disabled', label: 'disabled' },
];

export const CallbacksFields = () => {
  const { control } = useFormContext();

  return (
    <Row>
      <Col xl={3} lg={4} md={6}>
        <MultiSelect
          label="Status"
          control={control}
          id="statuses"
          name="statuses"
          options={statusOptions}
        />
      </Col>
      <Col xl={3} lg={4} md={6}>
        <DatePickerRange
          name="created_at"
          control={control}
          label={'Date & time range'}
          placeholder="From - To"
        />
      </Col>
    </Row>
  );
};
