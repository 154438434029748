import { Lead } from 'models/Leads/Lead';
import { Card } from 'components/ui/Card';
import { LeadAccountStatus } from './blocks/status/LeadAccountStatus';
import { LeadRegistered } from './blocks/registered/LeadRegistered';
import { LeadPinnedNotes } from './blocks/notes/LeadPinnedNotes';
import { LeadNote } from 'models/Leads/LeadNotes';
import { TableLinks, TableMeta } from 'models/Table';
import { LeadPersonalInfo } from './blocks/personalInfo/LeadPersonalInfo';
import { Col, Row } from 'react-bootstrap';
import { Icon } from 'components/ui/Icon';
import cn from 'classnames';
import styles from './lead-info.module.scss';

type Props = {
  lead: Lead;
  notesProps: {
    notes: LeadNote[];
    links: TableLinks;
    meta: TableMeta;
    isLoading: boolean;
    request: (params: { id: number }, isNextCall?: boolean) => void;
  };
  permissions: {
    showPersonalInfo: boolean;
    showContacts: boolean;
    showAddress: boolean;
  };
};

export const LeadInfo = ({ lead, notesProps, permissions }: Props) => {
  return (
    <Row className="row-gap-4">
      <Col xl={2} lg={4} md={4} xs={12}>
        <Row className={cn(styles.meta, 'row-gap-4')}>
          <Col md={12} xs={6}>
            <Card className={cn(styles.card, styles.accountStatus)} title="Account status">
              <LeadAccountStatus lead={lead} />
            </Card>
          </Col>
          <Col md={12} xs={6}>
            <Card className={cn(styles.card, styles.registered)} title="Registered">
              <LeadRegistered date={lead?.created_at} />
            </Card>
          </Col>
        </Row>
      </Col>

      {permissions.showPersonalInfo ? (
        <Col xl={5} lg={8} md={8} xs={12}>
          <Card
            className={cn(styles.card, styles.personalInfo)}
            title="Personal information"
            titleClassName={styles.personalInfoTitle}
            iconTitle={<Icon name="personalInfo" color="var(--main-color)" />}
          >
            <LeadPersonalInfo lead={lead} permissions={permissions} />
          </Card>
        </Col>
      ) : null}
      <Col xl={5} lg={12} md={12} xs={12}>
        <Card className={cn(styles.card, styles.pinnedNotes)} title="Pinned notes">
          <LeadPinnedNotes id={lead.id} {...notesProps} />
        </Card>
      </Col>
    </Row>
  );
};
