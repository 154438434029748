import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from '../clients-table.module.scss';
import moment from 'moment';

const cx = classNames.bind(styles);

interface IDeposit {
  deposits: number;
  deposits_first: number | null;
  deposits_last: number | null;
}
const Deposit: FC<IDeposit> = ({ deposits, deposits_first, deposits_last }) => {
  return (
    <div className={cx('client__deposit')}>
      {deposits ? (
        <div>
          <span>
            <b>{deposits}</b>
          </span>
          <span>First: {moment.unix(deposits_first).format('DD.MM.YYYY')}</span>
          <span>Last: {moment.unix(deposits_last).format('DD.MM.YYYY')}</span>
        </div>
      ) : (
        <span className={cx('empty')}>None</span>
      )}
    </div>
  );
};

export default memo(Deposit);
